import { useState } from "react";
import {useSettings} from "../../contexts/settings-context";
import {Link} from "react-router-dom";
import socialIcons from "../../../icons/social-icon";
// import Link from "@components/ui/link";
// import Scrollbar from "@components/common/scrollbar";
import { IoIosArrowDown } from "react-icons/io";
// import Logo from "@components/ui/logo";
// import { useUI } from "@contexts/ui.context";
import { IoClose } from "react-icons/io5";
import Logo from "../../../Components/ui/logo";
import Scrollbar from "../../../Components/scrollbar";
import {getIcon} from "../../../helpers";
import {mobileMenu} from "../../data/static/menus";
import {useDispatch} from "react-redux";
import {closeSidebar} from "../../../redux/uiSlice";
// import { useTranslation } from "next-i18next";
// import { useSettings } from "@contexts/settings.context";
// import { getIcon } from "@lib/get-icon";
// import socialIcons from "@components/icons/social-icon";
// import { Social } from "@framework/types";
// import { mobileMenu } from "@data/static/menus";

export default function MobileMenu() {
	const [activeMenus, setActiveMenus] = useState([]);
  const settings = useSettings();
  const dispatch = useDispatch()

  const socials = settings?.contactDetails?.socials;

	// const { closeSidebar } = useUI();
	// const { t } = useTranslation("menu");
	const t = text => text
	const handleArrowClick = (menuName) => {
		let newActiveMenus = [...activeMenus];

		if (newActiveMenus.includes(menuName)) {
			let index = newActiveMenus.indexOf(menuName);
			if (index > -1) {
				newActiveMenus.splice(index, 1);
			}
		} else {
			newActiveMenus.push(menuName);
		}

		setActiveMenus(newActiveMenus);
	};
	const someAction = () => {
		dispatch(closeSidebar())
	}

	const ListMenu = ({
		dept,
		data,
		hasSubMenu,
		menuName,
		menuIndex,
		className = "",
	}) =>
		data.label && (
			<li className={`mb-0.5 ${className}`}>
				<div className="flex items-center justify-between">
					<Link
						to={data.path}
						className="w-full text-[15px] menu-item relative py-3 ps-5 md:ps-7 pe-4 transition duration-300 ease-in-out"
					>
						<span className="block w-full" onClick={someAction}>
							{data.label}
						</span>
					</Link>
					{hasSubMenu && (
						<div
							className="cursor-pointer w-16 md:w-20 h-8 text-lg flex-shrink-0 flex items-center justify-center"
							onClick={() => handleArrowClick(menuName)}
						>
							<IoIosArrowDown
								className={`transition duration-200 ease-in-out transform text-heading ${
									activeMenus.includes(menuName) ? "-rotate-180" : "rotate-0"
								}`}
							/>
						</div>
					)}
				</div>
				{hasSubMenu && (
					<SubMenu
						dept={dept}
						data={data.subMenu}
						toggle={activeMenus.includes(menuName)}
						menuIndex={menuIndex}
					/>
				)}
			</li>
		);

	const SubMenu = ({ dept, data, toggle, menuIndex }) => {
		if (!toggle) {
			return null;
		}

		dept = dept + 1;

		return (
			<ul className="pt-0.5">
				{data?.map((menu, index) => {
					const menuName = `sidebar-submenu-${dept}-${menuIndex}-${index}`;

					return (
						<ListMenu
							dept={dept}
							data={menu}
							hasSubMenu={menu.subMenu}
							menuName={menuName}
							key={menuName}
							menuIndex={index}
							className={dept > 1 && "ps-4"}
						/>
					);
				})}
			</ul>
		);
	};

	return (
		<>
			<div className="flex flex-col justify-between w-full h-full">
				<div className="w-full border-b border-gray-100 flex justify-between items-center relative ps-5 md:ps-7 flex-shrink-0 py-0.5">
					<Logo />

					<button
						className="flex text-2xl items-center justify-center text-gray-500 px-4 md:px-5 py-6 lg:py-8 focus:outline-none transition-opacity hover:opacity-60"
						onClick={() => dispatch(closeSidebar())}
						aria-label="close"
					>
						<IoClose className="text-black mt-1 md:mt-0.5" />
					</button>
				</div>

				<Scrollbar className="menu-scrollbar flex-grow mb-auto">
					<div className="flex flex-col pt-2 pb-7 px-0 lg:px-2 text-heading">
					  <ul className="mobileMenu">
						{mobileMenu?.map((menu, index) => {
						  const dept = 1;
						  const menuName = `sidebar-menu-${dept}-${index}`;

						  return (
							<ListMenu
							  dept={dept}
							  data={menu}
							  hasSubMenu={menu.subMenu}
							  menuName={menuName}
							  key={menuName}
							  menuIndex={index}
							/>
						  );
						})}
					  </ul>
					</div>
				</Scrollbar>

				<div className="flex items-center justify-center bg-white border-t border-gray-100 px-7 flex-shrink-0 space-s-1">
					{socials?.map((social, index) => (
						<a
							href={social?.url ?? "#!"}
							className={`text-heading p-5 opacity-60 first:-ms-4 transition duration-300 ease-in hover:opacity-100`}
							target="_blank"
              rel="noreferrer"
							key={index}
						>
              {getIcon({
                iconList: socialIcons,
                iconName: social?.icon
              })}
						</a>
					))}
				</div>
			</div>
		</>
	);
}
