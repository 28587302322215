import {ROUTS} from "../routes";
import {CATEGORIES} from "../../../crmData/filters-data";

export const menu = [
  {
    id: 1,
    path: ROUTS.PRODUCTS_BY_CATEGORY([CATEGORIES.shoes]),
    label: "Жіноче взуття",
    subMenu: [
      {
        id: 1,
        path: "/products",
        label: "Демісезон"
      },
      {
        id: 2,
        path: "/products",
        label: "Зимове взуття"
      },
      {
        id: 3,
        path: "/products",
        label: "Лофери"
      },
      {
        id: 4,
        path: "/products",
        label: "Босоніжки"
      },
    ]
  },
  {
    id: 2,
    path: ROUTS.PRODUCTS_BY_CATEGORY([CATEGORIES.bags]),
    label: "Сумки",
    subMenu: [
      {
        id: 1,
        path: "/products",
        label: "Сумка багет"
      },
      {
        id: 2,
        path: "/products",
        label: "На плече та кросбоді"
      },
      {
        id: 3,
        path: "/products",
        label: "Об'ємні сумки та шопери"
      },
      {
        id: 4,
        path: ROUTS.PRODUCTS_BY_CATEGORY([CATEGORIES.woomenRykzak]),
        label: "Рюкзаки"
      },

    ]
  },
  {
    id: 3,
    path: ROUTS.PRODUCTS_BY_CATEGORY([CATEGORIES.acsesorials]),
    label: "Аксесуари",
    subMenu: [
      {
        id: 1,
        path: ROUTS.PRODUCTS_BY_CATEGORY([CATEGORIES.remeni]),
        label: "Ремені"
      },
      {
        id: 1,
        path: ROUTS.PRODUCTS_BY_CATEGORY([CATEGORIES.wallets]),
        label: "Кошильки"
      }
    ]
  },
  {
    id: 7,
    path: ROUTS.PRODUCTS_BY_CATEGORY([CATEGORIES.sale]),
    label: "Розпродаж"
  },
  {
    id: 6,
    path: ROUTS.CONTACTS_PAGE(),
    label: "Контакти"
  },
]
export const menu1 = [
  {
    id: 1,
    path: "/",
    label: "menu-demos",
    subMenu: [
      {
        id: 1,
        path: "/",
        label: "menu-modern"
      },
      {
        id: 2,
        path: "/standard",
        label: "menu-standard"
      },
      {
        id: 3,
        path: "/minimal",
        label: "menu-minimal"
      },
      {
        id: 4,
        path: "/vintage",
        label: "menu-vintage"
      },
      {
        id: 5,
        path: "/classic",
        label: "menu-classic"
      }
    ]
  },
  // {
  //   id: 2,
  //   path: "/search?category=men",
  //   label: "menu-men-wear",
  //   columns: [
  //     {
  //       id: 1,
  //       columnItems: [
  //         {
  //           id: 1,
  //           path: "/search?category=men",
  //           label: "menu-top-wear",
  //           columnItemItems: [
  //             {
  //               id: 1,
  //               path: "/search?category=men",
  //               label: "menu-t-shirt"
  //             },
  //             {
  //               id: 2,
  //               path: "/search?category=men",
  //               label: "menu-casual-shirts"
  //             },
  //             {
  //               id: 3,
  //               path: "/search?category=men",
  //               label: "menu-formal-shirts"
  //             },
  //             {
  //               id: 4,
  //               path: "/search?category=men",
  //               label: "menu-blazwers-coats"
  //             },
  //             {
  //               id: 5,
  //               path: "/search?category=men",
  //               label: "menu-suits"
  //             },
  //             {
  //               id: 6,
  //               path: "/search?category=men",
  //               label: "menu-jackets"
  //             }
  //           ]
  //         },
  //         {
  //           id: 2,
  //           path: "/search?category=men",
  //           label: "menu-belt-scarves"
  //         },
  //         {
  //           id: 3,
  //           path: "/search?category=men",
  //           label: "menu-watches-wearables"
  //         }
  //       ]
  //     },
  //     {
  //       id: 2,
  //       columnItems: [
  //         {
  //           id: 1,
  //           path: "/search?category=men",
  //           label: "menu-western-wear",
  //           columnItemItems: [
  //             {
  //               id: 1,
  //               path: "/search?category=men",
  //               label: "menu-dresses"
  //             },
  //             {
  //               id: 2,
  //               path: "/search?category=men",
  //               label: "menu-jumpsuits"
  //             },
  //             {
  //               id: 3,
  //               path: "/search?category=men",
  //               label: "menu-tops-shirts"
  //             },
  //             {
  //               id: 4,
  //               path: "/search?category=men",
  //               label: "menu-shorts-skirts"
  //             },
  //             {
  //               id: 5,
  //               path: "/search?category=men",
  //               label: "menu-shurgs"
  //             },
  //             {
  //               id: 6,
  //               path: "/search?category=men",
  //               label: "menu-blazers"
  //             }
  //           ]
  //         },
  //         {
  //           id: 2,
  //           path: "/search?category=men",
  //           label: "menu-plus-size"
  //         },
  //         {
  //           id: 3,
  //           path: "/search?category=men",
  //           label: "menu-sunglasses-frames"
  //         }
  //       ]
  //     },
  //     {
  //       id: 3,
  //       columnItems: [
  //         {
  //           id: 1,
  //           path: "/search?category=sneakers",
  //           label: "menu-footwear",
  //           columnItemItems: [
  //             {
  //               id: 1,
  //               path: "/search?category=sneakers",
  //               label: "menu-flats"
  //             },
  //             {
  //               id: 2,
  //               path: "/search?category=sneakers",
  //               label: "menu-casual-shoes"
  //             },
  //             {
  //               id: 3,
  //               path: "/search?category=sneakers",
  //               label: "menu-heels"
  //             },
  //             {
  //               id: 4,
  //               path: "/search?category=sneakers",
  //               label: "menu-boots"
  //             }
  //           ]
  //         },
  //         {
  //           id: 2,
  //           path: "/search?category=sports",
  //           label: "menu-sports-active-wear",
  //           columnItemItems: [
  //             {
  //               id: 1,
  //               path: "/search?category=sports",
  //               label: "menu-clothing"
  //             },
  //             {
  //               id: 2,
  //               path: "/search?category=sports",
  //               label: "menu-footwear"
  //             },
  //             {
  //               id: 3,
  //               path: "/search?category=sports",
  //               label: "menu-sports-accessories"
  //             }
  //           ]
  //         }
  //       ]
  //     },
  //     {
  //       id: 4,
  //       columnItems: [
  //         {
  //           id: 1,
  //           path: "/search?category=women",
  //           label: "menu-lingerie-sleepwear",
  //           columnItemItems: [
  //             {
  //               id: 1,
  //               path: "/search?category=women",
  //               label: "menu-bra"
  //             },
  //             {
  //               id: 2,
  //               path: "/search?category=women",
  //               label: "menu-briefs"
  //             },
  //             {
  //               id: 3,
  //               path: "/search?category=women",
  //               label: "menu-sleepwear"
  //             }
  //           ]
  //         },
  //         {
  //           id: 2,
  //           path: "/search?category=women",
  //           label: "menu-belt-scarves",
  //           columnItemItems: [
  //             {
  //               id: 1,
  //               path: "/search?category=women",
  //               label: "menu-makeup"
  //             },
  //             {
  //               id: 2,
  //               path: "/search?category=women",
  //               label: "menu-skincare"
  //             },
  //             {
  //               id: 3,
  //               path: "/search?category=women",
  //               label: "menu-premium-beauty"
  //             },
  //             {
  //               id: 4,
  //               path: "/search?category=women",
  //               label: "menu-lipsticks"
  //             }
  //           ]
  //         }
  //       ]
  //     },
  //     {
  //       id: 5,
  //       columnItems: [
  //         {
  //           id: 1,
  //           path: "/search?category=watch",
  //           label: "menu-gadgets",
  //           columnItemItems: [
  //             {
  //               id: 1,
  //               path: "/search?category=watch",
  //               label: "menu-smart-wearables"
  //             },
  //             {
  //               id: 2,
  //               path: "/search?category=watch",
  //               label: "menu-headphones"
  //             }
  //           ]
  //         },
  //         {
  //           id: 2,
  //           path: "/search?category=watch",
  //           label: "menu-jewellers",
  //           columnItemItems: [
  //             {
  //               id: 1,
  //               path: "/search?category=watch",
  //               label: "menu-fashion-jewellers"
  //             },
  //             {
  //               id: 2,
  //               path: "/search?category=watch",
  //               label: "menu-fine-jewellers"
  //             }
  //           ]
  //         },
  //         {
  //           id: 3,
  //           path: "/search?category=bags",
  //           label: "menu-backpacks"
  //         },
  //         {
  //           id: 4,
  //           path: "/search?category=bags",
  //           label: "menu-handbags-wallets"
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   id: 3,
  //   path: "/search?category=women",
  //   label: "menu-women-wear",
  //   columns: [
  //     {
  //       id: 1,
  //       columnItems: [
  //         {
  //           id: 1,
  //           path: "/search?category=bags",
  //           label: "menu-gadgets",
  //           columnItemItems: [
  //             {
  //               id: 1,
  //               path: "/search?category=watch",
  //               label: "menu-smart-wearables"
  //             },
  //             {
  //               id: 2,
  //               path: "/search?category=sports",
  //               label: "menu-headphones"
  //             }
  //           ]
  //         },
  //         {
  //           id: 2,
  //           path: "/search?category=watch",
  //           label: "menu-jewellers",
  //           columnItemItems: [
  //             {
  //               id: 1,
  //               path: "/search?category=watch",
  //               label: "menu-fashion-jewellers"
  //             },
  //             {
  //               id: 2,
  //               path: "/search?category=watch",
  //               label: "menu-fine-jewellers"
  //             }
  //           ]
  //         },
  //         {
  //           id: 3,
  //           path: "/search?category=bags",
  //           label: "menu-backpacks"
  //         },
  //         {
  //           id: 4,
  //           path: "/search?category=watch",
  //           label: "menu-handbags-wallets"
  //         }
  //       ]
  //     },
  //     {
  //       id: 2,
  //       columnItems: [
  //         {
  //           id: 1,
  //           path: "/search?category=women",
  //           label: "menu-top-wear",
  //           columnItemItems: [
  //             {
  //               id: 1,
  //               path: "/search?category=women",
  //               label: "menu-t-shirt"
  //             },
  //             {
  //               id: 2,
  //               path: "/search?category=women",
  //               label: "menu-casual-shirts"
  //             },
  //             {
  //               id: 3,
  //               path: "/search?category=women",
  //               label: "menu-formal-shirts"
  //             },
  //             {
  //               id: 4,
  //               path: "/search?category=women",
  //               label: "menu-blazwers-coats"
  //             },
  //             {
  //               id: 5,
  //               path: "/search?category=women",
  //               label: "menu-suits"
  //             },
  //             {
  //               id: 6,
  //               path: "/search?category=women",
  //               label: "menu-jackets"
  //             }
  //           ]
  //         },
  //         {
  //           id: 2,
  //           path: "/search?category=women",
  //           label: "menu-belt-scarves"
  //         },
  //         {
  //           id: 3,
  //           path: "/search?category=women",
  //           label: "menu-watches-wearables"
  //         }
  //       ]
  //     },
  //     {
  //       id: 3,
  //       columnItems: [
  //         {
  //           id: 1,
  //           path: "/search?category=women",
  //           label: "menu-footwear",
  //           columnItemItems: [
  //             {
  //               id: 1,
  //               path: "/search?category=women",
  //               label: "menu-flats"
  //             },
  //             {
  //               id: 2,
  //               path: "/search?category=women",
  //               label: "menu-casual-shoes"
  //             },
  //             {
  //               id: 3,
  //               path: "/search?category=women",
  //               label: "menu-heels"
  //             },
  //             {
  //               id: 4,
  //               path: "/search?category=women",
  //               label: "menu-boots"
  //             }
  //           ]
  //         },
  //         {
  //           id: 2,
  //           path: "/search?category=sneakers",
  //           label: "menu-sports-active-wear",
  //           columnItemItems: [
  //             {
  //               id: 1,
  //               path: "/search?category=sneakers",
  //               label: "menu-clothing"
  //             },
  //             {
  //               id: 2,
  //               path: "/search?category=sneakers",
  //               label: "menu-footwear"
  //             },
  //             {
  //               id: 3,
  //               path: "/search?category=sneakers",
  //               label: "menu-sports-accessories"
  //             }
  //           ]
  //         }
  //       ]
  //     },
  //     {
  //       id: 4,
  //       columnItems: [
  //         {
  //           id: 1,
  //           path: "/search?category=women",
  //           label: "menu-western-wear",
  //           columnItemItems: [
  //             {
  //               id: 1,
  //               path: "/search?category=women",
  //               label: "menu-dresses"
  //             },
  //             {
  //               id: 2,
  //               path: "/search?category=women",
  //               label: "menu-jumpsuits"
  //             },
  //             {
  //               id: 3,
  //               path: "/search?category=women",
  //               label: "menu-tops-shirts"
  //             },
  //             {
  //               id: 4,
  //               path: "/search?category=women",
  //               label: "menu-shorts-skirts"
  //             },
  //             {
  //               id: 5,
  //               path: "/search?category=women",
  //               label: "menu-shurgs"
  //             },
  //             {
  //               id: 6,
  //               path: "/search?category=women",
  //               label: "menu-blazers"
  //             }
  //           ]
  //         },
  //         {
  //           id: 2,
  //           path: "/search?category=women",
  //           label: "menu-plus-size"
  //         },
  //         {
  //           id: 3,
  //           path: "/search?category=women",
  //           label: "menu-sunglasses-frames"
  //         }
  //       ]
  //     },
  //     {
  //       id: 5,
  //       columnItems: [
  //         {
  //           id: 1,
  //           path: "/search?category=women",
  //           label: "menu-lingerie-sleepwear",
  //           columnItemItems: [
  //             {
  //               id: 1,
  //               path: "/search?category=women",
  //               label: "menu-bra"
  //             },
  //             {
  //               id: 2,
  //               path: "/search?category=women",
  //               label: "menu-briefs"
  //             },
  //             {
  //               id: 3,
  //               path: "/search?category=women",
  //               label: "menu-sleepwear"
  //             }
  //           ]
  //         },
  //         {
  //           id: 2,
  //           path: "/search?category=women",
  //           label: "menu-belt-scarves",
  //           columnItemItems: [
  //             {
  //               id: 1,
  //               path: "/search?category=women",
  //               label: "menu-makeup"
  //             },
  //             {
  //               id: 2,
  //               path: "/search?category=women",
  //               label: "menu-skincare"
  //             },
  //             {
  //               id: 3,
  //               path: "/search?category=women",
  //               label: "menu-premium-beauty"
  //             },
  //             {
  //               id: 4,
  //               path: "/search?category=women",
  //               label: "menu-lipsticks"
  //             }
  //           ]
  //         }
  //       ]
  //     }
  //   ]
  // },
  {
    id: 4,
    path: "/search?category=men",
    label: "menu-casual-wear",
    columns: [
      {
        id: 1,
        columnItems: [
          {
            id: 1,
            path: "/search?category=watch",
            label: "menu-gadgets",
            columnItemItems: [
              {
                id: 1,
                path: "/search?category=watch",
                label: "menu-smart-wearables"
              },
              {
                id: 2,
                path: "/search?category=sports",
                label: "menu-headphones"
              }
            ]
          },
          {
            id: 2,
            path: "/search?category=watch",
            label: "menu-jewellers",
            columnItemItems: [
              {
                id: 1,
                path: "/search?category=watch",
                label: "menu-fashion-jewellers"
              },
              {
                id: 2,
                path: "/search?category=watch",
                label: "menu-fine-jewellers"
              }
            ]
          },
          {
            id: 3,
            path: "/search?category=bags",
            label: "menu-backpacks"
          },
          {
            id: 4,
            path: "/search?category=bags",
            label: "menu-handbags-wallets"
          }
        ]
      },
      {
        id: 2,
        columnItems: [
          {
            id: 1,
            path: "/search?category=men",
            label: "menu-top-wear",
            columnItemItems: [
              {
                id: 1,
                path: "/search?category=men",
                label: "menu-t-shirt"
              },
              {
                id: 2,
                path: "/search?category=men",
                label: "menu-casual-shirts"
              },
              {
                id: 3,
                path: "/search?category=men",
                label: "menu-formal-shirts"
              },
              {
                id: 4,
                path: "/search?category=men",
                label: "menu-blazwers-coats"
              },
              {
                id: 5,
                path: "/search?category=men",
                label: "menu-suits"
              },
              {
                id: 6,
                path: "/search?category=men",
                label: "menu-jackets"
              }
            ]
          },
          {
            id: 2,
            path: "/search?category=men",
            label: "menu-belt-scarves"
          },
          {
            id: 3,
            path: "/search?category=watch",
            label: "menu-watches-wearables"
          }
        ]
      },
      {
        id: 3,
        columnItems: [
          {
            id: 1,
            path: "/search?category=sneakers",
            label: "menu-footwear",
            columnItemItems: [
              {
                id: 1,
                path: "/search?category=sneakers",
                label: "menu-flats"
              },
              {
                id: 2,
                path: "/search?category=sneakers",
                label: "menu-casual-shoes"
              },
              {
                id: 3,
                path: "/search?category=sneakers",
                label: "menu-heels"
              },
              {
                id: 4,
                path: "/search?category=sneakers",
                label: "menu-boots"
              }
            ]
          },
          {
            id: 2,
            path: "/search?category=men",
            label: "menu-sports-active-wear",
            columnItemItems: [
              {
                id: 1,
                path: "/search?category=men",
                label: "menu-clothing"
              },
              {
                id: 2,
                path: "/search?category=men",
                label: "menu-footwear"
              },
              {
                id: 3,
                path: "/search?category=sports",
                label: "menu-sports-accessories"
              }
            ]
          }
        ]
      },
      {
        id: 4,
        columnItems: [
          {
            id: 1,
            path: "/search?category=men",
            label: "menu-western-wear",
            columnItemItems: [
              {
                id: 1,
                path: "/search?category=men",
                label: "menu-dresses"
              },
              {
                id: 2,
                path: "/search?category=men",
                label: "menu-jumpsuits"
              },
              {
                id: 3,
                path: "/search?category=men",
                label: "menu-tops-shirts"
              },
              {
                id: 4,
                path: "/search?category=men",
                label: "menu-shorts-skirts"
              },
              {
                id: 5,
                path: "/search?category=men",
                label: "menu-shurgs"
              },
              {
                id: 6,
                path: "/search?category=men",
                label: "menu-blazers"
              }
            ]
          },
          {
            id: 2,
            path: "/search?category=women",
            label: "menu-plus-size"
          },
          {
            id: 3,
            path: "/search?category=sunglass",
            label: "menu-sunglasses-frames"
          }
        ]
      },
      {
        id: 5,
        columnItems: [
          {
            id: 1,
            path: "/search?category=women",
            label: "menu-lingerie-sleepwear",
            columnItemItems: [
              {
                id: 1,
                path: "/search?category=women",
                label: "menu-bra"
              },
              {
                id: 2,
                path: "/search?category=women",
                label: "menu-briefs"
              },
              {
                id: 3,
                path: "/search?category=women",
                label: "menu-sleepwear"
              }
            ]
          },
          {
            id: 2,
            path: "/search?category=women",
            label: "menu-belt-scarves",
            columnItemItems: [
              {
                id: 1,
                path: "/search?category=women",
                label: "menu-makeup"
              },
              {
                id: 2,
                path: "/search?category=women",
                label: "menu-skincare"
              },
              {
                id: 3,
                path: "/search?category=women",
                label: "menu-premium-beauty"
              },
              {
                id: 4,
                path: "/search?category=women",
                label: "menu-lipsticks"
              }
            ]
          }
        ]
      }
    ]
  },
  {
    id: 5,
    path: "/search",
    label: "menu-search"
  },
  {
    id: 6,
    path: "/shops",
    label: "menu-shops"
  },
  {
    id: 7,
    path: "/",
    label: "menu-pages",
    subMenu: [
      {
        id: 1,
        path: "/",
        label: "menu-users",
        subMenu: [
          {
            id: 1,
            path: "/my-account",
            label: "menu-my-account"
          },
          {
            id: 2,
            path: "/signin",
            label: "menu-sign-in"
          },
          {
            id: 3,
            path: "/signup",
            label: "menu-sign-up"
          },
          {
            id: 4,
            path: "/forget-password",
            label: "menu-forget-password"
          }
        ]
      },
      {
        id: 2,
        path: "/faq",
        label: "menu-faq"
      },
      {
        id: 3,
        path: "/privacy",
        label: "menu-privacy-policy"
      },
      {
        id: 4,
        path: "/terms",
        label: "menu-terms-condition"
      },
      {
        id: 5,
        path: "/contact-us",
        label: "menu-contact-us"
      },
      {
        id: 6,
        path: "/checkout",
        label: "menu-checkout"
      },
      {
        id: 7,
        path: "/collections/on-sale",
        label: "menu-collection"
      },
      {
        id: 8,
        path: "/search",
        label: "menu-category"
      },
      {
        id: 9,
        path: "/my-account/orders",
        label: "menu-order"
      },
      {
        id: 10,
        path: "/404",
        label: "menu-404"
      }
    ]
  }
];

export const mobileMenu = [...menu]
export const mobileMenu1 = [
  {
    id: 1,
    path: "/",
    label: "menu-demos",
    subMenu: [
      {
        id: 1,
        path: "/",
        label: "menu-modern"
      },
      {
        id: 2,
        path: "/standard",
        label: "menu-standard"
      },
      {
        id: 3,
        path: "/minimal",
        label: "menu-minimal"
      },
      {
        id: 4,
        path: "/vintage",
        label: "menu-vintage"
      },
      {
        id: 5,
        path: "/classic",
        label: "menu-classic"
      }
    ]
  },
  {
    id: 2,
    path: "/search?category=men",
    label: "menu-men-wear",
    subMenu: [
      {
        id: 1,
        path: "/search?category=men",
        label: "menu-top-wear",
        subMenu: [
          {
            id: 1,
            path: "/search?category=men",
            label: "menu-t-shirt"
          },
          {
            id: 2,
            path: "//search?category=men",
            label: "menu-casual-shirts"
          },
          {
            id: 3,
            path: "/search?category=men",
            label: "menu-formal-shirts"
          },
          {
            id: 4,
            path: "/search?category=men",
            label: "menu-blazwers-coats"
          },
          {
            id: 5,
            path: "/search?category=men",
            label: "menu-suits"
          },
          {
            id: 6,
            path: "/search?category=men",
            label: "menu-jackets"
          }
        ]
      },
      {
        id: 2,
        path: "/search?category=women",
        label: "menu-belt-scarves"
      },
      {
        id: 3,
        path: "/search?category=women",
        label: "menu-watches-wearables"
      },
      {
        id: 4,
        path: "/search?category=women",
        label: "menu-western-wear",
        subMenu: [
          {
            id: 1,
            path: "/search?category=women",
            label: "menu-dresses"
          },
          {
            id: 2,
            path: "/search?category=women",
            label: "menu-jumpsuits"
          },
          {
            id: 3,
            path: "/search?category=men",
            label: "menu-tops-shirts"
          },
          {
            id: 4,
            path: "/search?category=men",
            label: "menu-shorts-skirts"
          },
          {
            id: 5,
            path: "/search?category=men",
            label: "menu-shurgs"
          },
          {
            id: 6,
            path: "/search?category=men",
            label: "menu-blazers"
          }
        ]
      },
      {
        id: 5,
        path: "/search?category=women",
        label: "menu-plus-size"
      },
      {
        id: 6,
        path: "/search?category=women",
        label: "menu-sunglasses-frames"
      },
      {
        id: 7,
        path: "/search?category=women",
        label: "menu-footwear",
        subMenu: [
          {
            id: 1,
            path: "/search?category=women",
            label: "menu-flats"
          },
          {
            id: 2,
            path: "/search?category=women",
            label: "menu-casual-shoes"
          },
          {
            id: 3,
            path: "/search?category=women",
            label: "menu-heels"
          },
          {
            id: 4,
            path: "/search?category=women",
            label: "menu-boots"
          }
        ]
      },
      {
        id: 8,
        path: "/search?category=women",
        label: "menu-sports-active-wear",
        subMenu: [
          {
            id: 1,
            path: "/search?category=women",
            label: "menu-clothing"
          },
          {
            id: 2,
            path: "/search?category=women",
            label: "menu-footwear"
          },
          {
            id: 3,
            path: "/search?category=women",
            label: "menu-sports-accessories"
          }
        ]
      },
      {
        id: 9,
        path: "/search?category=women",
        label: "menu-lingerie-sleepwear",
        subMenu: [
          {
            id: 1,
            path: "/search?category=women",
            label: "menu-bra"
          },
          {
            id: 2,
            path: "/search?category=women",
            label: "menu-briefs"
          },
          {
            id: 3,
            path: "/search?category=women",
            label: "menu-sleepwear"
          }
        ]
      },
      {
        id: 10,
        path: "/search?category=women",
        label: "menu-belt-scarves",
        subMenu: [
          {
            id: 1,
            path: "/search?category=women",
            label: "menu-makeup"
          },
          {
            id: 2,
            path: "/search?category=women",
            label: "menu-skincare"
          },
          {
            id: 3,
            path: "/search?category=women",
            label: "menu-premium-beauty"
          },
          {
            id: 4,
            path: "/search?category=women",
            label: "menu-lipsticks"
          }
        ]
      },
      {
        id: 11,
        path: "/search?category=sports",
        label: "menu-gadgets",
        subMenu: [
          {
            id: 1,
            path: "/search?category=sports",
            label: "menu-smart-wearables"
          },
          {
            id: 2,
            path: "/search?category=sports",
            label: "menu-headphones"
          }
        ]
      },
      {
        id: 12,
        path: "/search?category=women",
        label: "menu-jewellers",
        subMenu: [
          {
            id: 1,
            path: "/search?category=women",
            label: "menu-fashion-jewellers"
          },
          {
            id: 2,
            path: "/search?category=women",
            label: "menu-fine-jewellers"
          }
        ]
      },
      {
        id: 13,
        path: "/search?category=bags",
        label: "menu-backpacks"
      },
      {
        id: 14,
        path: "/search?category=bags",
        label: "menu-handbags-wallets"
      }
    ]
  },
  {
    id: 3,
    path: "/search?category=women",
    label: "menu-women-wear",
    subMenu: [
      {
        id: 1,
        path: "/search?category=men",
        label: "menu-top-wear",
        subMenu: [
          {
            id: 1,
            path: "/search?category=women",
            label: "menu-t-shirt"
          },
          {
            id: 2,
            path: "//search?category=women",
            label: "menu-casual-shirts"
          },
          {
            id: 3,
            path: "/search?category=women",
            label: "menu-formal-shirts"
          },
          {
            id: 4,
            path: "/search?category=women",
            label: "menu-blazwers-coats"
          },
          {
            id: 5,
            path: "/search?category=women",
            label: "menu-suits"
          },
          {
            id: 6,
            path: "/search?category=women",
            label: "menu-jackets"
          }
        ]
      },
      {
        id: 2,
        path: "/search?category=women",
        label: "menu-belt-scarves"
      },
      {
        id: 3,
        path: "/search?category=women",
        label: "menu-watches-wearables"
      },
      {
        id: 4,
        path: "/search?category=women",
        label: "menu-western-wear",
        subMenu: [
          {
            id: 1,
            path: "/search?category=women",
            label: "menu-dresses"
          },
          {
            id: 2,
            path: "/search?category=women",
            label: "menu-jumpsuits"
          },
          {
            id: 3,
            path: "/search?category=men",
            label: "menu-tops-shirts"
          },
          {
            id: 4,
            path: "/search?category=men",
            label: "menu-shorts-skirts"
          },
          {
            id: 5,
            path: "/search?category=men",
            label: "menu-shurgs"
          },
          {
            id: 6,
            path: "/search?category=men",
            label: "menu-blazers"
          }
        ]
      },
      {
        id: 5,
        path: "/search?category=women",
        label: "menu-plus-size"
      },
      {
        id: 6,
        path: "/search?category=women",
        label: "menu-sunglasses-frames"
      },
      {
        id: 7,
        path: "/search?category=women",
        label: "menu-footwear",
        subMenu: [
          {
            id: 1,
            path: "/search?category=women",
            label: "menu-flats"
          },
          {
            id: 2,
            path: "/search?category=women",
            label: "menu-casual-shoes"
          },
          {
            id: 3,
            path: "/search?category=women",
            label: "menu-heels"
          },
          {
            id: 4,
            path: "/search?category=women",
            label: "menu-boots"
          }
        ]
      },
      {
        id: 8,
        path: "/search?category=women",
        label: "menu-sports-active-wear",
        subMenu: [
          {
            id: 1,
            path: "/search?category=women",
            label: "menu-clothing"
          },
          {
            id: 2,
            path: "/search?category=women",
            label: "menu-footwear"
          },
          {
            id: 3,
            path: "/search?category=women",
            label: "menu-sports-accessories"
          }
        ]
      },
      {
        id: 9,
        path: "/search?category=women",
        label: "menu-lingerie-sleepwear",
        subMenu: [
          {
            id: 1,
            path: "/search?category=women",
            label: "menu-bra"
          },
          {
            id: 2,
            path: "/search?category=women",
            label: "menu-briefs"
          },
          {
            id: 3,
            path: "/search?category=women",
            label: "menu-sleepwear"
          }
        ]
      },
      {
        id: 10,
        path: "/search?category=women",
        label: "menu-belt-scarves",
        subMenu: [
          {
            id: 1,
            path: "/search?category=women",
            label: "menu-makeup"
          },
          {
            id: 2,
            path: "/search?category=women",
            label: "menu-skincare"
          },
          {
            id: 3,
            path: "/search?category=women",
            label: "menu-premium-beauty"
          },
          {
            id: 4,
            path: "/search?category=women",
            label: "menu-lipsticks"
          }
        ]
      },
      {
        id: 11,
        path: "/search?category=sports",
        label: "menu-gadgets",
        subMenu: [
          {
            id: 1,
            path: "/search?category=sports",
            label: "menu-smart-wearables"
          },
          {
            id: 2,
            path: "/search?category=sports",
            label: "menu-headphones"
          }
        ]
      },
      {
        id: 12,
        path: "/search?category=women",
        label: "menu-jewellers",
        subMenu: [
          {
            id: 1,
            path: "/search?category=women",
            label: "menu-fashion-jewellers"
          },
          {
            id: 2,
            path: "/search?category=women",
            label: "menu-fine-jewellers"
          }
        ]
      },
      {
        id: 13,
        path: "/search?category=bags",
        label: "menu-backpacks"
      },
      {
        id: 14,
        path: "/search?category=bags",
        label: "menu-handbags-wallets"
      }
    ]
  },
  {
    id: 4,
    path: "/search?category=women",
    label: "menu-casual-wear",
    subMenu: [
      {
        id: 1,
        path: "/search?category=men",
        label: "menu-top-wear",
        subMenu: [
          {
            id: 1,
            path: "/search?category=me",
            label: "menu-t-shirt"
          },
          {
            id: 2,
            path: "/search?category=me",
            label: "menu-casual-shirts"
          },
          {
            id: 3,
            path: "/search?category=me",
            label: "menu-formal-shirts"
          },
          {
            id: 4,
            path: "/search?category=me",
            label: "menu-blazwers-coats"
          },
          {
            id: 5,
            path: "/search?category=me",
            label: "menu-suits"
          },
          {
            id: 6,
            path: "/search?category=me",
            label: "menu-jackets"
          }
        ]
      },
      {
        id: 2,
        path: "/search?category=women",
        label: "menu-belt-scarves"
      },
      {
        id: 3,
        path: "/search?category=women",
        label: "menu-watches-wearables"
      },
      {
        id: 4,
        path: "/search?category=women",
        label: "menu-western-wear",
        subMenu: [
          {
            id: 1,
            path: "/search?category=women",
            label: "menu-dresses"
          },
          {
            id: 2,
            path: "/search?category=women",
            label: "menu-jumpsuits"
          },
          {
            id: 3,
            path: "/search?category=men",
            label: "menu-tops-shirts"
          },
          {
            id: 4,
            path: "/search?category=men",
            label: "menu-shorts-skirts"
          },
          {
            id: 5,
            path: "/search?category=men",
            label: "menu-shurgs"
          },
          {
            id: 6,
            path: "/search?category=men",
            label: "menu-blazers"
          }
        ]
      },
      {
        id: 5,
        path: "/search?category=women",
        label: "menu-plus-size"
      },
      {
        id: 6,
        path: "/search?category=women",
        label: "menu-sunglasses-frames"
      },
      {
        id: 7,
        path: "/search?category=women",
        label: "menu-footwear",
        subMenu: [
          {
            id: 1,
            path: "/search?category=women",
            label: "menu-flats"
          },
          {
            id: 2,
            path: "/search?category=women",
            label: "menu-casual-shoes"
          },
          {
            id: 3,
            path: "/search?category=women",
            label: "menu-heels"
          },
          {
            id: 4,
            path: "/search?category=women",
            label: "menu-boots"
          }
        ]
      },
      {
        id: 8,
        path: "/search?category=sports",
        label: "menu-sports-active-wear",
        subMenu: [
          {
            id: 1,
            path: "/search?category=sports",
            label: "menu-clothing"
          },
          {
            id: 2,
            path: "/search?category=sports",
            label: "menu-footwear"
          },
          {
            id: 3,
            path: "/search?category=sports",
            label: "menu-sports-accessories"
          }
        ]
      },
      {
        id: 9,
        path: "/search?category=women",
        label: "menu-lingerie-sleepwear",
        subMenu: [
          {
            id: 1,
            path: "/search?category=women",
            label: "menu-bra"
          },
          {
            id: 2,
            path: "/search?category=women",
            label: "menu-briefs"
          },
          {
            id: 3,
            path: "/search?category=women",
            label: "menu-sleepwear"
          }
        ]
      },
      {
        id: 10,
        path: "/search?category=women",
        label: "menu-belt-scarves",
        subMenu: [
          {
            id: 1,
            path: "/search?category=women",
            label: "menu-makeup"
          },
          {
            id: 2,
            path: "/search?category=women",
            label: "menu-skincare"
          },
          {
            id: 3,
            path: "/search?category=women",
            label: "menu-premium-beauty"
          },
          {
            id: 4,
            path: "/search?category=women",
            label: "menu-lipsticks"
          }
        ]
      },
      {
        id: 11,
        path: "/search?category=sports",
        label: "menu-gadgets",
        subMenu: [
          {
            id: 1,
            path: "/search?category=sports",
            label: "menu-smart-wearables"
          },
          {
            id: 2,
            path: "/search?category=sports",
            label: "menu-headphones"
          }
        ]
      },
      {
        id: 12,
        path: "/search?category=women",
        label: "menu-jewellers",
        subMenu: [
          {
            id: 1,
            path: "/search?category=women",
            label: "menu-fashion-jewellers"
          },
          {
            id: 2,
            path: "/search?category=women",
            label: "menu-fine-jewellers"
          }
        ]
      },
      {
        id: 13,
        path: "/search?category=bags",
        label: "menu-backpacks"
      },
      {
        id: 14,
        path: "/search?category=bags",
        label: "menu-handbags-wallets"
      }
    ]
  },
  {
    id: 5,
    path: "/search",
    label: "menu-search"
  },
  {
    id: 6,
    path: "/shops",
    label: "menu-shops"
  },
  {
    id: 7,
    path: "/",
    label: "menu-pages",
    subMenu: [
      {
        id: 1,
        path: "/",
        label: "menu-users",
        subMenu: [
          {
            id: 1,
            path: "/my-account",
            label: "menu-my-account"
          },
          {
            id: 2,
            path: "/signin",
            label: "menu-sign-in"
          },
          {
            id: 3,
            path: "/signup",
            label: "menu-sign-up"
          },
          {
            id: 4,
            path: "/forget-password",
            label: "menu-forget-password"
          }
        ]
      },
      {
        id: 2,
        path: "/faq",
        label: "menu-faq"
      },
      {
        id: 3,
        path: "/privacy",
        label: "menu-privacy-policy"
      },
      {
        id: 4,
        path: "/terms",
        label: "menu-terms-condition"
      },
      {
        id: 5,
        path: "/contact-us",
        label: "menu-contact-us"
      },
      {
        id: 6,
        path: "/checkout",
        label: "menu-checkout"
      },
      {
        id: 7,
        path: "/collections/on-sale",
        label: "menu-collection"
      },
      {
        id: 8,
        path: "/search",
        label: "menu-category"
      },
      {
        id: 9,
        path: "/my-account/orders",
        label: "menu-order"
      },
      {
        id: 10,
        path: "/404",
        label: "menu-404"
      }
    ]
  }
]
