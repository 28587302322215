import PageHeader from "../../../Components/page-header";
import Divider from "../../../Components/ui/divider";
import Container from "../../../Components/ui/container";
import {useSettings} from "../../contexts/settings-context";

const text = ({ companyName, schedule, phone }) =>  '1. ЗАГАЛЬНІ ПОЛОЖЕННЯ##' +
    '##' +
    `1.1. Ця Угода укладається між компанією ${companyName}(далі по тексту - ${companyName}), що надає доступ та послуги сайту luname-shop.com (далі - "Сайт").Відвідувач Сайту - це будь-яка фізична особа, яка відвідує Сайт і користується наданими послугами, навіть без реєстрації та авторизації на Сайті.##` +
    '##' +
    '1.2. Угода регулює умови використання ресурсів, сервісів та можливостей Сайту luname-shop.com, а також встановлює правила збору та використання особистих даних відвідувачів Сайту. Угода також надає інформацію щодо прав та можливостей відвідувачів щодо своїх особистих даних.##' +
    '##' +
    '1.3. Усі надані особисті дані захищаються та обробляються відповідно до умов цієї Угоди. Відвідувач Сайту розуміє та приймає умови цієї Угоди в момент надання своїх особистих даних.##' +
    '##' +
    '1.4. Користуючись Сайтом, відвідувач надає згоду на збір та використання особистих даних відповідно до чинного законодавства та цієї Угоди.##' +
    '##' +
    '1.5. Використовувати інтерактивні ресурси сайту luname-shop.com, вести онлайн-спілкування можуть як зареєстровані, так і незареєстровані відвідувачі сайту.##' +
    '##' +
    '2. ОСОБИСТІ ДАНІ ТА БЕЗПЕКА##' +
    '##' +
    '2.1. Особисті дані, надані відвідувачами Сайту, використовуються для обробки замовлень, надання доступу до спеціальної інформації та в маркетингових цілях.##' +
    '##' +
    '2.2Інформація щодо акцій, знижок та інших пропозицій може надсилатися на адресу електронної пошти, вказану при реєстрації, проте відвідувач Сайту має право відмовитися від розсилки в будь-який момент без пояснення причин. Сервісні повідомлення, пов\'язані з обробкою замовлень, надсилаються автоматично та не підлягають відхиленню відвідувачем Сайту.##' +
    '##' +
    '2.3. Сайт luname-shop.com використовує технологію cookie, за допомогою якої він налаштовується на роботу особисто з відвідувачами сайту. Cookie не містять конфіденційну інформацію і не передаються третім особам.##' +
    '##' +
    '2.4. Отримана інформація про ip-адресу відвідувача сайту не використовується для встановлення особистості відвідувача.##' +
    '##' +
    '2.5. Дані на сайті luname-shop.com мають суто інформативний характер і можуть в будь-який час бути змінені, оновлені чи доповнені.##' +
    '##' +
    '3. КОНТЕНТ САЙТУ##' +
    '##' +
    '3.1. Контент сайту luname-shop.com, є інтелектуальною власністю ФОП Кривдік Д.В. Його використання встановлено чинним законодавством України.##' +
    '##' +
    '3.2. На сайті luname-shop.com є посилання, що дозволяють перейти на інші сайти. ФОП Кривдік Д.В. не несе відповідальності за відомості, що публікуються на цих сайтах, і надає посилання на них тільки з метою забезпечення зручності для відвідувачів свого сайту.##' +
    '##' +
    '4. ВИКОРИСТАННЯ МАТЕРІАЛІВ САЙТУ##' +
    '##' +
    `4.1. Усі авторські права на контент Сайту luname-shop.com, ресурси та сервіси, якщо інше не вказано, належать компанії ${companyName}.##`+
    '##' +
    `4.2. Копіювання матеріалів сайту без попередньої письмової згоди компанії ${companyName} заборонено.##` +
    '##' +
    '4.3. Усі назви, бренди, торгові марки, логотипи та слогани, які зареєстровані відповідно до закону, є власністю їх законних власників.##' +
    '##' +
    '5. ЗАКЛЮЧНІ ПОЛОЖЕННЯ##' +
    '##' +
    '5.1. Сайт та надані сервіси можуть тимчасово бути недоступними частково або повністю унаслідок профілактичних робіт, або з інших технічних причин. Технічна служба Сайту має право проводити необхідні профілактичні та інші роботи без попереднього повідомлення відвідувачів.##' +
    '##' +
    '5.2. Відвідувач Сайту погоджується з умовами Угоди та розуміє, що факт користування Сайтом luname-shop.com вважатиметься згодою з умовами Угоди з моменту фактичного використання Сайту.##' +
    '##' +
    `5.3. Діяльність компанії ${companyName} проводиться відповідно до законодавства України. Будь-які претензії, суперечки та офіційні звернення будуть розглядатися відповідно до законодавства України.##` +
    '##' +
    `5.4. Умови цієї Угоди можуть бути змінені компанією ${companyName} в односторонньому порядку. `

const ConfidentialPage = () => {
    const setting = useSettings()
    const textArray = text(
        { schedule: setting.schedule, companyName: setting?.siteTitle, phone: setting?.contactDetails?.phone }
    ).split('##')
    return (
        <>
            <PageHeader pageHeader="Політика конфіденційності" pageSubHeader="LunaMe"/>
            <Divider className="mb-0" />

            <Container>
                <div className={'py-10'}>
                    {
                        textArray.map((el, i) => (
                            <p>{el}</p>
                        ))
                    }
                </div>
            </Container>
        </>
    )
}
export default ConfidentialPage
