import { createSlice } from '@reduxjs/toolkit'
import {filterProductsHelper} from "../helpers/filter-products";
import {productsData} from "../crmData";
// import type { PayloadAction } from '@reduxjs/toolkit'

export const DEFAULT_PRODUCTS_PER_PAGE = 16
const initialState = {
    products: [],
    cardItems: [],
    fastBuyItems: [],
    productsForShowInList: DEFAULT_PRODUCTS_PER_PAGE,
}

export const productsSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        applyFiltersAndSorting: (state, {payload}) => {
            state.products = filterProductsHelper(productsData.data, payload)
        },
        setCardItems: (state, {payload}) => {
            state.cardItems = payload
        },
        setFastBuyItems: (state, {payload}) => {
            state.fastBuyItems = payload
        },
        setProductsForShowAmount: (state, {payload}) => {
            state.productsForShowInList = payload
        }
    },
})

// Action creators are generated for each case reducer function
export const {
    setCardItems,
    setFastBuyItems,
    applyFiltersAndSorting,
    setProductsForShowAmount,
} = productsSlice.actions

export default productsSlice.reducer
