// import { useCart } from '@store/quick-cart/cart.context';
// import { useTranslation } from 'next-i18next';
// import ItemCard from './item-card';
// import EmptyCartIcon from '@components/icons/empty-cart';
// import usePrice from '@lib/use-price';
// import { ItemInfoRow } from './item-info-row';
// import { CheckAvailabilityAction } from '@components/checkout/action/check-availability-action';
// @ts-ignore
import ItemInfoRow from "./item-info-row";
import {isEmpty} from "lodash";
import ItemCard from "./item-card";
import {useSelector} from "react-redux";
import {useMemo} from "react";
import usePrice, {getCardItemsTotalPrice} from "../../../helpers";

const RightSideView = ({ items }) => {
    const total = useMemo(() => getCardItemsTotalPrice(items), [items])
    // const { cardItems: items } = useSelector((state) => state.products)
  // const { t } = useTranslation("common");
  // const t = (st) => st
  // const { items, total, isEmpty } = useCart();
  const { price: subtotal } = usePrice(
     {
      amount: total,
    }
  );

  return (
    <div className="w-full">
      <h2 className="text-xl xl:text-2xl font-bold text-heading mb-5 md:mb-6 -mt-2 text-center lg:text-start">
        Ваше замовлення
      </h2>
      <div className="border border-gray-300 rounded-md overflow-hidden">
        <div className="flex flex-col">
          {isEmpty([{}]) ? (
            <div className="h-full flex flex-col items-center justify-center py-8">
               {/*<EmptyCartIcon width={190} height={205} />*/}
               {/*<h4 className="mt-6 text-[15px] text-heading font-semibold">*/}
               {/*  {t("text-no-products")}*/}
               {/*</h4>*/}
            </div>
          ) : (
            <>
              <div className="flex items-center justify-between text-heading text-base font-semibold bg-gray-200 px-6 py-3.5 border-b border-gray-300">
                <span>Товари</span>
                <span>Ціна</span>
              </div>
              <div className="px-6 py-2.5">
                {items?.map((item) => (
                  <ItemCard item={item} key={item.id} />
                ))}
              </div>
            </>
          )}
        </div>
        <div className="">
          <ItemInfoRow title={'Загальна сума'} value={subtotal} />
          {/*<ItemInfoRow*/}
          {/*  title={"text-tax"}*/}
          {/*  value={"text-calculated-checkout"}*/}
          {/*/>*/}
          {/*<ItemInfoRow*/}
          {/*  title={"text-shipping"}*/}
          {/*  value={"text-calculated-checkout"}*/}
          {/*/>*/}
        </div>
      </div>
    </div>
  );
};
export default RightSideView;
