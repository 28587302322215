import axios from "axios";
import PageHeader from "../../Components/page-header";
import Container from "../../Components/ui/container";
import Button from "../../Components/ui/button";
import {getCrmCategories, getProducts, getVariations, updateProductsInCrm} from "../../helpers/http";
// const INITIAL_CRM_URL = 'https://swapi.dev/api/people/1'
const INITIAL_CRM_URL = 'http://127.0.0.1:5001/lunameshoes/us-central1/subsctiptionsTest'
// const INITIAL_CRM_URL = 'http://127.0.0.1:5001/lunameshoes/us-central1/serverLanding'
//
// const INITIAL_CRM_URL = 'https://us-central1-lunameshoes.cloudfunctions.net/serverLanding'
// const INITIAL_CRM_URL = 'https://us-central1-lunameshoes.cloudfunctions.net/subsctiptionsTest'
const GET_PRODUCTS_URL = '/products/byIds'
const CREATE_NEW_ORDER_URL = '/products/createOrder'
const UPDATE_PRODUCTS_CRM_URL = '/products/updateProductsCrm'
const CRM_CATEGORIES_URL = '/products/crmCategories'
const token = 'OTY5MWJjZjg1ZDRiYzY5YjQzMzE5OThmZDFjNTM1YWFmMjQ4ZTc4Mw'
// const sendRequest = async ({path, method = 'get', data = {}}) => {
//     try {
//         return await axios({
//             method,
//             data,
//             url: `${INITIAL_CRM_URL}${path}`,
//             headers: {
//                 "Content-Type": "application/json",
//                 // "Accept": "application/json",
//             }
//         })
//     } catch (e) {
//         // console.log(e);
//         return {
//             error: e
//         }
//     }
// }
// const getProducts = () => {
//     return sendRequest({path: GET_PRODUCTS_URL})
// }
// const createNewOrder = () => {
//     return sendRequest({path: CREATE_NEW_ORDER_URL, method: 'POST', data: {'name': 'PETRO'}})
// }
// const updateProductsInCrm = () => {
//     return sendRequest({path: UPDATE_PRODUCTS_CRM_URL})
// }
// const getCrmCategories = () => {
//     return sendRequest({path: CRM_CATEGORIES_URL})
// }
//
const copyToClipboard = async (data) => {
    await navigator.clipboard.writeText(JSON.stringify(data))
}

function AdminPanelPage() {
    const asFunct = async () => {


        // const {data, error} = await createNewOrder()

    }
    const getProductsData = async () => {
        const {data, error} = await getProducts()
        await copyToClipboard(data)
        console.log('LPLPLPPLLPLPL', data);
    }

    const updateProductsFromMyDropData = async () => {
        const {data, error} = await updateProductsInCrm()
    }

    const getCategories = async () => {
        const {data, error} = await getCrmCategories()
        await copyToClipboard(data)
    }
    const getProductsVariations = async () => {
        const {data, error} = await getVariations()
        await copyToClipboard(data)
    }




    return (
        <>
            <PageHeader pageHeader="Наші контакти" pageSubHeader="LunaMe"/>
            <Container>
            <div className={'flex flex-col mt-4'}>
                <Button className="mb-3" onClick={() => updateProductsFromMyDropData()}>Update crm from My drop data</Button>
                <Button className="mb-3" onClick={() => getCategories()}>Get CRM categories</Button>
                <Button className="mb-3" onClick={() => getProductsVariations()}>Get products variations</Button>
                <Button onClick={() => getProductsData()} className="mb-3">
                    get products data
                </Button>
            </div>
                {/*<div className="my-14 lg:my-16 xl:my-20 px-0 pb-2 lg: xl:max-w-screen-xl mx-auto flex flex-col md:flex-row w-full">*/}
                {/*    <div className="md:w-full lg:w-2/5 2xl:w-2/6 flex flex-col h-full">*/}
                {/*        /!*<ContactInfoBlock />*!/*/}
                {/*    </div>*/}
                {/*    <div className="md:w-full lg:w-3/5 2xl:w-4/6 flex h-full md:ms-7 flex-col lg:ps-7">*/}
                {/*        <div className="flex pb-7 md:pb-9 mt-7 md:-mt-1.5">*/}
                {/*            <h4 className="text-2xl 2xl:text-3xl font-bold text-heading">*/}
                {/*                {"text-get-in-touch"}*/}
                {/*            </h4>*/}
                {/*        </div>*/}
                {/*        /!*<ContactForm />*!/*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<Subscription />*/}
            </Container>
        </>
    );
}



export default AdminPanelPage
