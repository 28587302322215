import Container from "../../Components/ui/container";
import PageHeader from "../../Components/page-header";
import axios from "axios";
import ContactInfoBlock from "./components/contact-info";
import ContactForm from "./components/contact-form";

function ContactUsPage() {

    return (
        <>
            <PageHeader pageHeader="Наші контакти" pageSubHeader="LunaMe"/>
            <Container>
                <div className="my-14 lg:my-16 xl:my-20 px-0 pb-2 lg: xl:max-w-screen-xl mx-auto flex flex-col md:flex-row w-full">
                    <div className="md:w-full lg:w-2/5 2xl:w-2/6 flex flex-col h-full">
                        <ContactInfoBlock />
                    </div>
                    <div className="md:w-full lg:w-3/5 2xl:w-4/6 flex h-full md:ms-7 flex-col lg:ps-7">
                        <div className="flex flex-col pb-5 md:pb-7 mt-7 md:-mt-1.5">
                            <h4 className="text-2xl 2xl:text-3xl font-bold text-heading">
                                Будемо на зв'язку
                            </h4>
                            <p className={'text-sm'}>Заповніть форму і наш менеджер вам перетелефонує.</p>
                        </div>
                        <ContactForm />
                    </div>
                </div>
            </Container>
        </>
    );
}



export default ContactUsPage
