// import Container from "@components/ui/container";
// import { siteSettings } from "@settings/site.settings";

import Container from "../../../Components/ui/container";
import {siteSettings} from "../../contexts/settings-context";

const year = new Date().getFullYear();
const Copyright= ({ payment }) => {
  // const { t } = useTranslation("footer");
  const t = tex => tex
  return (
    <div className="border-t border-gray-300 pt-5 pb-20 sm:pb-22 md:pb-5 mb-2 sm:mb-0">
      <Container className="flex flex-col-reverse md:flex-row text-center md:justify-between">
        <p className="text-body text-xs md:text-[13px] lg:text-sm leading-6">
         &copy; 2019 - {year}&nbsp;
          <a
            className="font-semibold text-gray-700 transition-colors duration-200 ease-in-out hover:text-body"
            href={siteSettings.author.websiteUrl}
          >
            {siteSettings.author.name}
          </a>
          &nbsp; Всі права захищені
        </p>
      </Container>
    </div>
  );
};

export default Copyright;
