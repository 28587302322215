import {createQueryString} from "../../helpers";

const addSearchParams = (url, searchParams = {}) => {
    return `${url}${createQueryString(searchParams)}`
}

const ROUTS = {
    ORDER_CREATED_SUSSES: ({totalPrice, id} = {}) => addSearchParams(`/sussesOrderCreated/${id}/${totalPrice}`),
    NEW_ORDER_PAGE: ({searchParams, buyType = 'card'}) => addSearchParams(`/new-order/${buyType}`, searchParams),
    PRODUCTS_LIST_PAGE: ({searchParams} = {}) => addSearchParams('/products', searchParams),
    PRODUCTS_BY_CATEGORY: (category) => addSearchParams('/products', { category }),
    PRODUCT_PAGE: ({id, searchParams}) => addSearchParams(`/products/${id}`, searchParams),
    CONTACTS_PAGE: () => addSearchParams(`/contacts`, ),
    DELIVERY_PAGE: addSearchParams(`/delivery`, ),
    RETURN_EXCHANGE_PAGE: addSearchParams(`/return`, ),
    RECOMMENDATIONS_PAGE: addSearchParams(`/recommendations`, ),
    ABOUT_US_PAGE: addSearchParams(`/aboutUs`, ),
    AGREEMENT_PAGE: addSearchParams(`/agreement`, ),
    CONFIDENTIAL_PAGE: addSearchParams(`/confidential`, ),

}

export {
    ROUTS
}
