import Divider from "../../../Components/ui/divider";
import Container from "../../../Components/ui/container";
import {Component, useEffect, useState} from "react";
import RightSideView from "../components/unverified-item-list";
import Button from "../../../Components/ui/button";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import TextArea from "../../../Components/ui/text-area";
import Input from "../../../Components/ui/input";
import PageHeader from "../../../Components/page-header";
import {useDispatch, useSelector} from "react-redux";
import {closeCartModal} from "../../../redux/uiSlice";
import {createNewOrder} from "../../../helpers/http";
import {useNavigate, useParams} from "react-router";
import {ROUTS} from "../../data/routes";
import {setCardItems} from "../../../redux/productsSlice";
import {toast} from "react-toastify";
import {checkIfAbleSendFormData, getCardItemsTotalPrice} from "../../../helpers";
import {FB} from "../../../App";
// import { useTranslation } from "next-i18next";
// import { billingAddressAtom, shippingAddressAtom } from "@store/checkout";
// import dynamic from "next/dynamic";
// import useUser from "@framework/auth/use-user";
// import { AddressType } from "@framework/utils/constants";
// import { getLayout } from "@components/layout/layout";
// import { Address } from "@framework/types";
// import Divider from "@components/ui/divider";
// import Container from "@components/ui/container";
// import Subscription from "@components/common/subscription";
//
// export { getStaticProps } from "@framework/ssr/common";
//
// const ScheduleGrid = dynamic(
//   () => import("@components/checkout/schedule/schedule-grid")
// );
// const AddressGrid = dynamic(() => import("@components/checkout/address-grid"));
// const ContactGrid = dynamic(
//   () => import("@components/checkout/contact/contact-grid")
// );
// const RightSideView = dynamic(
//   () => import("@components/checkout/right-side-view")
// );

// class RightSideView extends Component {
//   render() {
//     return null;
//   }
// }

export const contactFormSchema = yup.object().shape({
    full_name: yup.string().min(3, 'Мінімальна кількість символів 3').required('Це поле обов\'язкове'),
    phone: yup.string().min(9, 'Номер телефону закороткий').max(13, 'Номер телефону завеликий'),
    // phone: yup.string().matches(/^(?=.*\d).{13,}$/, 'Номер телефону '),
    // subject: yup.string().required('forms:error-subject-required'),
    description: yup.string(),
});

export const defaultValues = {
    full_name: "",
    phone: "+380",
    description: ""
};

export default function NewOrderPage() {
    const navigate = useNavigate()
    let { buyType } = useParams();
    const dispatch = useDispatch()
    const { cardItems, fastBuyItems } = useSelector((state) => state.products)
    const [sendingData, setSendingData] = useState(false)
    const items = buyType ===  'buyFast' ? fastBuyItems : cardItems
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(contactFormSchema),
        defaultValues
    });


    async function onSubmit(values) {
        const ableSendForm = checkIfAbleSendFormData('orderForm')
        if (!ableSendForm) return null
        const { description, ...contactData} = values
        setSendingData(true)
        try {
            const { data } = await createNewOrder({
                contact: contactData,
                manager_comment: description,
                products: items.map(el => {
                    if (!el.sku) throw new Error('sku - not exist in product')
                    return {
                        "sku": el.sku,
                        "price": Number(el.unitPrice),
                        "quantity": el.quantity,
                        "name": el.name,
                        "picture": el.image
                    }
                })
            })
            if (data.orderNumber) {
                localStorage.setItem('orderForm', String(Math.floor(Date.now() / 1000)))
                const totalPrice = getCardItemsTotalPrice(items)
                FB.makeParchese({totalPrice})
                toast('Замовлення успішно створене', {
                    type: "default",
                    progressClassName: "fancy-progress-bar",
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                dispatch(setCardItems([]))
                navigate(ROUTS.ORDER_CREATED_SUSSES({id: data.orderNumber?.slice(1), totalPrice}), { replace: true, })
            } else {
                FB.problemWhenCreateOrder(values)
                toast('Помилка при створенні замовлення.Зв\'яжіться будь ласка з нашим менеджером', {
                    type: "error",
                    progressClassName: "fancy-progress-bar",
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });

            }
        } catch (e) {
            FB.problemWhenCreateOrder(values)
            toast('Помилка при створенні замовлення.Зв\'яжіться будь ласка з нашим менеджером', {
                type: "error",
                progressClassName: "fancy-progress-bar",
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        } finally {
             setSendingData(false)

        }




    }


  return (
    <>
        <PageHeader pageHeader="Введіть свої данні" pageSubHeader="LunaMe"/>
      <Divider className="mb-0" />
      <Container>
        <div className="py-8 lg:py-10 xl:py-14 max-w-[1280px] mx-auto">
          <div className="flex flex-col lg:flex-row items-center lg:items-start m-auto lg:space-s-7 xl:space-s-12 w-full">
            <div className="w-full space-y-6">
                <h2 className="text-xl xl:text-2xl font-bold text-heading mb-5 md:mb-6 -mt-2 text-center lg:text-start">
                    Контактна інформація
                </h2>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="w-full "
                    noValidate
                >
                    <div className="flex flex-col space-y-5">
                        <div className="flex flex-col md:flex-row space-y-5 md:space-y-0">
                            <Input
                                labelKey="Ім'я"
                                placeholderKey="ПІБ"
                                {...register("full_name")}
                                className="w-full md:w-1/2 "
                                errorKey={errors.full_name?.message}
                                variant="solid"
                            />
                            <Input
                                labelKey="Телефон"
                                type="tel"
                                placeholderKey="Номер телефону"
                                {...register("phone")}
                                className="w-full md:w-1/2 md:ms-2.5 lg:ms-5 mt-2 md:mt-0"
                                errorKey={errors.phone?.message}
                                variant="solid"
                            />
                        </div>
                        <TextArea
                            labelKey="Коментар до замовлення"
                            {...register("description")}
                            className="relative mb-4"
                            placeholderKey="Коментар"
                            errorKey={errors.description?.message}
                        />
                        <div className="relative">
                            <Button
                                loading={sendingData}
                                type="submit"
                                className="h-12 lg:h-14 mt-1 text-sm lg:text-base w-full sm:w-auto"
                            >
                                Оформити замовлення
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
            <div className="w-full lg:w-[320px] xl:w-[440px] flex-shrink-0 mt-10 sm:mt-12 lg:mt-0">
              <RightSideView items={items}/>
            </div>
          </div>
        </div>
        {/*<Subscription />*/}
      </Container>
    </>
  );
}

// CheckoutPage.authenticate = true;
// CheckoutPage.getLayout = getLayout;
