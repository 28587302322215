import {Outlet, NavLink, useNavigate, redirect, useSearchParams} from "react-router-dom";
import React, {useEffect} from 'react'
import ProductCard from "../../../Components/products/product-card";
import Container from "../../../Components/ui/container";
import Divider from "../../../Components/ui/divider";
// import ProductCard from "../../../Components/product-card";

// import Container from "@components/ui/container";
// import { getLayout } from "@components/layout/layout";
// import Subscription from "@components/common/subscription";
// import { ShopFilters } from "@components/shop/filters";
import StickyBox from "react-sticky-box";
import {ShopFilters} from "../components/filters";
import ProductSearchBlock from "../../../Components/products/product-search-block";
import {useLocation} from "react-router";
import {queryStringToObject} from "../../../helpers";
import {initialFilterParams} from "../../../crmData/filters-data";
import {useDispatch, useSelector} from "react-redux";
import {applyFiltersAndSorting} from "../../../redux/productsSlice";
// import ActiveLink from "@components/ui/active-link";
// import { BreadcrumbItems } from "@components/common/breadcrumb";
// import { ROUTES } from "@lib/routes";
// import { useTranslation } from "next-i18next";
// import Divider from "@components/ui/divider";
// import ProductSearchBlock from "@components/product/product-search-block";
//
// export { getStaticProps } from "@framework/ssr/products-filter";

 function ProductListPage() {
     const [searchParams, setSearchParams] = useSearchParams();
     const { search } = useLocation();
     const dispatch = useDispatch()
     const queryData = React.useMemo(() => queryStringToObject(search, initialFilterParams), [searchParams]);

     const { products } = useSelector((state) => state.products)

     useEffect(() => {
         console.log(queryData, 'queryDataqueryDataqueryData');
         dispatch(applyFiltersAndSorting(queryData))
     }, [search])


    return (
        <>
            <Divider className="mb-2" />
            <Container>
                <div className={`flex pt-8 pb-16 lg:pb-20`}>
                    <div className="flex-shrink-0 pe-24 hidden lg:block w-96">
                        <StickyBox offsetTop={100} offsetBottom={20}>
                            <ShopFilters />
                        </StickyBox>
                    </div>

                    <div className="w-full lg:-ms-9">
                        <ProductSearchBlock products={products}/>
                    </div>
                </div>
            </Container>
        </>
    );
}

export default ProductListPage
