import React from 'react'
import usePrice, {fadeInOut} from "../../helpers";
import { motion } from "framer-motion";
import Counter from "../../Components/ui/counter";
import {Link} from "react-router-dom";
// import { IoIosCloseCircle } from "react-icons/io/IoIosCloseCircle";

const CartItem = ({ item, addItemToCart, removeItemFromCart, handleSelectItem }) => {
    // const { addItemToCart, removeItemFromCart, clearItemFromCart } = useCart();
    const { cardItems, productId } = item

    const { price: unitPrice } = usePrice({
        amount: item.unitPrice,
    });

    const { price: totalPrice } = usePrice({
        amount: item.unitPrice * item.quantity,
    });




    return (
        <motion.div
            layout
            initial="from"
            animate="to"
            exit="from"
            variants={fadeInOut(0.25)}
            className={`group w-full h-auto flex justify-start items-center bg-white py-4 md:py-7 border-b border-gray-100 relative last:border-b-0`}
            title={item?.name}
        >
            <div className="relative flex w-24 md:w-28 h-24 md:h-28 rounded-md overflow-hidden bg-gray-200 flex-shrink-0 cursor-pointer me-4">
                <img
                    src={item?.image ?? "/assets/placeholder/cart-item.svg"}
                    width={112}
                    height={112}
                    loading="eager"
                    alt={item.name || "Product Image"}
                    className="bg-gray-300 object-cover"
                />
                <div
                    className="absolute top-0 start-0 h-full w-full bg-black bg-opacity-30 md:bg-opacity-0 flex justify-center items-center transition duration-200 ease-in-out md:group-hover:bg-opacity-30"
                    onClick={() => {}}
                    role="button"
                >
                    {/*<IoIosCloseCircle className="relative text-white text-2xl transform md:scale-0 md:opacity-0 transition duration-300 ease-in-out md:group-hover:scale-100 md:group-hover:opacity-100" />*/}
                </div>
            </div>

            <div className="flex flex-col w-full overflow-hidden">
                {/*TODO ADD THIS LiNK*/}
                <span
                    // href={`${ROUTES.PRODUCT}/${item?.slug}`}
                    // to={'#'}
                    onClick={handleSelectItem}
                    className="truncate text-sm text-heading mb-1.5 -mt-1 cursor-pointer"
                >
                    {/*{generateCartItemName(item.name, item.attributes)}*/}
                    {item.name}
                </span>
                <span className="text-sm text-gray-400 mb-2.5">
					Ціна за одиницю : &nbsp;
                    {unitPrice}
				</span>

                <div className="flex items-end justify-between">
                    {/*TODO ADD THIS Counter*/}
                    <Counter
                        quantity={item.quantity}
                        onIncrement={() => addItemToCart(item.id, 1)}
                        disableIncrement={item.quantity >= item.maxQuantity}
                        onDecrement={() => removeItemFromCart(item.id)}
                        variant="dark"
                    />
                    <span className="font-semibold text-sm md:text-base text-heading leading-5">
						{totalPrice}
					</span>
                </div>
            </div>
        </motion.div>
    );
};

export default CartItem;
