import PageHeader from "../../../Components/page-header";
import Divider from "../../../Components/ui/divider";
import Container from "../../../Components/ui/container";
import {useSettings} from "../../contexts/settings-context";

const text = ({ companyName, schedule, phone }) => 'Нові товари можна обміняти або повернути у відділенні Нової Пошти під час перевірки або впродовж 14 днів з моменту покупки, якщо вони не були вживані.##' +
    'Кошти сплачені за товар повернуться вам протягом 3 - 10 банківських днів, в залежності від обраного методу оплати.##' +
    'Якщо оплачували за реквізитами на рахунок ФОП, то повернення займе до 3 робочих днів після прибуття товару на наш склад##' +
    'Якщо оплатили товар у відділенні пошти, то кошти повернуться протягом 10 робочих днів після прибуття товару на наш склад##' +
    'Обов’язково! Здійснюйте повернення на адресу яку надасть наш менеджер. Щоб прискорити процес повернення коштів — повідомте номер декларації менеджеру.##' +
    'Важливо! Товари, що повертаються, повинні бути не пошкодженими та зі збереженими оригінальними етикетками та ярликами.##' +
    'Якщо ви оплачували товар готівкою при отриманні, ви можете відмовитися від нього при огляді. У такому випадку завдаток у 200 грн не повертається##' +
    'Обмін товару відбувається після повернення його в інтернет-магазин.##' +
    `Якщо у вас є додаткові питання, наші менеджери готові надати консультацію ${schedule}.##` +
    `${phone}`

const ReturnExchangePage = () => {
    const setting = useSettings()
    const textArray = text(
        { schedule: setting.schedule, companyName: setting?.siteTitle, phone: setting?.contactDetails?.phone }
    ).split('##')

    return (
        <>
            <PageHeader pageHeader="Повернення чи обмін товару" pageSubHeader="LunaMe"/>
            <Divider className="mb-0" />

            <Container>
                <div className={'py-10'}>
                    {
                        textArray.map((el, i) => (
                            <p className={'mb-4'} key={i}>{el}</p>
                        ))
                    }
                </div>
            </Container>
        </>
    )
}
export default ReturnExchangePage
