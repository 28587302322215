import logo from './logo.svg';
import {
    Routes,
    Route,
    Outlet,
    Link,
    useLocation,
    useNavigate,
    useParams,
} from "react-router-dom";
import SiteLayout from "./Features/layout";
import CartModal from "./Features/Cart";
import React, {useEffect} from "react";
import ReactPixel from 'react-facebook-pixel';

const advancedMatching = { }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: true, // enable logs
};
ReactPixel.init('1338144753504697', advancedMatching, options);

export const FB = {
    makeParchese: (data) => {
        ReactPixel.track('Purchase', data)
    },
    pageView: () => {
        ReactPixel.pageView(); // For tracking page view
    },
    openProductProfile: (productId) => {
        ReactPixel.trackCustom('OPEN_PRODUCT_PROFILE', {productId})
    },
    addToCard: (productId) => {
        ReactPixel.trackCustom('ADD_PRODUCT_TO_CARD', {productId})
    },
    makeFastBuy: (productId) => {
        ReactPixel.trackCustom('MAKE_FAST_BUY', {productId})
    },
    problemWhenCreateOrder: (data = {}) => {
        ReactPixel.trackCustom('PROBLEM_WHEN_CREATE_ORDER', data)
    },
}

// ReactPixel.track('Purchase', {currency: "USD", value: 30.00}); // For tracking default events. More info about standard events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#standard-events
// ReactPixel.trackSingle('PixelId', event, data); // For tracking default events.
// ReactPixel.trackCustom(event, data); // For tracking custom events. More info about custom events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#custom-events
// ReactPixel.trackSingleCustom('PixelId', event, data); // For tracking custom events.
// import { Outlet } from "react-router-dom";

// const Layout = ({ children}) => {
//     return (
//         <div>
//             <div>Layout Header</div>
//             {children}
//             <div>Layout Footer</div>
//         </div>
//     )
// }

function App() {
    // let location = useLocation();
    const { pathname } = useLocation();

    React.useEffect(() => {
        // const unlisten = history.listen(() => {
            window.scrollTo(0, 0);
        // });
        // return () => {
        //     unlisten();
        // }
        console.log('kokookokokko')
    }, [pathname]);
    // useEffect(() => {
    //     const unlisten = history.listen(() => {
    //         window.scrollTo(0, 0);
    //     });
    //     return () => {
    //         unlisten();
    //     }
    // }, []);
  return (
    <>
        <CartModal/>
        <SiteLayout>
            <Outlet />
        </SiteLayout>
      {/*<header className="App-header">*/}
      {/*  <img src={logo} className="App-logo" alt="logo" />*/}

      {/*  <a*/}
      {/*    className="App-link"*/}
      {/*    href="https://reactjs.org"*/}
      {/*    target="_blank"*/}
      {/*    rel="noopener noreferrer"*/}
      {/*  >*/}
      {/*    Learn React*/}
      {/*  </a>*/}
      {/*</header>*/}
    </>
  );
}

export default App;
