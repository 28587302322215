import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import Input from "../../../Components/ui/input";
import TextArea from "../../../Components/ui/text-area";
import Button from "../../../Components/ui/button";
import {useState} from "react";
import {contactFormSchema, defaultValues} from "../../OrderPage/pages/checkout";
import {createNewOrder, createRecallCard} from "../../../helpers/http";
import {checkIfAbleSendFormData} from "../../../helpers";



const ContactForm = () => {
	const [loading, setLoading] = useState(false)
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset
	} = useForm({
    resolver: yupResolver(contactFormSchema),
    defaultValues
  });
  async function onSubmit(values) {
	  const ableSendForm = checkIfAbleSendFormData('contactForm')
	  if (!ableSendForm) return null
	  const { description, ...contactData} = values
	  setLoading(true)
	  try {
		  const {data} = await createRecallCard({
			  contact: contactData,
			  manager_comment: description,
		  })
		  if (data.orderNumber) {
			  reset(defaultValues)
			  localStorage.setItem('contactForm', String(Math.floor(Date.now() / 1000)))
			  toast('Ваша заявка прийнята. Наш менеджер вам перетелефонує найближчи часом', {
				  type: "default",
				  progressClassName: "fancy-progress-bar",
				  position: "top-right",
				  autoClose: 4000,
				  hideProgressBar: false,
				  closeOnClick: true,
				  pauseOnHover: true,
				  draggable: true,
			  });
		  } else {
			  toast('Помилка. Зателефонуйте будь ласка нам або напишіть у соц марежах', {
				  type: "error",
				  progressClassName: "fancy-progress-bar",
				  position: "top-right",
				  autoClose: 5000,
				  hideProgressBar: false,
				  closeOnClick: true,
				  pauseOnHover: true,
				  draggable: true,
			  });
		  }
	  } catch (e) {
		  toast('Помилка. Зателефонуйте будь ласка нам або напишіть у соц марежах', {
			  type: "error",
			  progressClassName: "fancy-progress-bar",
			  position: "top-right",
			  autoClose: 5000,
			  hideProgressBar: false,
			  closeOnClick: true,
			  pauseOnHover: true,
			  draggable: true,
		  });
	  } finally {
		  setLoading(false)
	  }
    // await mutate(values, {
    //   onSuccess: (data) => {
    //     if (data?.success){
    //       toast.success(t('contact-success-message'));
    //     }else {
    //       toast.error(t(data?.message!));
    //     }
    //   }
    // });
  }

  return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			className="w-full mx-auto flex flex-col justify-center "
			noValidate
		>
			<div className="flex flex-col space-y-5">
				<div className="flex flex-col md:flex-row space-y-5 md:space-y-0">
					<Input
						labelKey="Ім'я"
						placeholderKey="Ім'я"
						{...register("full_name")}
						className="w-full md:w-1/2 "
						errorKey={errors.full_name?.message}
						variant="solid"
					/>
					<Input
						labelKey="Телефон"
						type="tel"
						placeholderKey="Номер телефону"
						{...register("phone")}
						className="w-full md:w-1/2 md:ms-2.5 lg:ms-5 mt-2 md:mt-0"
						errorKey={errors.phone?.message}
						variant="solid"
					/>
				</div>
				<TextArea
					labelKey="Повідомлення"
					{...register("description")}
					className="relative mb-4"
					placeholderKey="Повідомлення"
					errorKey={errors.description?.message}
				/>
				<div className="relative">
					<Button
						loading={loading}
						type="submit"
						className="h-12 lg:h-14 mt-1 text-sm lg:text-base w-full sm:w-auto"
					>
						Надіслати
					</Button>
				</div>
			</div>
		</form>
	);
};

export default ContactForm;
