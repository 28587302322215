import cn from "classnames";
import {OverlayScrollbarsComponent, useOverlayScrollbars} from "overlayscrollbars-react";
// import "overlayscrollbars/css/OverlayScrollbars.css";
import 'overlayscrollbars/overlayscrollbars.css';
import {useWindowSize} from "../helpers";
import {useEffect, useRef} from "react";


const Scrollbar = ({
                                                 options,
                                                 children,
                                                 style,
                       className,
                                                 ...props
                                             }) => {
    const { width, height } = useWindowSize();
    const ref = useRef();
    const [initialize, instance] = useOverlayScrollbars({ options: {
            className: cn("os-theme-thin", className),
            scrollbars: {
                autoHide: "scroll",
            },
            ...options,
        }, defer: true });

    useEffect(() => {
        initialize(ref.current);
    }, [initialize, height]);
    // useEffect(() => {
    //     console.log(height);
    // }, [width, height])
    return (
        <div ref={ref} className={className} style={style}>
            {children}
        </div>
        // <OverlayScrollbarsComponent
        //     // defer
        //     element="div"
        //     options={{
        //         className: cn("os-theme-thin", className),
        //         scrollbars: {
        //             autoHide: "scroll",
        //         },
        //         ...options,
        //     }}
        //     className={className}
        //     style={style}
        //     {...props}
        // >
        //     {children}
        // </OverlayScrollbarsComponent>
    );
};

export default Scrollbar;
