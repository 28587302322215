const CounterPlusIcon = ({
  color = "currentColor",
  width = "10px",
  height = "10px",
}) => {
  return (
    <svg
      data-name="plus (2)"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 12 12"
    >
      <g data-name="Group 5367">
        <path
          data-name="Path 17138"
          d="M6.749,5.251V0h-1.5V5.251H0v1.5H5.251V12h1.5V6.749H12v-1.5Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default CounterPlusIcon
