import PageHeader from "../../../Components/page-header";
import Divider from "../../../Components/ui/divider";
import Container from "../../../Components/ui/container";
import {useSettings} from "../../contexts/settings-context";

const text = ({ companyName, schedule, phone }) => `${companyName} - український магазин взуття та аксесуарів, що працює з 2019 року. У зв\'язку з карантинними обмеженнями, ми були змушені закрити фізичний магазин, проте ми продовжуємо допомагати нашим клієнтам знаходити ідеальне взуття в інтернет-магазині.##` +
    '##' +
    'Кожного сезону наші співробітники закуповують високоякісне шкіряне взуття з Туреччини. Завдяки їхній постійній і згуртованій роботі, колекції магазину поповнюються кілька разів протягом сезону.##' +
    'Поза різноманітною лінійкою взуття для жінок, магазин LunaMe славиться колекціями сумок, портфелів, гаманців та ременів.##' +
    'LunaMe це синонім комфорту, якості та професіоналізму. Тисячі задоволених клієнтів знову та знову повертаються до нас за унікальними та стильними моделями, які пропонує наш магазин.##' +
    'Ми раді вітати вас серед наших постійних клієнтів у світі нашого онлайн-магазину!##'

const AboutUsPage = () => {
    const setting = useSettings()
    const textArray = text(
        { schedule: setting.schedule, companyName: setting?.siteTitle, phone: setting?.contactDetails?.phone }
    ).split('##')

    return (
        <>
            <PageHeader pageHeader="Про компанію" pageSubHeader="LunaMe"/>
            <Divider className="mb-0" />

            <Container>
                <div className={'py-10'}>
                    {
                        textArray.map((el, i) => (
                            <p className={'mb-4'} key={i}>{el}</p>
                        ))
                    }
                </div>
            </Container>
        </>
    )
}
export default AboutUsPage
