// import Header from "@components/layout/header/header";
// import Footer from "@components/layout/footer/footer";
// import MobileNavigation from "@components/layout/mobile-navigation/mobile-navigation";
// import Search from "@components/common/search";
import React, {useEffect} from "react";
import Header from "./header/header";
import Footer from "./footer";
import BottomNavigation from "./mobile-navigation/mobile-navigation";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// class MobileNavigation extends React.Component {
//     render() {
//         return null;
//     }
// }

const SiteLayout= ({ children }) => {

    return (
        <div className="flex flex-col min-h-screen">
            <Header />
            <ToastContainer />
            <main
                className="relative flex-grow"
                style={{
                    minHeight: "-webkit-fill-available",
                    WebkitOverflowScrolling: "touch",
                }}
            >
                {children}
            </main>
            <Footer />
            <BottomNavigation />
            {/*<Search />*/}
        </div>
    );
};

export const getLayout = (page) => (
    <SiteLayout>{page}</SiteLayout>
)

export default SiteLayout;
