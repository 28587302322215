// import Image from "next/image";
// import Link from "@components/ui/link";
// import cn from "classnames";
// import { siteSettings } from "@settings/site.settings";
// import { useSettings } from "@contexts/settings.context";

import {Link} from "react-router-dom";
import cn from "classnames";
import {siteSettings, useSettings} from "../../Features/contexts/settings-context";

const Logo = ({
                                                            className,
                                                            ...props
                                                        }) => {
    const { logo, siteTitle } = useSettings();


    return (
        <Link
            href={"/"}
            className={cn("inline-flex focus:outline-none", className)}
            {...props}
        >
            <img
                src={'/images/luname4.png'}
                // src={logo?.original ?? siteSettings.logo.url}
                alt={siteTitle || "ChawkBazar Logo"}
                // TODO: Make it dynamic
                height={40}
                width={200}
                // layout="fixed"
                loading="eager"
            />
        </Link>
    );
};

export default Logo;
