import {intersection, isEmpty} from "lodash";
import {uniq} from "ramda";
import {
    CATEGORIES,
    CATEGORIES_DATA, COLOR_FILTERS_MAP,
    colorFilterConfig, PRICE_FILTERS_MAP, PRICE_VALUES_MAP,
    priceFilterConfig, SHOES_SIZES_DATA,
    shoesFilterCategoryConfig, shoesSizeFilterConfig
} from "../crmData/filters-data";
import {SORTING_OPTIONS} from "../crmData/sorts-data";
const checkPriceFilter = (price) => {
    let pricesFilters = []
    if (price <= 1000) {
        pricesFilters.push(PRICE_VALUES_MAP.less1000)
    } else if ( price >= 1000 && price <= 2000) {
        pricesFilters.push(PRICE_VALUES_MAP.from1000to2000)
    } else if ( price >= 2000 && price <= 3000) {
        pricesFilters.push(PRICE_VALUES_MAP.from2000to3000)
    } else if ( price >= 3000 && price <= 4000) {
        pricesFilters.push(PRICE_VALUES_MAP.from2000to3000)
    }
    return pricesFilters
}
const getPriceFilterForProduct = ({price, max_price, min_price}) => {
    let pricesFilters = []

    if (price) {
        pricesFilters = [...pricesFilters, ...checkPriceFilter(price)]
    }
    if (max_price) {
        pricesFilters = [...pricesFilters, ...checkPriceFilter(max_price)]

    }
    if (min_price) {
        pricesFilters = [...pricesFilters, ...checkPriceFilter(min_price)]

    }
    console.log(uniq(pricesFilters), 'lplplplpderde');
    return uniq(pricesFilters)

}

const filterProductsHelper = (data, filterConfig) => {
    // console.log(filterConfig?.sorting, 'lpplpllpplldd');
    let filteredData = [...data]
    if (filterConfig && !isEmpty(filterConfig)) {
        const selectedCategories = filterConfig?.[shoesFilterCategoryConfig.keyName]?.filter(el => !isEmpty(CATEGORIES_DATA[el]))
        const selectedColors = filterConfig?.[colorFilterConfig.keyName]?.filter(el => !isEmpty(COLOR_FILTERS_MAP[el]))
        const selectedPrices = filterConfig?.[priceFilterConfig.keyName]?.filter(el => !isEmpty(PRICE_FILTERS_MAP[el]))
        const selectedShoesSizes = filterConfig?.[shoesSizeFilterConfig.keyName]?.filter(el => !isEmpty(SHOES_SIZES_DATA[String(el)]))
        if (!isEmpty(selectedCategories)) {
            const showProductsWithSale = selectedCategories?.includes(CATEGORIES.sale)
            console.log(showProductsWithSale, 'dokewokdeoked');
            filteredData = filteredData.filter((product) => {
                const productCategories = product.categories
                if (showProductsWithSale && product?.priceWithoutDiscount > 0) {
                    return true
                }
                if (productCategories && !isEmpty(productCategories)) {
                    // const selectedCategories = filterConfig?.[shoesFilterCategoryConfig.keyName].
                    const similarCategories = intersection(productCategories, selectedCategories)
                    return similarCategories?.length >= 1

                }

            })

        }
        if (selectedColors && !isEmpty(selectedColors)) {
            const convertedSelectedColorsAsCrmValues = selectedColors.map(el => COLOR_FILTERS_MAP[el]?.crmValue)
            filteredData = filteredData.filter(({productColor}) => {
                const similarColors = intersection(productColor, convertedSelectedColorsAsCrmValues)
                return similarColors?.length >= 1
                    // console.log(productColor, 'dekdkoerkodko');
                // if (el.id == 56) {
                // }
            })
        }

        if (selectedShoesSizes && !isEmpty(selectedShoesSizes) && selectedCategories?.includes(CATEGORIES.shoes)) {
            filteredData = filteredData.filter((product) => {
                const isShoes = product?.categories?.includes(CATEGORIES.shoes)
                if (!isShoes) return true
                const productSizes = product?.variations?.map(el => el?.size)
                const similarSizes = intersection(selectedShoesSizes, productSizes || [])
                return similarSizes?.length >= 1
            })
        }
        if (selectedPrices && !isEmpty(selectedPrices)) {

            filteredData = filteredData.filter((product) => {
                const productPriceFilters = getPriceFilterForProduct(product)
                console.log(productPriceFilters, 'edwmkdkemwmk');
                const similarPrices = intersection(selectedPrices, productPriceFilters)
                return similarPrices?.length >= 1
            })
        }


        if (filterConfig?.sorting) {
            switch (filterConfig?.sorting) {
                case SORTING_OPTIONS.priceASC :
                    filteredData.sort((pre, next) => {
                        return pre.min_price - next.min_price
                    })
                    break;
                case SORTING_OPTIONS.priceDesc:
                    filteredData.sort((pre, next) => {
                        return next.max_price - pre.max_price
                    })
                    break;
                default:
                    break;
            }
        }

    }



    return filteredData
}

export {
    filterProductsHelper
}
