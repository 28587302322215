// import Card from "@components/common/card";
// import SectionHeader from "@components/common/section-header";
// import Carousel from "@components/ui/carousel/carousel";
// import CardLoader from "@components/ui/loaders/card-loader";
// import CardRoundedLoader from "@components/ui/loaders/card-rounded-loader";
// import { useCategoriesQuery } from "@framework/category/categories.query";
// import { ROUTES } from "@lib/routes";
// import Alert from "@components/ui/alert";
import { SwiperSlide } from "swiper/react";
import isEmpty from "lodash/isEmpty";
// import NotFoundItem from "@components/404/not-found-item";
// import { useTranslation } from "next-i18next";
import React from "react";
import NotFoundItem from "../404/not-found-item";
import SectionHeader from "../section-header";
import Alert from "../ui/alert";
import Carousel from "../ui/carousel";
import CardRoundedLoader from "../loaders/card-rounded-loader";
import CardLoader from "../loaders/card-loader";
import Card from "../card";
import {ROUTS} from "../../Features/data/routes";
import {
	CATEGORIES, CATEGORIES_DATA,
	shoesFilterCategoryConfig
} from "../../crmData/filters-data";

const breakpoints = {
	"1720": {
		slidesPerView: 8,
		spaceBetween: 28,
	},
	"1400": {
		slidesPerView: 7,
		spaceBetween: 28,
	},
	"1025": {
		slidesPerView: 6,
		spaceBetween: 28,
	},
	"768": {
		slidesPerView: 5,
		spaceBetween: 20,
	},
	"500 ": {
		slidesPerView: 4,
		spaceBetween: 20,
	},
	"0": {
		slidesPerView: 3,
		spaceBetween: 12,
	},
};

const breakpointsCircle = {
	"1720": {
		slidesPerView: 8,
		spaceBetween: 48,
	},
	"1400": {
		slidesPerView: 7,
		spaceBetween: 32,
	},
	"1025": {
		slidesPerView: 6,
		spaceBetween: 28,
	},
	"768": {
		slidesPerView: 5,
		spaceBetween: 20,
	},
	"500 ": {
		slidesPerView: 4,
		spaceBetween: 20,
	},
	"0": {
		slidesPerView: 3,
		spaceBetween: 12,
	},
};

const CategoryBlock = ({
	className = "mb-10 md:mb-11 lg:mb-12 xl:mb-14 lg:pb-1 xl:pb-0",
	sectionHeading,
	type = "circle",
}) => {
  // const { t } = useTranslation();
const t = text => text
	const categories = {
	data: [{
		url: ROUTS.PRODUCTS_LIST_PAGE({searchParams: { [shoesFilterCategoryConfig.keyName]: [CATEGORIES.bags] }}),
		name: CATEGORIES_DATA[CATEGORIES.shoes].label,
		image: '/images/categories/1.png'
		},
		{
		url: ROUTS.PRODUCTS_LIST_PAGE({searchParams: { [shoesFilterCategoryConfig.keyName]: [CATEGORIES.shoes] }}),
		name: CATEGORIES_DATA[CATEGORIES.bags].label,
			image: '/images/categories/2.png'
		},
		{
		url: ROUTS.PRODUCTS_LIST_PAGE({searchParams: { [shoesFilterCategoryConfig.keyName]: [CATEGORIES.remeni] }}),
		name: CATEGORIES_DATA[CATEGORIES.remeni].label,
			image: '/images/categories/3.png'
		},
		{
		url: ROUTS.PRODUCTS_LIST_PAGE({searchParams: { [shoesFilterCategoryConfig.keyName]: [CATEGORIES.wallets] }}),
		name: CATEGORIES_DATA[CATEGORIES.woomenRykzak].label,
			image: '/images/categories/4.png'
		},
		{
			url: ROUTS.PRODUCTS_LIST_PAGE({searchParams: { [shoesFilterCategoryConfig.keyName]: [CATEGORIES.bags] }}),
		name: CATEGORIES_DATA[CATEGORIES.wallets].label,
			image: '/images/categories/5.png'
		},
	]
	}
	const loading = false
	const error = null
	// const { data: categories, isLoading: loading, error } = useCategoriesQuery({
	// 	limit: 10,
    // parent: null
	// });

  if (!loading && isEmpty(categories?.data)) {
    return (
      <NotFoundItem text={t("text-no-categories-found")} />
    )
  }


	return (
		<div className={className}>
			<SectionHeader sectionHeading={'Покупки по категоріях'} />
			{error ? (
				<Alert message={error?.message} />
			) : (
				<Carousel
					breakpoints={type === "rounded" ? breakpoints : breakpointsCircle}
					buttonClassName="-mt-8 md:-mt-10"
				>
					{loading && !categories?.data
						? Array.from({ length: 10 }).map((_, idx) => {
								if (type === "rounded") {
									return (
										<SwiperSlide key={`card-rounded-${idx}`}>
											<CardRoundedLoader uniqueKey={`card-rounded-${idx}`} />
										</SwiperSlide>
									);
								}
								return (
									<SwiperSlide key={`card-circle-${idx}`}>
										<CardLoader uniqueKey={`card-circle-${idx}`} />
									</SwiperSlide>
								);
						  })
						: categories?.data?.map((category, i) => (
								<SwiperSlide key={`category--key-${category.id}${i}`}>
									<Card
										item={category}
										href={category.url}
										// href={`${'products'}/${category.slug}`}
										variant={type}
										effectActive={true}
										size={type === "rounded" ? "medium" : "small"}
									/>
								</SwiperSlide>
						  ))}
				</Carousel>
			)}
		</div>
	);
};

export default CategoryBlock;
