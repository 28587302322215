import { IoLogoInstagram } from "react-icons/io5";
import { IoLogoFacebook } from "react-icons/io5";
import { IoLogoTwitter } from "react-icons/io5";
import { IoLogoYoutube } from "react-icons/io5";
import { FaTelegram, FaViber } from "react-icons/fa";


export const FacebookIcon = () => <IoLogoFacebook/>;
export const InstagramIcon = () => <IoLogoInstagram/>;
export const TwitterIcon = () => <IoLogoTwitter/>;
export const YouTubeIcon = () => <IoLogoYoutube/>;
export const TelegramIcon = () => <FaTelegram/>;
export const ViberIcon = () => <FaViber/>;

export default {
  FacebookIcon,
  InstagramIcon,
  TelegramIcon,
  TwitterIcon,
  ViberIcon,
  YouTubeIcon
}
