import cn from "classnames";
import usePrice from "../../helpers";
import {siteSettings} from "../../Features/contexts/settings-context";
import {useNavigate} from "react-router-dom";
import {ROUTS} from "../../Features/data/routes";
import MyImage from "../ui/imageLoad";
import {FB} from "../../App";
// import Image from "next/image";
// import type { FC } from "react";
// import { useUI } from "@contexts/ui.context";
// import usePrice from "@lib/use-price";
// import { Product } from "@framework/types";
// import { siteSettings } from "@settings/site.settings";


const ProductCard = ({
  product,
  className = "",
  contactClassName = "",
  imageContentClassName = "",
  variant = "list",
  imgWidth = 300,
  imgHeight = 400,
  imgLoading,
}) => {
    const navigate = useNavigate();
  // const { openModal, setModalView, setModalData } = useUI();
  const { name, image, min_price, max_price, product_type,  variations = [] } =
    product ?? {};
  const description = "Чудові туфлі опис для картки"

  const currentVariation = variations?.[0]
  const { price, basePrice } = usePrice({
    amount: product?.price,
    // amount: product?.price || currentVariation?.price,
    baseAmount: product?.priceWithoutDiscount,
  });

  const { price: minPrice } = usePrice({
    amount: min_price,
  });

  const { price: maxPrice } = usePrice({
    amount: max_price,
  });

    // console.log(min_price, max_price, product?.price, product);

    function handlePopupView() {
        FB.openProductProfile(product.id)
      navigate(ROUTS.PRODUCT_PAGE({id: product.id}))
    // setModalData(product.slug);
    // setModalView("PRODUCT_VIEW");
    // return openModal();
  }

  return (
    <div
      className={cn(
        "group box-border overflow-hidden flex rounded-md cursor-pointer",
        {
          "pe-0 pb-2 lg:pb-3 flex-col items-start bg-white transition duration-200 ease-in-out transform hover:-translate-y-1 hover:md:-translate-y-1.5 hover:shadow-product":
            variant === "grid",
          "pe-0 md:pb-1 flex-col items-start bg-white": variant === "gridSlim",
          "items-center bg-transparent border border-gray-100 transition duration-200 ease-in-out transform hover:-translate-y-1 hover:shadow-listProduct":
            variant === "listSmall",
          "flex-row items-center transition-transform ease-linear bg-gray-200 pe-2 lg:pe-3 2xl:pe-4":
            variant === "list",
        },
        className
      )}
      onClick={handlePopupView}
      role="button"
      title={name}
    >
      <div
        className={cn(
          "flex",
          {
            "mb-3 md:mb-3.5": variant === "grid",
            "mb-3 md:mb-3.5 pb-0": variant === "gridSlim",
            "flex-shrink-0 w-32 sm:w-44 md:w-36 lg:w-44":
              variant === "listSmall",
          },
          imageContentClassName
        )}
      >
        <img
          src={image ?? siteSettings?.product?.placeholderImage()}
          width={imgWidth}
          height={imgHeight}
          loading={imgLoading}
          alt={name || "Product Image"}
          className={cn("bg-gray-300 object-cover rounded-s-md", {
            "w-full rounded-md transition duration-200 ease-in group-hover:rounded-b-none":
              variant === "grid",
            "rounded-md transition duration-150 ease-linear transform group-hover:scale-105":
              variant === "gridSlim",
            "rounded-s-md transition duration-200 ease-linear transform group-hover:scale-105":
              variant === "list",
          })}
        />
      </div>
      <div
        className={cn(
          "w-full overflow-hidden",
          {
            "ps-0 lg:ps-2.5 xl:ps-4 pe-2.5 xl:pe-4": variant === "grid",
            "ps-0": variant === "gridSlim",
            "px-4 lg:px-5 2xl:px-4": variant === "listSmall",
          },
          contactClassName
        )}
      >
        <h2
          className={cn("text-heading font-semibold truncate mb-1", {
            "text-sm md:text-base": variant === "grid",
            "md:mb-1.5 text-sm sm:text-base md:text-sm lg:text-base xl:text-lg":
              variant === "gridSlim",
            "text-sm sm:text-base md:mb-1.5 pb-0": variant === "listSmall",
            "text-sm sm:text-base md:text-sm lg:text-base xl:text-lg md:mb-1.5":
              variant === "list",
          })}
        >
          {name}
        </h2>
        {description && (
          <p className="text-body text-xs md:text-[13px] lg:text-sm leading-normal xl:leading-relaxed max-w-[250px] truncate">
            {description}
          </p>
        )}
          {/*<p className="text-body text-xs truncate">*/}
          {/*  Розміри: 37, 38*/}
          {/*</p>*/}
        <div
          className={`text-heading font-semibold text-sm sm:text-base mt-1.5 space-s-1 ${
            variant === "grid"
              ? "3xl:text-lg lg:mt-2.5"
              : "sm:text-lg md:text-base 3xl:text-xl md:mt-2.5 2xl:mt-3"
          }`}
        >
          {!product?.price && (minPrice !== maxPrice) ? (
            <>
              <span className="inline-block">{minPrice}</span>
              <span> - </span>
              <span className="inline-block">{maxPrice}</span>
            </>
          ) : (
            <>
              <span className="inline-block">{price || minPrice}</span>

              {basePrice && (
                <del className="sm:text-base font-normal text-gray-800 ps-1">
                  {basePrice}
                </del>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
