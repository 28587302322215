import {pick} from "ramda";
import {CATEGORIES, CATEGORIES_DATA} from "../../../crmData/filters-data";
import {ROUTS} from "../../data/routes";

export const footer = {
  widgets: [
    {
      id: 3,
      widgetTitle: "Інформація",
      lists: [
        {
          id: 1,
          title: "Доставка та оплата",
          path: ROUTS.DELIVERY_PAGE,
        },
        {
          id: 2,
          title: "Повернення чи обмін товару",
          path: ROUTS.RETURN_EXCHANGE_PAGE,
        },
        {
          id: 3,
          title: "Рекомендації з догляду",
          path: ROUTS.RECOMMENDATIONS_PAGE,
        },

      ],
    },
    // {
    //   id: 4,
    //   widgetTitle: "widget-title-customer-care",
    //   lists: [
    //     {
    //       id: 1,
    //       title: "link-faq",
    //       path: "/faq",
    //     },
    //     {
    //       id: 2,
    //       title: "link-shipping",
    //       path: "/",
    //     },
    //     {
    //       id: 3,
    //       title: "link-exchanges",
    //       path: "/",
    //     },
    //   ],
    // },
    {
      id: 5,
      widgetTitle: "Наша інформаці",
      lists: [
        {
          id: 4,
          title: "Про компанію",
          path: ROUTS.ABOUT_US_PAGE,
        },
        // {
        //   id: 2,
        //   title: "Договір публічної оферти",
        //   path: ROUTS.AGREEMENT_PAGE,
        // },
        {
          id: 3,
          title: "Політика конфіденційності",
          path: ROUTS.CONFIDENTIAL_PAGE,
        },

      ],
    },
    {
      id: 7,
      widgetTitle: 'Популярні категорії',
      lists: Object.values(pick([
        CATEGORIES.bags,
        CATEGORIES.acsesorials,
        CATEGORIES.shoes,
        CATEGORIES.sale,
      ], CATEGORIES_DATA)).map(el => ({ id: el.id, title: el.label, path: ROUTS.PRODUCTS_BY_CATEGORY([el.value])})),
    },
  ],
};
