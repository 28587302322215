import {Link} from "react-router-dom";
import {useSettings} from "../../Features/contexts/settings-context";
import {ROUTS} from "../../Features/data/routes";

const WidgetContact = () => {
  const settings = useSettings();

  const contactDetails = settings?.contactDetails;

  return (
    <div>
      <h4 className="text-heading text-sm md:text-base xl:text-lg font-semibold mb-5 2xl:mb-6 3xl:mb-7">
        Контакти
      </h4>
      <ul className="text-xs md:text-[13px] lg:text-sm text-body flex flex-col space-y-3 lg:space-y-3.5">
      <li className="flex items-baseline">
        <Link to={ROUTS.CONTACTS_PAGE()} className="transition-colors duration-200 hover:text-black">
            Сторінка з контактами
        </Link>
      </li>

        {contactDetails?.email && (
          <li className="flex items-baseline">
            Email: {contactDetails.email}
          </li>
        )}
        {contactDetails?.phone && (
          <li className="flex items-baseline">
            Teл: {contactDetails.phone}
          </li>
        )}
      </ul>
    </div>
  );
};

export default WidgetContact;
