import React, {useEffect, useMemo, useState} from "react";
// import Button from "@components/ui/button";
// import Counter from "@components/common/counter";
// import { getVariations } from "@framework/utils/get-variations";
// import { useCart } from "@store/quick-cart/cart.context";
// import usePrice from "@lib/use-price";
// import { generateCartItem } from "@utils/generate-cart-item";
// import { ProductAttributes } from "./product-attributes";
// import isEmpty from "lodash/isEmpty";
// import Link from "@components/ui/link";
// import Image from "next/image";
import { toast } from "react-toastify";
// import { useWindowSize } from "@utils/use-window-size";
// import Carousel from "@components/ui/carousel/carousel";
import { SwiperSlide } from "swiper/react";
// import { Attachment, Product } from "@framework/types";
import isEqual from "lodash/isEqual";
// import VariationPrice from "@components/product/product-variant-price";
// import { useTranslation } from "next-i18next";
import isMatch from "lodash/isMatch";
import usePrice, {makeId, useWindowSize} from "../../../helpers";
import {isEmpty} from "lodash";
import Carousel from "../../../Components/ui/carousel";
import Counter from "../../../Components/ui/counter";
import Button from "../../../Components/ui/button";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {setCardItems, setFastBuyItems} from "../../../redux/productsSlice";
import {ProductAttributes} from "./product-attributes";
import {useNavigate} from "react-router";
import {ROUTS} from "../../data/routes";
import {uniq} from "ramda";
import {ALL_FILTERS_DATA, CATEGORIES, COLOR_FILTERS_CRM_MAP} from "../../../crmData/filters-data";
import ProductImageSection from "./product-image";
import ProductDescribeSection from "./product-describe-section";
import {FB} from "../../../App";
// import { ROUTES } from "@lib/routes";



const generateSizeVariations = (variations = []) => {
  return variations.reduce((accum, el) => {
    if (!el.size) return accum
    return [...accum, { value: el.size, id: el.id }]

  }, [])
}

const ProductSingleDetails = ({ product }) => {

  const navigate = useNavigate()
  const [activeVariation, setActiveVariation] = useState({})
  useEffect(() => {
    const activeVariationIndex = (product?.variations || []).findIndex(el => Number(el.quantity) > 0)
    const activeVariation = product?.variations?.[
        (activeVariationIndex && activeVariationIndex > -1) ? activeVariationIndex : 0
        ] || {}
    setActiveVariation(activeVariation)
  }, [product])
  const [attributes, setAttributes] = useState({});
  const [quantity, setQuantity] = useState(1);
  const { cardItems } = useSelector((state) => state.products)
  const dispatch = useDispatch()
  // const [addToCartLoader, setAddToCartLoader] = useState<boolean>(false);

  const { price, basePrice } = usePrice({
    amount: activeVariation?.price,
    baseAmount: product?.priceWithoutDiscount,
  });

  // const variations = getVariations(product?.variations!);
  const variations = product?.variations || [];
  const sizeVariations = useMemo(() => {
    return generateSizeVariations(variations)
  }, [variations])
  // console.log(activeVariation, 'lpplplplplpl');
  // const isSelected = !isEmpty(variations)
  //   ? !isEmpty(attributes) &&
  //     Object.keys(variations).every((variation) =>
  //       attributes.hasOwnProperty(variation)
  //     )
  //   : true;
  //
  // let selectedVariation = {};
  // if (isSelected) {
  //   selectedVariation = product?.variation_options?.find((o) =>
  //     isEqual(
  //       o.options.map((v) => v.value).sort(),
  //       Object.values(attributes).sort()
  //     )
  //   );
  // }

  function addToCart(isFastBuy) {
    const { sku, price, quantity: maxQuantity } = activeVariation;
    const cardItemData = {
      quantity,
      maxQuantity,
      sku,
      productId: product.id,
      image: product.image,
      unitPrice: price || product.price,
      id: makeId(),
      name: product.name,
    }
    if (isFastBuy) {
      FB.makeFastBuy(product.id)
      dispatch(setFastBuyItems([cardItemData]))
      navigate(ROUTS.NEW_ORDER_PAGE({buyType: 'buyFast'}))
    } else  {
     FB.addToCard(product.id)
    const updatedCard = [...cardItems, cardItemData]
    dispatch(setCardItems(updatedCard, {}))
    toast('Товар доданий в кошик', {
      type: "dark",
      progressClassName: "fancy-progress-bar",
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
    }
  }

  const productSouldOut = activeVariation?.is_disable || activeVariation?.quantity === 0
  const productAddedToCard = useMemo(() => {
    let productAdded = false
    cardItems.forEach(el => {
      if (el.sku === activeVariation?.sku) productAdded = true
    })
    return productAdded
  }, [activeVariation, cardItems])

  function handleAttribute(id) {
    setQuantity(1)
    const selectedVariationIndex = variations.findIndex((el) => el.id === id)
    setActiveVariation(variations[selectedVariationIndex])
  }

  // Combine image and gallery
  const combineImages = product?.images || [product?.image];

  return (
    <div className="block lg:grid grid-cols-9 gap-x-10 xl:gap-x-14 pt-7 pb-10 lg:pb-14 2xl:pb-20 items-start">
      <ProductImageSection name={product?.name} combineImages={combineImages}/>


      <div className="col-span-4 pt-8 lg:pt-0">
        <div className="pb-7 border-b border-gray-300">
          <h2 className="text-heading text-lg md:text-xl lg:text-2xl 2xl:text-3xl font-bold hover:text-black mb-3.5">
            {product?.name}
          </h2>
          <p className="text-body text-sm lg:text-base leading-6 lg:leading-8">
            {product?.description}
            {'Колекція \n осінь 2023\n' +
            'Сезон\n' +
            'демісезон\n' +
            'Вид взуття\n' +
            'челсі\n' +
            'Колір\n' +
            'коричневий\n' +
            'Кольорова гама\n' +
            'коричнева\n' +
            'Матеріал верху\n' +
            '100% натуральна шкіра\n' +
            'Вид підборів\n' +
            'низький хід\n' +
            'Підбори\n' +
            'до 5 см\n' +
            'Виробництво\n' +
            'Україна'}
          </p>
          <div className="flex items-center mt-5">
              <>
                <div className="text-heading font-semibold text-base md:text-xl lg:text-2xl">
                  {`Ціна: ${price}`}
                </div>

                {basePrice && (
                  <del className="font-segoe text-gray-400 text-base lg:text-xl ps-2.5 -mt-0.5 md:mt-0">
                    {basePrice}
                  </del>
                )}
              </>
          </div>
        </div>
        {(sizeVariations && !isEmpty(sizeVariations)) && (
          <div className="pt-7 pb-3 border-b border-gray-300">
            <ProductAttributes
                title={'Розмір'}
                attributes={sizeVariations}
                active={activeVariation?.size}
                onClick={handleAttribute}
            />
          </div>
        )}

        <div className="flex flex-col border-b border-gray-300">

          <div className="flex items-center space-s-4   py-8">
            {!isEmpty(activeVariation) && (
                <>
                  { productSouldOut ? (
                      <div className="text-base text-red-500 whitespace-nowrap lg:ms-7">
                        Товар розпроданий
                      </div>
                  ) : (
                      <Counter
                          quantity={quantity}
                          onIncrement={() => setQuantity((prev) => prev + 1)}
                          onDecrement={() =>
                              setQuantity((prev) => (prev !== 1 ? prev - 1 : 1))
                          }
                          disableDecrement={quantity === 1}
                          disableIncrement={
                            quantity >= Number(activeVariation?.quantity)
                          }
                      />
                  )}
                </>
            )}
            <Button
                onClick={() => {

                  addToCart()
                }}
                variant="slim"
                className={`w-full md:w-6/12 xl:w-full ${
                    "bg-gray-400 hover:bg-gray-400"
                }`}
                disabled={
                  productAddedToCard || productSouldOut
                }
                loading={false}
            >
            <span className="py-2 3xl:px-8">
              {
                productAddedToCard
                    ? 'Товар в корзині'
                    : productSouldOut
                      ? 'Товар розпроданий'
                      : 'Додати в кошик'
              }
            </span>
            </Button>
          </div>
          <div className="pb-8">
            <Button
                onClick={() =>  {
                  addToCart(true)
                }}
                variant="slim"
                className={`w-full md:w-6/12 xl:w-full ${
                    "bg-gray-400 hover:bg-gray-400"
                }`}
                disabled={productSouldOut}
                // loading={addToCartLoader}
                loading={false}
            >
            <span className="py-2 3xl:px-8">
              Купити в один клік
            </span>
            </Button>
          </div>
        </div>
        <div className="py-6">
          <ProductDescribeSection sku={activeVariation?.sku} product={product}/>
        </div>
      </div>
    </div>
  );
};

export default ProductSingleDetails;
