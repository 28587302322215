import {indexBy, prop} from "ramda";
const SORTING_OPTIONS = {
    default: 'default',
    priceASC: 'price|ASC',
    priceDesc: 'price|DESC',
}
const sortOptions = [
    { name: "Сортуваня", value: SORTING_OPTIONS.default },
    { name: "Найдешевші ", value: SORTING_OPTIONS.priceASC },
    { name: "Найдорожчі", value: SORTING_OPTIONS.priceDesc },
]

const SORTING_OPTIONS_MAP = indexBy(prop('value'), sortOptions)


export {
    sortOptions,
    SORTING_OPTIONS,
    SORTING_OPTIONS_MAP
}
