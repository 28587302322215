import {CATEGORIES} from "./filters-data";
import {CRM_PRODUCTS} from "./products";
import {convertArrayToObg} from "../helpers";


const productsDataMap = convertArrayToObg(CRM_PRODUCTS,'id')
const productsData = {
    data: CRM_PRODUCTS,
    dataDefault: [{
        id: 1,
        quantity: 2,
        name: 'жіноче взуття',
        image: 'https://firebasestorage.googleapis.com/v0/b/lunameshoes.appspot.com/o/2023-09-28%2014.36.34.jpg?alt=media&token=6bbea543-fc6c-4e4a-b0d9-1299377d3dc2&_gl=1*1vg91nm*_ga*NDgxMDY2NDQ2LjE2MTE4MzYyNjM.*_ga_CW55HF8NVT*MTY5NTkxMjIwMi41LjAuMTY5NTkxMjIwMi42MC4wLjA.',
        price: 7,
        min_price: 5,
        max_price: 10,
        product_type: 'dmemkdme',
        description: 'some test description',
        categories: [CATEGORIES.shoes],

    },
    {
        id: 2,
        name: 'Жіноча сумка',
        image: 'https://firebasestorage.googleapis.com/v0/b/lunameshoes.appspot.com/o/2023-09-28%2014.36.34.jpg?alt=media&token=6bbea543-fc6c-4e4a-b0d9-1299377d3dc2&_gl=1*1vg91nm*_ga*NDgxMDY2NDQ2LjE2MTE4MzYyNjM.*_ga_CW55HF8NVT*MTY5NTkxMjIwMi41LjAuMTY5NTkxMjIwMi42MC4wLjA.',
        price: 7,
        min_price: 5,
        max_price: 10,
        product_type: 'dmemkdme',
        description: 'some test description',
        categories: [CATEGORIES.bags],
    },
        {
        id: 3,
            name: 'Жіночий кошильок',
            image: 'https://firebasestorage.googleapis.com/v0/b/lunameshoes.appspot.com/o/2023-09-28%2014.36.34.jpg?alt=media&token=6bbea543-fc6c-4e4a-b0d9-1299377d3dc2&_gl=1*1vg91nm*_ga*NDgxMDY2NDQ2LjE2MTE4MzYyNjM.*_ga_CW55HF8NVT*MTY5NTkxMjIwMi41LjAuMTY5NTkxMjIwMi42MC4wLjA.',
            price: 7,
            min_price: 5,
            max_price: 10,
            product_type: 'dmemkdme',
            description: 'some test description',
            categories: [CATEGORIES.wallets],
        },
        {
        id: 4,
            name: 'сумка і кошильок',
            image: 'https://firebasestorage.googleapis.com/v0/b/lunameshoes.appspot.com/o/2023-09-28%2014.36.34.jpg?alt=media&token=6bbea543-fc6c-4e4a-b0d9-1299377d3dc2&_gl=1*1vg91nm*_ga*NDgxMDY2NDQ2LjE2MTE4MzYyNjM.*_ga_CW55HF8NVT*MTY5NTkxMjIwMi41LjAuMTY5NTkxMjIwMi42MC4wLjA.',
            price: 7,
            min_price: 5,
            max_price: 10,
            product_type: 'dmemkdme',
            description: 'some test description',
            categories: [],
        },
        {
        id: 5,
            name: 'black Shoes from luneMe',
            image: 'https://firebasestorage.googleapis.com/v0/b/lunameshoes.appspot.com/o/2023-09-28%2014.36.34.jpg?alt=media&token=6bbea543-fc6c-4e4a-b0d9-1299377d3dc2&_gl=1*1vg91nm*_ga*NDgxMDY2NDQ2LjE2MTE4MzYyNjM.*_ga_CW55HF8NVT*MTY5NTkxMjIwMi41LjAuMTY5NTkxMjIwMi42MC4wLjA.',
            price: 7,
            min_price: 5,
            max_price: 10,
            product_type: 'dmemkdme',
            description: 'some test description'
        },
        {
        id: 6,
            name: 'black Shoes from luneMe',
            image: 'https://firebasestorage.googleapis.com/v0/b/lunameshoes.appspot.com/o/2023-09-28%2014.36.34.jpg?alt=media&token=6bbea543-fc6c-4e4a-b0d9-1299377d3dc2&_gl=1*1vg91nm*_ga*NDgxMDY2NDQ2LjE2MTE4MzYyNjM.*_ga_CW55HF8NVT*MTY5NTkxMjIwMi41LjAuMTY5NTkxMjIwMi42MC4wLjA.',
            price: 7,
            min_price: 5,
            max_price: 10,
            product_type: 'dmemkdme',
            description: 'some test description'
        },
        {
        id: 7,
            name: 'black Shoes from luneMe',
            image: 'https://firebasestorage.googleapis.com/v0/b/lunameshoes.appspot.com/o/2023-09-28%2014.36.34.jpg?alt=media&token=6bbea543-fc6c-4e4a-b0d9-1299377d3dc2&_gl=1*1vg91nm*_ga*NDgxMDY2NDQ2LjE2MTE4MzYyNjM.*_ga_CW55HF8NVT*MTY5NTkxMjIwMi41LjAuMTY5NTkxMjIwMi42MC4wLjA.',
            price: 7,
            min_price: 5,
            max_price: 10,
            product_type: 'dmemkdme',
            description: 'some test description'
        },
        {
        id: 8,
            name: 'black Shoes from luneMe',
            image: 'https://firebasestorage.googleapis.com/v0/b/lunameshoes.appspot.com/o/2023-09-28%2014.36.34.jpg?alt=media&token=6bbea543-fc6c-4e4a-b0d9-1299377d3dc2&_gl=1*1vg91nm*_ga*NDgxMDY2NDQ2LjE2MTE4MzYyNjM.*_ga_CW55HF8NVT*MTY5NTkxMjIwMi41LjAuMTY5NTkxMjIwMi42MC4wLjA.',
            price: 7,
            min_price: 5,
            max_price: 10,
            product_type: 'dmemkdme',
            description: 'some test description'
        },

    ]
}


export {
    productsData,
    productsDataMap
}
