import React, { FC, useEffect, useRef } from "react";
import {motion, AnimatePresence, useAnimate, usePresence} from "framer-motion";
import cn from "classnames";
import { createPortal } from "react-dom";
import Drawer from "react-modern-drawer";
import {useWindowSize} from "../helpers";
// export function fadeInRight (duration = 0.3) {
//     return {
//         from: {
//             right: '-100%',
//             transition: {
//                 type: 'easeInOut',
//                 duration: duration,
//             }
//         },
//         to: {
//             right: 0,
//             transition: {
//                 type: 'easeInOut',
//                 duration: duration,
//             }
//         },
//     }
// }
// export function fadeInLeft (duration = 0.3) {
//     return {
//         from: {
//             left: '-100%',
//             transition: {
//                 type: 'easeInOut',
//                 duration: duration,
//             }
//         },
//         to: {
//             left: 0,
//             transition: {
//                 type: 'easeInOut',
//                 duration: duration,
//             }
//         },
//     }
// }
// export function fadeInOut (duration = 0.2) {
//     return {
//         from: {
//             opacity: 0,
//             transition: {
//                 type: 'easeInOut',
//                 duration: duration,
//             }
//         },
//         to: {
//             opacity: 1,
//             transition: {
//                 type: 'easeInOut',
//                 duration: duration,
//             }
//         },
//     }
// }
// const Portal = ({children}) => {
//     const mount = document.getElementById("portal-root");
//     const el = document.createElement("div");
//
//     useEffect(() => {
//         mount.appendChild(el);
//         return () => mount.removeChild(el);
//     }, [el, mount]);
//
//     return createPortal(children, el)
// };
// // const TestComponent = ({ variant, children, onClose }) => {
// //     const [isPresent, safeToRemove] = usePresence()
// //     const [scope, animate] = useAnimate()
// //     useEffect(() => {
// //
// //         if (isPresent) {
// //             const enterAnimation = async () => {
// //                 await animate(scope.current, { opacity: 1 })
// //                 // await animate("li", { opacity: 1, x: 0 })
// //             }
// //             enterAnimation()
// //
// //         } else {
// //             const exitAnimation = async () => {
// //                 console.log(isPresent, 'OKODKMEKMDMEKM');
// //                 // await animate("li", { opacity: 0, x: -100 })
// //                 await animate(scope.current, { background: 0 })
// //                 safeToRemove()
// //             }
// //
// //             exitAnimation()
// //         }
// //     }, [isPresent])
// //
// //     return (
// //         <motion.div
// //             ref={scope}
// //             key="drawer"
// //             // initial={variant === "right" ? {  background: 'green', msTransitionDuration: 3000 } : {}}
// //             // animate={variant === "right" ? {  background: 'red', msTransitionDuration: 3000 } : {}}
// //             exit={{ background: 'green', }}
// //             // // initial="from"
// //             // // animate="to"
// //             // // exit="from"
// //             // // variants={variant === "right" ? fadeInRight() : fadeInLeft()}
// //             // transition={{ delay: 3 }}
// //             className="fixed inset-0 overflow-hidden h-full z-50"
// //         >
// //             <div className="absolute inset-0 overflow-hidden">
// //                 <div
// //                     // initial="from"
// //                     // animate="to"
// //                     // exit="from"
// //                     // variants={fadeInOut(0.35)}
// //                     onClick={onClose}
// //                     className={cn(
// //                         "absolute inset-0 bg-dark bg-opacity-40",
// //                         // useBlurBackdrop && "use-blur-backdrop"
// //                     )}
// //                     // style={{ backgroundColor: 'red'}}
// //                     style={{ backgroundColor: 'rgba(0,0,0,.5)'}}
// //                 />
// //                 <div
// //
// //                     className={cn(
// //                         "absolute inset-y-0 max-w-full flex outline-none opacity-0",
// //                         // variant === "right" ? "end-0" : "start-0"
// //                     )}
// //                     style={variant === "right" ? { right: '-100%'} : { left: '-100%'}}
// //                 >
// //                     {/*<div className="h-full w-screen max-w-md">*/}
// //                     {/*    <div className="h-full flex flex-col text-body bg-light shadow-xl">*/}
// //                     {children}
// //                     {/*    </div>*/}
// //                     {/*</div>*/}
// //                 </div>
// //             </div>
// //         </motion.div>
// //     )
// // }
// const DrawerCustom = ({
//                                       children,
//                                       open = false,
//                                       variant = "right",
//                                       useBlurBackdrop = true,
//                                       onClose,
//                                   }) => {
//     const ref = useRef();
//     const [scope, animate] = useAnimate()
//     useEffect(() => {
//         if (open) {
//         document.body.style.overflow = 'hidden';
//         } else {
//             document.body.style.overflow = 'unset';
//         }
//     }, [open])
//     useEffect(() => {
//         // This "li" selector will only select children
//         // of the element that receives `scope`.
//     console.log(open, 'dewdwedwedsaxoaxsak');
//         if (scope.current) {
//
//         if (open) {
//          animate(scope.current, { ...variant === "right" ? {right: 0} : { left: 0 }, opacity: 1 }, {duration: 0.3})
//         } else {
//          // animate(scope.current, { opacity: 0 }, {duration: 3})
//
//         }
//         }
//     }, [open])
//     return (
//         <Portal>
//             <AnimatePresence>
//                 {open && (
//                     // <TestComponent variant={variant} onClose={onClose}>
//                     //     {children}
//                     // </TestComponent>
//                     <div
//                         key="drawer"
//                         // initial={variant === "right" ? {  background: 'green', msTransitionDuration: 3000 } : {}}
//                         // animate={variant === "right" ? {  background: 'red', msTransitionDuration: 3000 } : {}}
//                         // exit={{ background: 'green', }}
//                         // // initial="from"
//                         // // animate="to"
//                         // // exit="from"
//                         // // variants={variant === "right" ? fadeInRight() : fadeInLeft()}
//                         // transition={{ delay: 3 }}
//                         className="fixed inset-0 overflow-hidden h-full z-50"
//                     >
//                         <div className="absolute inset-0 overflow-hidden">
//                             <div
//                                 // initial="from"
//                                 // animate="to"
//                                 // exit="from"
//                                 // variants={fadeInOut(0.35)}
//                                 onClick={onClose}
//                                 className={cn(
//                                     "absolute inset-0 bg-dark bg-opacity-40",
//                                     useBlurBackdrop && "use-blur-backdrop"
//                                 )}
//                                 // style={{ backgroundColor: 'red'}}
//                                 style={{ backgroundColor: 'rgba(0,0,0,.5)'}}
//                             />
//                             <div
//                                 ref={scope}
//                                 className={cn(
//                                     "absolute inset-y-0 max-w-full flex outline-none opacity-0",
//                                     // variant === "right" ? "end-0" : "start-0"
//                                 )}
//                                 style={variant === "right" ? { right: '-100%'} : { left: '-100%'}}
//                             >
//                                 {/*<div className="h-full w-screen max-w-md">*/}
//                                 {/*    <div className="h-full flex flex-col text-body bg-light shadow-xl">*/}
//                                         {children}
//                                 {/*    </div>*/}
//                                 {/*</div>*/}
//                             </div>
//                         </div>
//                     </div>
//                 )}
//             </AnimatePresence>
//         </Portal>
//     );
// };
const DrawerCustom = ({ open, onClose, children, variant = 'left'}) => {
    const { width, height } = useWindowSize();
    // useEffect(() => {
    //     if (open) {
    //         document.body.style.overflow = 'hidden';
    //     } else {
    //         document.body.style.overflow = 'unset';
    //     }
    // }, [open])

    return (
        <Drawer
            lockBackgroundScroll
            open={open}
            onClose={onClose}
            direction={variant}
            overlayClassName={'overlay-drawer-custom'}
            // className="fixed inset-0 overflow-hidden h-full z-50"
            style={{
                width: '95%',
                // background: 'red',
                maxWidth: '450px',
                height: `${height}px`
            }}

        >
            {children}
        </Drawer>
    )
}
export default DrawerCustom;
