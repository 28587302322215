import PageHeader from "../../../Components/page-header";
import Divider from "../../../Components/ui/divider";
import Container from "../../../Components/ui/container";

const text = ''
const AgreementPage = () => {
    const textArray = text.split('##')
    return (
        <>
            <PageHeader pageHeader="Договір публічної оферти" pageSubHeader="LunaMe"/>
            <Divider className="mb-0" />

            <Container>
                <div className={'py-10'}>
                    {
                        textArray.map((el, i) => (
                            <p>{el}</p>
                        ))
                    }
                </div>
            </Container>
        </>
    )
}
export default AgreementPage
