// import ProductCard from "@components/product/product-card";
// import Button from "@components/ui/button";
// import type { FC } from "react";
// import { PaginatedProduct } from "@framework/products/products.query";
// import ProductFeedLoader from "@components/ui/loaders/product-feed-loader";
// import { useTranslation } from "next-i18next";
// import { Product } from "@framework/types";
import isEmpty from "lodash/isEmpty";
import ProductCard from "./product-card";
import NotFound from "../404/not-found";
import ProductFeedLoader from "../loaders/product-feed-loader";
import Button from "../ui/button";
import {fadeInOut} from "../../helpers";
import EmptyCart from "../../Features/Cart/empty-cart";
import {motion} from "framer-motion";
import React from "react";
// import NotFound from "@components/404/not-found";


export const ProductInfiniteGrid = ({
  className = "",
  loading,
  data,
  hasNextPage,
  loadingMore,
  fetchNextPage,
}) => {
  // const { t } = useTranslation();
    const t = (st) => st

  // If no product found
  if (!loading && isEmpty(data?.[0])) {
    return (
        <motion.div
            layout
            initial="from"
            animate="to"
            exit="from"
            variants={fadeInOut(0.25)}
            className="px-5 md:px-7 pt-8 pb-5 flex justify-center flex-col items-center"
        >
            <EmptyCart width={240} height={450}/>
            <h3 className="text-lg text-heading font-bold pt-8">
                Товари з такими фільтрами відсутні
            </h3>
        </motion.div>
    )
    // return <NotFound text={t("text-no-products-found")} />;
  }

  return (
    <>
      <div
        className={`grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-x-3 lg:gap-x-5 xl:gap-x-7 gap-y-3 xl:gap-y-5 2xl:gap-y-8 ${className}`}
      >
        {loading && !data?.length ? (
          <ProductFeedLoader limit={20} uniqueKey="search-product" />
        ) : (
            data?.map((product) => (
                <ProductCard
                    key={`product--key${product.id}`}
                    product={product}
                    variant="grid"
                />
            ))
        )}
      </div>
      <div className="text-center pt-8 xl:pt-14">
        {hasNextPage && (
          <Button
            loading={loadingMore}
            disabled={loadingMore}
            onClick={() => fetchNextPage()}
            variant="slim"
          >
              Завантажити ще товарів
          </Button>
        )}
      </div>
    </>
  );
};

export default ProductInfiniteGrid;
