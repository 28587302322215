import PageHeader from "../../../Components/page-header";
import Divider from "../../../Components/ui/divider";
import Container from "../../../Components/ui/container";
import {useSettings} from "../../contexts/settings-context";

const text = ({ companyName, schedule, phone }) =>  'Гарантійні зобов\'язання##' +
    `Компанія ${companyName}  гарантує відповідність законодавству та закону України "Про захист прав споживачів". Відповідно до цих нормативних документів, ми надаємо наступні гарантійні послуги:##` +
    '1. Покупець може подавати претензії щодо якості взуття, якщо упаковка збережена, протягом гарантійного строку. Претензії подаються у письмовій формі та повинні містити опис конкретних дефектів.##' +
    '2. Незначні дефекти взуття, які можуть бути виправлені без втрати споживчих властивостей (наприклад, розклеювання декоративного ранта, розрив нитки на пришивній підошві, відрив фурнітури, відставання набійки, злам замків-блискавок, відклеювання підошви та інші), не є підставою для повернення коштів або обміну товару. Такі дефекти можуть бути усунуті через ремонт.##' +
    '3. Гарантія не поширюється на: механічні або хімічні пошкодження товару; пошкодження дрібних деталей (як устілки, шнурівки, набійки, змінні деталі, фурнітуру); пошкодження, що виникли при спробі ремонту, виконаному особами, іншими за виробника (продавця); на товар з очевидними дефектами або з уціненою якістю, про які було вказано під час покупки; на товар, який пошкоджений через неправильне носіння, сушіння, вплив хімічних речовин, плями, що виникли під час носіння в вологу погоду, або інші дефекти, за виною покупця.##' +

    `Протягом гарантійного терміну, ми проводимо безкоштовний ремонт взуття або розглядаємо можливість його обміну або повернення, якщо пошкодження відповідає гарантійним умовам.##` +
    'Терміни гарантії для взуття складають 30 днів.##' +
    'Ремені поясні - 30 днів з моменту продажу.##' +
    'Жіночі, дорожні і спортивні сумки (шкіряні) - 50 днів з моменту продажу.##' +
    'Сумки із штучної шкіри - 30 днів з моменту продажу.##' +
    `При придбанні продуктів компанії ${companyName} ви автоматично погоджуєтеся з умовами гарантії та правилами обміну і повернення.##` +
    'Рекомендації щодо догляду за взуттям:##' +
    '1. Догляд за взуттям починайте одразу після приходу додому та зняття взуття. Спочатку очистіть зовнішню поверхню взуття від бруду за допомогою вологої губки або ганчірки, а потім дайте взуттю підсохнути.##' +
    '2. Сушіть взуття при кімнатній температурі та подалі від джерел нагрівання, таких як батареї або калорифери. Для збереження форми взуття під час сушіння використовуйте дерев\'яні колодки або зім\'ятий папір, щоб підтримати контури взуття.##' +
    '3. Після повного висихання взуття додатково очистіть його спеціальною піною, що видалить залишки бруду. Потім нанесіть спеціальний крем (безкольоровий або тонуючий) або спрей, які наситять шкіру та інші матеріали, щоб запобігти їх пошкодженню. Завершіть догляд, застосувавши водовідштовхувальний чи інший захисний спрей.##' +
    'Тепер ваша пара взуття бездоганно доглянута!##' +
    'Використовуйте лише засоби, призначені для лакованої шкіри, набуку та замші, щоб зберегти зовнішній вигляд вашого взуття. В іншому випадку, воно може втратити свій естетичний вигляд.\n##' +
    'Для підтримання приємного мікроклімату всередині взуття систематично користуйтесь дезодорантом для взуття.##' +
    'Щоб забезпечити комфортний мікроклімат всередині взуття, регулярно використовуйте дезодорант для взуття.##' +
    'Для зберігання взуття, яке не використовується в позаробочий сезон, розкладіть його в коробки за розміром і покладіть кожну пару в окремі м\'які мішечки. Не рекомендується використовувати поліетиленові пакети, оскільки це не екологічно та може бути шкідливим для взуття.##' +
    'Поради щодо вибору взуття##' +
    'При виборі взуття слід дуже уважно підходити до визначення правильного розміру та ширини. Лише відбираючи відповідний розмір, ви зможете насолоджуватися комфортом при носінні взуття та уникати травм ніг. Мікро- та макротравми можуть виникнути як при використанні взуття, яке занадто велике або занадто мале.##' +
    'Якщо розмір взуття не відповідає вашим ногам, це може спричинити деформацію, розриви швів, тріщини, а також ламку підборів та інші дефекти на взутті. Важливо враховувати, що гарантія не розповсюджується на такі дефекти через те, що правильний вибір розміру взуття лежить на плечах покупця.##' +
    'Взуття на шкіряній підошві, лаковане, модельне і прошите не рекомендується носити в дощову, снігову погоду, а також в місцях з ґрунтовим, кам\'янистим покриттям.##' +
    'Взуття має відповідати сезону. Не можна носити лаковане взуття при температурі нижче -5 ℃ (зимові види взуття нижче -15 ℃) та вище +25 ℃.##' +
    'Взуття з обтягнутими підборами не рекомендується носити по гравію, камінні та в сиру погоду.##' +
    'Обтягнені підбори необхідно обробляти засобами для догляду за взуттям та своєчасно міняти набійки.##' +
    'Бережіть взуття від надмірних механічних пошкоджень: ударів, порізів тощо. Бо зазвичай це призводить до псування матеріалу верху, псування фурнітури, підошви, ламанню каблуків.##' +
    'При одяганні та зніманні взуття користуйтеся взуттєвою ложкою, уникаючи тягнення за ремені, гумку чи декоративну фурнітуру. Не відділяйте взуття, ступаючи на задник.##' +
    'Для взуття зі шкіряною підошвою, особливо під час активного використання, рекомендується регулярно наносити профілактичний захист.##' +
    'Щоб запобігти фарбуванню устілок і підкладки, які виготовлені з натуральної шкіри, слід користуватися засобами для догляду за взуттям.\n##' +
    'Нагадуємо, що прання будь-якого виду взуття не рекомендується!'

const RecommendationsPage = () => {
    const setting = useSettings()
    const textArray = text(
        { schedule: setting.schedule, companyName: setting?.siteTitle, phone: setting?.contactDetails?.phone }
    ).split('##')

    return (
        <>
            <PageHeader pageHeader="Рекомендації з догляду" pageSubHeader="LunaMe"/>
            <Divider className="mb-0" />

            <Container>
                <div className={'py-10'}>
                    {
                        textArray.map((el, i) => (
                            <p className={'mb-4'} key={i}>{el}</p>
                        ))
                    }
                </div>
            </Container>
        </>
    )
}
export default RecommendationsPage
