import React, {useEffect, useMemo} from 'react'
import usePrice, {fadeInOut, getCardItemsTotalPrice, makeId} from "../../helpers";
import { motion } from "framer-motion";
import { IoClose } from "react-icons/io5";
import cn from "classnames";
import {Link} from "react-router-dom";
import CartItem from "./card-item";
import EmptyCart from "./empty-cart";
import Scrollbar from "../../Components/scrollbar";
import Drawer from "react-modern-drawer";
import {useDispatch, useSelector} from "react-redux";
import {closeCartModal} from "../../redux/uiSlice";
// import component 👇
// import Drawer from 'react-modern-drawer'

//import styles 👇
import 'react-modern-drawer/dist/index.css'
import {isEmpty} from "lodash";
import {setCardItems, setFastBuyItems} from "../../redux/productsSlice";
import {ROUTS} from "../data/routes";
import {useNavigate} from "react-router";
import DrawerWrapper from "../../Components/drawer-wrapper";
import DrawerCustom from "../../Components/drawer";

function Cart() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { cardItems: items } = useSelector((state) => state.products)
    const total = useMemo(() => {
        return getCardItemsTotalPrice(items)
    }, [items])

    const { price: cartTotal } = usePrice({
        amount: total,
    });
    const addItemToCart = (id) => {
        const updatedCard = items.map((item) => {
            if (item.id === id) return { ...item, quantity: item.quantity + 1 }
            return item
        })
        dispatch(setCardItems(updatedCard))
    }
    const removeItemFromCart = (id) => {
        const updatedCard = items.reduce(( accum, item) => {
            if (item.id === id) {
                const updatedQuantity = item.quantity - 1
                return updatedQuantity > 0 ? [...accum, { ...item, quantity: updatedQuantity }] : accum
            }
            return [...accum, item]
        }, [])
        dispatch(setCardItems(updatedCard))
    }
    const closeCart = () => dispatch(closeCartModal())

    const handleMakeOrder = () => {
        if (!isEmpty(items)) {

            dispatch(closeCartModal())
        navigate(ROUTS.NEW_ORDER_PAGE({}))
        }
    }

    const handleSelectItem = ({ productId }) => {
        console.log('okdokewkdokewko', productId)
        navigate(ROUTS.PRODUCT_PAGE({id: productId}))
        closeCart()
    }

    return (
        <div className="flex flex-col w-full h-full justify-between">
            <div className="w-full flex justify-between items-center relative ps-5 md:ps-7 py-0.5 border-b border-gray-100">
                <h2 className="font-bold text-xl md:text-2xl m-0 text-heading">
                    Кошик товарів
                </h2>
                <button
                    className="flex text-2xl items-center justify-center text-gray-500 px-4 md:px-6 py-6 lg:py-8 focus:outline-none transition-opacity hover:opacity-60"
                    onClick={closeCart}
                    aria-label="close"
                >
                    <IoClose className="text-black mt-1 md:mt-0.5" />
                </button>
            </div>
            {!isEmpty(items) ? (
                <Scrollbar className="cart-scrollbar w-full  h-full ">
                    <div className="w-full h-full px-5 md:px-7" >
                        {items?.map((item, key) => (
                            <CartItem item={item}
                                      handleSelectItem={() => handleSelectItem(item)}
                                      key={key} addItemToCart={addItemToCart}
                                      removeItemFromCart={removeItemFromCart}/>
                        ))}
                    </div>
                </Scrollbar>
            ) : (
                <motion.div
                    layout
                    initial="from"
                    animate="to"
                    exit="from"
                    variants={fadeInOut(0.25)}
                    className="px-5 md:px-7 pt-8 pb-5 flex justify-center flex-col items-center"
                >
                    <EmptyCart />
                    <h3 className="text-lg text-heading font-bold pt-8">
                        Кошик порожній
                    </h3>
                </motion.div>
            )}

            <div
                className="flex flex-col px-5 md:px-7 pt-2 pb-5 md:pb-7"
            >
                <button
                    // to={isEmpty(items) ? '#' : ROUTS.NEW_ORDER_PAGE({})}
                    onClick={handleMakeOrder}
                    className={cn(
                        "w-full px-5 py-3 md:py-4 flex items-center justify-center bg-heading rounded-md text-sm sm:text-base text-white focus:outline-none transition duration-300 hover:bg-gray-600",
                        {
                            "cursor-not-allowed bg-gray-400 hover:bg-gray-400": isEmpty(items),
                        }
                    )}
                >
					<span className="w-full pe-5 -mt-0.5 py-0.5">
						Купити
					</span>
                    <span className="ms-auto flex-shrink-0 -mt-0.5 py-0.5">
						<span className="border-s border-white pe-5 py-0.5" />
                        {cartTotal}
					</span>
                </button>
            </div>
        </div>
    );
}

const CartModal = () => {
    const dispatch = useDispatch()
    const { showCartModal } = useSelector((state) => state.ui)
    // const { cardItems, fastBuyItems } = useSelector((state) => state.products)
    // useEffect(() => {
    //     const cardData = localStorage.getItem('cardDataShopL');
    //     const fastBuyItems = localStorage.getItem('fastBuyItems');
    //     const parsedData = JSON.parse(cardData)
    //     const parsedFastItems = JSON.parse(fastBuyItems)
    //     if (parsedData && !isEmpty(parsedData)) {
    //         dispatch(setCardItems(parsedData))
    //     }
    //     if (parsedFastItems && !isEmpty(parsedFastItems)) {
    //         dispatch(setFastBuyItems(parsedFastItems))
    //     }
    //
    // }, [])
    // useEffect(() => {
    //     if (cardItems) {
    //        localStorage.setItem('cardDataShopL', JSON.stringify(cardItems));
    //     }
    // }, [cardItems])
    //
    // useEffect(() => {
    //     if (fastBuyItems) {
    //        localStorage.setItem('fastBuyItems', JSON.stringify(fastBuyItems));
    //     }
    // }, [fastBuyItems])

    return (
        <DrawerCustom open={showCartModal} onClose={() => dispatch(closeCartModal())} variant="right">
            {/*<DrawerWrapper onClose={() => dispatch(closeCartModal())}>*/}
                {/*<div className="flex flex-col space-y-6 p-5">*/}

                {/*</div>*/}
            <Cart/>
            {/*</DrawerWrapper>*/}
        </DrawerCustom>
        // <Drawer
        //     lockBackgroundScroll
        //     open={showCartModal}
        //     onClose={() => dispatch(closeCartModal())}
        //     direction='right'
        //     className='bla bla bla'
        //     style={{
        //         width: '95%',
        //         maxWidth: '450px'
        //     }}
        // >
        //     <Cart/>
        // </Drawer>
    )
}

export default CartModal
