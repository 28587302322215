// import { CheckBox } from "@components/ui/checkbox";
// import { useRouter } from "next/router";
import React from "react";
import {CheckBox} from "../../../Components/ui/checkbox";
import {
    CATEGORIES,
    initialFilterParams,
    shoesCategoryFiltersData,
    shoesFilterCategoryConfig, shoesSizeFilterConfig
} from "../../../crmData/filters-data";
import {useSearchParams} from "react-router-dom";
import {useLocation} from "react-router";
import {queryStringToObject} from "../../../helpers";
import {useDispatch} from "react-redux";
import {DEFAULT_PRODUCTS_PER_PAGE, setProductsForShowAmount} from "../../../redux/productsSlice";
// import { useTranslation } from "next-i18next";
// import { Category } from "@framework/types";
// import Button from "@components/ui/button";
// import { useCategoriesInfiniteQuery } from "@framework/category/categories.query";

export const CategoryFilter = ({ config: { label, keyName, options } }) => {
    let [searchParams, setSearchParams] = useSearchParams();
    const { search } = useLocation();
    const queryData = React.useMemo(() => queryStringToObject(search, initialFilterParams), [search]);
      const dispatch = useDispatch()
    const selectedOptions = searchParams.get(keyName)

  function handleItemClick(e) {
    const { value, name } = e.currentTarget;
      console.log(name, 'lplplpplpll', value);
      dispatch(setProductsForShowAmount( DEFAULT_PRODUCTS_PER_PAGE))

      const updatedCategoryFilters = queryData?.[keyName] && queryData[keyName].includes(value)
      ? queryData?.[keyName].filter(el => el !== value)
      : [
              ...queryData?.[keyName] ? queryData?.[keyName] : [],
              value
          ]

      console.log(updatedCategoryFilters);
      setSearchParams({
          ...queryData,
          ...(name === shoesFilterCategoryConfig.keyName && value === CATEGORIES.shoes) ? { [shoesSizeFilterConfig.keyName]: []} : {},
          [keyName]: updatedCategoryFilters
      }, { replace: true })
      window.scrollTo(0, 0)
      // let currentFormState = formState.includes(value)
    //   ? formState.filter((i) => i !== value)
    //   : [...formState, value];
    // const { category, ...restQuery } = query;
    // router.push(
    //   {
    //     pathname,
    //     query: {
    //       ...restQuery,
    //       ...(!!currentFormState.length
    //         ? { category: currentFormState.join(",") }
    //         : {}),
    //     },
    //   },
    //   undefined,
    //   { scroll: false }
    // );
  }

  return (
    <div className="block border-b border-gray-300 pb-7 mb-7">
      <h3 className="text-heading text-sm md:text-base font-semibold mb-7">
        {label}
      </h3>
      <div className="mt-2 flex flex-col space-y-4">
        {
            options.map((category) => (
              <CheckBox
                  key={category.id}
                  label={category.label}
                  value={category.value}
                  name={keyName}
                  checked={Array.isArray(queryData?.[keyName]) && queryData[keyName].includes(category.value)}
                  // checked={formState.includes(category.slug)}
                  onChange={handleItemClick}
              />
          ))
        }

        {/*<div className="w-full">*/}
        {/*  {hasNextPage && (*/}
        {/*    <Button*/}
        {/*      variant="custom"*/}
        {/*      disabled={loadingMore}*/}
        {/*      onClick={() => fetchNextPage()}*/}
        {/*      className="text-sm text-heading ps-9 pt-1"*/}
        {/*    >*/}
        {/*      {t("button-load-more")}*/}
        {/*    </Button>*/}
        {/*  )}*/}
        {/*</div>*/}
      </div>
    </div>
  );
};
