import {Link} from "react-router-dom";
import CartButton from "../../Cart/cart-button";
import MenuIcon from "../../../icons/menu-icon";
import React, {Component, useState} from "react";
import SearchIcon from "../../../icons/search-icon";
import HomeIcon, {ContactsIcon} from "../../../icons/home-icon";
import UserIcon from "../../../icons/user-icon";
import Drawer from "react-modern-drawer";
import MobileMenu from "../header/mobile-menu";
import {useDispatch, useSelector} from "react-redux";
import {closeCartModal, closeSidebar, openSidebar} from "../../../redux/uiSlice";
import {ROUTS} from "../../data/routes";
import DrawerWrapper from "../../../Components/drawer-wrapper";
import DrawerCustom from "../../../Components/drawer";
import {IoCallSharp} from "react-icons/io5";

const BottomNavigation = () => {
	// const [displaySidebar, closeSidebar] = useState(true)
	// increment, decrement, incrementByAmount
	const { sidebarOpened } = useSelector((state) => state.ui)
	const dispatch = useDispatch()

	// const {
	// 	openSidebar,
	// 	closeSidebar,
	// 	displaySidebar,
	// 	setDrawerView,
	// 	openSearch,
	// 	openModal,
	// 	setModalView,
	// } = useUI();

  // const [ isAuthorize ] = useAtom(authorizationAtom);

	// function handleLogin() {
	// 	setModalView("LOGIN_VIEW");
	// 	return openModal();
	// }
	function handleMobileMenu() {
		dispatch(openSidebar());
		// dispatch(closeSidebar({data: 'kookokok'}))
		// setDrawerView("MOBILE_MENU");
		// return openSidebar();
	}
	const handleCloseMenu = () => {
		dispatch(closeSidebar())
	}

	// const { locale } = useRouter();
	// const dir = getDirection(locale);
	// const contentWrapperCSS = dir === "ltr" ? { left: 0 } : { right: 0 };

	return (
		<>
			<div className={'md:hidden fixed z-10 bottom-0 shadow-bottomNavigation bg-white w-full'}>
				<div className=" flex items-center justify-between text-gray-700 body-font bg-white w-full h-14 sm:h-16 px-4">
					<button
						aria-label="Menu"
						className="menuBtn flex flex-col items-center justify-center flex-shrink-0 outline-none focus:outline-none"
						onClick={handleMobileMenu}
					>
						<MenuIcon />
					</button>
					<Link to="/" className="flex-shrink-0">
						<HomeIcon />
					</Link>
					<Link to={ROUTS.CONTACTS_PAGE()} className="flex-shrink-0">
						{/*<UserIcon/>*/}
						{/*<ContactsIcon/>*/}
						<IoCallSharp size={20} className={'currentColor'}/>
					</Link>
					<CartButton />
				</div>
				<div style={{ width: '100vw', height: '16px'}}></div>
			</div>
			<DrawerCustom open={sidebarOpened} onClose={handleCloseMenu} variant="left">
				{/*<DrawerWrapper>*/}
					{/*<div className="flex flex-col space-y-6 p-5">*/}

					{/*</div>*/}
					<MobileMenu />
				{/*</DrawerWrapper>*/}
			</DrawerCustom>
			{/*<Drawer*/}
			{/*	lockBackgroundScroll*/}
			{/*	open={sidebarOpened}*/}
			{/*	onClose={handleCloseMenu}*/}
			{/*	direction='left'*/}
			{/*	// className='bla bla bla'*/}
			{/*	style={{*/}
			{/*		width: '95%',*/}
			{/*		maxWidth: '450px'*/}
			{/*	}}*/}
			{/*>*/}
			{/*	<MobileMenu />*/}
			{/*</Drawer>*/}
		</>
	);
};

export default BottomNavigation;
