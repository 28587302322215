import { FC } from "react";
import { IoLocationSharp, IoMail, IoCallSharp } from "react-icons/io5";
import isEmpty from "lodash/isEmpty";
import {useSettings} from "../../contexts/settings-context";
import {getIcon} from "../../../helpers";
import socialIcons from "../../../icons/social-icon";
// import { formatAddress } from "@lib/format-address";
// import GoogleStaticMap from "@components/common/google-static-map";

const ContactInfoBlock = () => {
  // const settings = useSettings();
    const settings = useSettings();
    const socials = settings?.contactDetails?.socials;
  // const { t } = useTranslation("common");
    const t = (st) => st
    const navigateToSocial = (url) => {
        window.open(url,'_blank')
    }
  return (
    <div className="mb-6 lg:border lg:rounded-md border-gray-300 lg:p-7 items-start">
      <h4 className="text-2xl md:text-lg font-bold text-heading pb-7 md:pb-10 lg:pb-6 -mt-1">
         Наші контакти
      </h4>

      {/* Email */}
      <div className="flex pb-7">
        <div className="flex flex-shrink-0 justify-center items-center p-1.5 border rounded-md border-gray-300 w-10 h-10">
          <IoMail />
        </div>
        <div className="flex flex-col ps-3 2xl:ps-4 text-sm md:text-base">
          <h5 className="text-sm font-bold text-heading">Email</h5>
          {settings?.contactDetails.email
            ? settings?.contactDetails.email
            : t("text-no-email")}
        </div>
      </div>

      {/* Phone */}
      <div className="flex pb-7">
        <div className="flex flex-shrink-0 justify-center items-center p-1.5 border rounded-md border-gray-300 w-10 h-10">
          <IoCallSharp />
        </div>
        <div className="flex flex-col ps-3 2xl:ps-4 text-sm md:text-base">
          <h5 className="text-sm font-bold text-heading">Телефон</h5>
          {settings?.contactDetails.phone ? (
            settings?.contactDetails.phone
          ) : (
            <p className="text-red-500">Телефон не вказаний</p>
          )}
        </div>
      </div>

        {
            socials && (
                socials.map((social, i) => (
                    <div className="flex mb-7 items-center" key={i} >
                        <div
                            onClick={() => navigateToSocial(social.url)}
                            className="flex flex-shrink-0 justify-center items-center p-1.5 border rounded-md border-gray-300 w-10 h-10 cursor-pointer">
                            {getIcon({
                                iconList: socialIcons,
                                iconName: social?.icon,
                            })}
                        </div>
                        <div className="flex flex-col ps-3 2xl:ps-4 text-sm md:text-base">
                            <h5 className="text-sm font-bold text-heading cursor-pointer" onClick={() => navigateToSocial(social.url)}>
                                {social?.label ? social?.label : ''}
                            </h5>
                            {/*{settings?.contactDetails.phone ? (*/}
                            {/*    settings?.contactDetails.phone*/}
                            {/*) : (*/}
                            {/*    <p className="text-red-500">Телефон не вказаний</p>*/}
                            {/*)}*/}
                        </div>
                    </div>
                ))
            )
        }

      {/*/!* Google Map *!/*/}
      {/*{!isEmpty(settings?.contactDetails?.location) && (*/}
      {/*  <GoogleStaticMap*/}
      {/*    lat={settings?.contactDetails?.location?.lat}*/}
      {/*    lng={settings?.contactDetails?.location?.lng}*/}
      {/*  />*/}
      {/*)}*/}
    </div>
  );
};

export default ContactInfoBlock;
