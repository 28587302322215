import {sortOptions} from "./sorts-data";
import {indexBy, prop} from "ramda";

export const CATEGORIES = {
    shoes: 'shoes',
    wallets: 'wallets',
    bags: 'bags',
    remeni: 'remeni',
    woomenRykzak: 'woomenRykzak',
    acsesorials: 'acsesorials',
    sale: 'sale',
}
const CATEGORIES_DATA = {
    [CATEGORIES.woomenRykzak]: { label: 'Рюкзаки', value: CATEGORIES.woomenRykzak, id: 6, crmId: 6, defaultCategories: [CATEGORIES.woomenRykzak] },
    [CATEGORIES.acsesorials]: { label: 'Аксесуари', value: CATEGORIES.acsesorials, id: 5, crmId: 5, defaultCategories: [CATEGORIES.acsesorials, CATEGORIES.remeni, CATEGORIES.wallets] },
    [CATEGORIES.remeni]: { label: 'Ремені', value: CATEGORIES.remeni, id: 4, crmId: 4, defaultCategories: [CATEGORIES.acsesorials, CATEGORIES.remeni] },
    [CATEGORIES.wallets]: { label: 'Кошильки', value: CATEGORIES.wallets, id: 3, crmId: 3, defaultCategories: [CATEGORIES.acsesorials, CATEGORIES.wallets] },
    [CATEGORIES.bags]: { label: 'Сумки', value: CATEGORIES.bags, id: 2, crmId: 2, defaultCategories: [CATEGORIES.bags] },
    [CATEGORIES.shoes]: { label: 'Жіноче взуття', value: CATEGORIES.shoes, id: 1, crmId: 1, defaultCategories: [CATEGORIES.shoes] },
    [CATEGORIES.sale]: { label: 'Знижки', value: CATEGORIES.sale, id: 7, defaultCategories: [CATEGORIES.sale] },
}

const shoesCategoryFiltersData = Object.values(CATEGORIES_DATA)

const PRICE_VALUES_MAP = {
    less1000: 'less1000',
    from1000to2000: 'from1000to2000',
    from2000to3000: 'from2000to3000',
    from3000to4000: 'from3000to4000',
}
const shoesPriceFiltersData = [
    {
        id: 1,
        label: 'до 1000грн',
        value: PRICE_VALUES_MAP.less1000

    },
    {
        id: 2,
        label: '1000грн - 2000грн',
        value: PRICE_VALUES_MAP.from1000to2000

    },
    {
        id: 3,
        label: '2000грн - 3000грн',
        value: PRICE_VALUES_MAP.from2000to3000
    },
    {
        id: 4,
        label: '3000грн - 4000грн',
        value: PRICE_VALUES_MAP.from3000to4000
    }
]
// зелений
// синій
const COLORS = {
    'black': 'чорний',
    'white': 'білий',
    'brawn': 'коричневий',
    'red': 'червоний',
}
const shoesColorFiltersData = [
    {
        id: 1,
        label: 'Чорний',
        crmValue: 'чорний',
        value: 'black'

    },
    {
        id: 2,
        label: 'Білий',
        value: 'white',
        crmValue: 'білий',

    },
    {
        id: 3,
        label: 'Коричневий',
        value: 'brawn',
        crmValue: 'коричневий',
    },
    {
        id: 4,
        label: 'Червоний',
        crmValue: 'червоний',
        value: 'red'
    },
]
const PRICE_FILTERS_MAP = indexBy(prop('value'), shoesPriceFiltersData)
const COLOR_FILTERS_MAP = indexBy(prop('value'), shoesColorFiltersData)
const COLOR_FILTERS_CRM_MAP = indexBy(prop('crmValue'), shoesColorFiltersData)

const SHOES_SIZES = {
    '35': '35',
    '36': '36',
    '37': '37',
    '38': '38',
    '39': '39',
    '40': '40',
}
const SHOES_SIZES_DATA = {
    [SHOES_SIZES["35"]]: { id: 1, label: '35р', value:SHOES_SIZES['35'] },
    [SHOES_SIZES["36"]]: { id: 2, label: '36р', value:SHOES_SIZES['36'] },
    [SHOES_SIZES["37"]]: { id: 3, label: '37р', value:SHOES_SIZES['37'] },
    [SHOES_SIZES["38"]]: { id: 4, label: '38р', value:SHOES_SIZES['38'] },
    [SHOES_SIZES["39"]]: { id: 5, label: '39р', value:SHOES_SIZES['39'] },
    [SHOES_SIZES["40"]]: { id: 6, label: '40р', value:SHOES_SIZES['40'] },
}
const shoesSizeFiltersData = Object.values(SHOES_SIZES_DATA)
const shoesFilterCategoryConfig = {
    label: 'Категорії',
    keyName: 'category',
    options: shoesCategoryFiltersData
}
const priceFilterConfig = {
    label: 'Ціна',
    keyName: 'price',
    options: shoesPriceFiltersData
}
const colorFilterConfig = {
    label: 'Колір',
    keyName: 'color',
    options: shoesColorFiltersData
}
const shoesSizeFilterConfig = {
    label: 'Розмір',
    keyName: 'size',
    options: shoesSizeFiltersData
}

const initialFilterParams = {
    [shoesFilterCategoryConfig['keyName']]: [],
    [priceFilterConfig['keyName']]: [],
    [colorFilterConfig['keyName']]: [],
    [shoesSizeFilterConfig['keyName']]: [],
    sorting: 'default',
    page: 1
}
const ALL_FILTERS_DATA = {
    ...CATEGORIES_DATA,
    ...COLOR_FILTERS_MAP,
    ...PRICE_FILTERS_MAP,
    ...SHOES_SIZES_DATA
}

export {
    initialFilterParams,
    shoesFilterCategoryConfig,
    shoesCategoryFiltersData,
    priceFilterConfig,
    colorFilterConfig,
    CATEGORIES_DATA,
    PRICE_FILTERS_MAP,
    COLOR_FILTERS_MAP,
    PRICE_VALUES_MAP,
    ALL_FILTERS_DATA,
    shoesSizeFilterConfig,
    SHOES_SIZES_DATA,
    COLOR_FILTERS_CRM_MAP
}
