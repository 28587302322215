import React, { useRef } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { Swiper, SwiperSlide } from 'swiper/react';

import "swiper/css/bundle";
import "swiper/css";
// // import { getDirection } from "@utils/get-direction";
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
// // SwiperCore.use([Navigation, Pagination, Autoplay]);


const Carousel = ({
	children,
	className = "",
	buttonClassName = "",
	buttonSize = "default",
	paginationVariant = "default",
	breakpoints,
  loop,
	autoplay = {
		delay: 4000,
	},
	...props
}) => {
	const prevRef = useRef(null);
	const nextRef = useRef(null);
	// const { locale } = useRouter();
	const dir = 'default';
	return (
		<div
			className={`carouselWrapper relative ${className} ${
				paginationVariant === "circle" ? "dotsCircle" : ""
			}`}
		>
			<Swiper
				modules={[Autoplay, Pagination, Navigation]}
				loop={loop ?? true}
				rewind={true}
				autoplay={autoplay}
				breakpoints={breakpoints}
				// dir={dir}
				navigation={{
					prevEl: prevRef.current ? prevRef.current : undefined,
					nextEl: nextRef.current ? nextRef.current : undefined,
				}}
				onInit={(swiper) => {
					const navigation = swiper.params.navigation;
					navigation.prevEl = prevRef.current;
					navigation.nextEl = nextRef.current;
					swiper.navigation.update();
				}}
				{...props}
			>
				{children}
			</Swiper>
			<div className="flex items-center w-full absolute top-2/4 z-10">
				<button
					ref={prevRef}
					aria-label="prev-button"
					className={`${buttonClassName} ${
						buttonSize === "default"
							? "w-7 h-7 md:w-7 md:h-7 lg:w-9 lg:h-9 xl:w-10 xl:h-10 3xl:w-12 3xl:h-12 text-sm md:text-base lg:text-xl 3xl:text-2xl"
							: "w-7 h-7 md:w-7 md:h-7 lg:w-8 lg:h-8 text-sm md:text-base lg:text-lg"
					} text-black flex items-center justify-center rounded-full text-gray-0 bg-white absolute transition duration-250 hover:bg-gray-900 hover:text-white focus:outline-none start-0 transform ${
						dir === "rtl"
							? "rotate-180 shadow-navigationReverse translate-x-1/2"
							: "shadow-navigation -translate-x-1/2"
					}`}
				>
					<IoIosArrowBack />
				</button>
				<button
					ref={nextRef}
					aria-label="next-button"
					className={`${buttonClassName} ${
						buttonSize === "default"
							? "w-7 h-7 lg:w-9 lg:h-9 xl:w-10 xl:h-10 3xl:w-12 3xl:h-12 text-sm md:text-base lg:text-xl 3xl:text-2xl"
							: "w-7 h-7 lg:w-8 lg:h-8 text-sm md:text-base lg:text-lg"
					} text-black flex items-center justify-center rounded-full bg-white absolute transition duration-250 hover:bg-gray-900 hover:text-white focus:outline-none end-0 transform ${
						dir === "rtl"
							? "rotate-180 shadow-navigationReverse -translate-x-1/2"
							: 
							"shadow-navigation translate-x-1/2"
					}`}
				>
					<IoIosArrowForward />
				</button>
			</div>
		</div>
	);
};

export default Carousel;
