import PageHeader from "../../../Components/page-header";
import Divider from "../../../Components/ui/divider";
import Container from "../../../Components/ui/container";
import {useSettings} from "../../contexts/settings-context";

const text = ({ companyName, schedule, phone }) => 'Доставка товарів по Україні виконується через перевізника "Нова Пошта". Ви маєте можливість вибрати доставку до відділення, в поштомат або кур\'єром за адресою.##' +
    'Оплата замовлень доступна двома способами:##' +
    '1. За реквізитами на рахунок ФОП.##' +
    '2. Накладним платежем, за умови передоплати 200 грн,під час отримання товарів у відділенні "Нової Пошти".##' +
    'При оплаті накладним платежем при отриманні товару стягується додаткова комісія у розмірі 20 грн та 2% від суми замовлення. Вартість доставки визначається відповідно до тарифів "Нової Пошти". Якщо товар вам не підійшов з будь-якої причини, ви можете відмовитись від нього у відділенні "Нової Пошти" або повернути його кур\'єру і не сплачувати за нього.##' +
    'Інформацію щодо термінів доставки в ваш регіон ви можете знайти на веб-сайті "Нової Пошти".##' +
    'Будь ласка, зверніть увагу, що повна оплата обов\'язкова у таких випадках:\n##' +
    '1. вартість товару не перевищує 1000 грн.##' +
    '2. Кількість відмов від посилок перевищує кількість оплачених посилок.\n##' +
    'Щодо умов повернення товару, будь ласка, ознайомтеся у розділі "Повернення чи обмін товару".##' +
    'З усіма питаннями, пов\'язаними з вашими замовленнями, ви можете звернутися до нашої служби підтримки за номером телефону:##' +
    `${phone}##` +
    `Ми працюємо ${schedule}.##` +
    `${companyName} завжди старається забезпечити вам комфортні та зручні покупки в Інтернеті!`
const DeliveryPage = () => {
    const setting = useSettings()
    const textArray = text(
        { schedule: setting.schedule, companyName: setting?.siteTitle, phone: setting?.contactDetails?.phone }
        ).split('##')

    return (
        <>
            <PageHeader pageHeader="Доставка та оплата" pageSubHeader="LunaMe"/>
            <Divider className="mb-0" />

            <Container>
                <div className={'py-10'}>
                    {
                        textArray.map((el, i) => (
                            <p className={'mb-4'} key={i}>{el}</p>
                        ))
                    }
                </div>
            </Container>
        </>
    )
}
export default DeliveryPage
