import React, {useMemo} from 'react'
import {Link, useNavigate} from "react-router-dom";
import ProductSingleDetails from "../components/product-single-details";
import {productsData, productsDataMap} from "../../../crmData";
import Divider from "../../../Components/ui/divider";
import Container from "../../../Components/ui/container";
import {useParams} from "react-router";
import RelatedProducts from "../../../Components/products/related-products";
import {CRM_PRODUCTS} from "../../../crmData/products";
import {motion} from "framer-motion";
import {fadeInOut} from "../../../helpers";
import EmptyCart from "../../Cart/empty-cart";
import {ROUTS} from "../../data/routes";
const findRelatedProducts = (product) => {
    if (!product) return []
    const productSku = product.sku || product?.variations?.[0]?.sku
    const mainSkuPartProduct = productSku?.split("*")?.[0]
    // console.log(productSku.split("*"), 'kkikookokko');
    const data = CRM_PRODUCTS.filter(el => {
        const elSku = el.sku || el?.variations?.[0]?.sku
        const mainSkuPart = elSku?.split("*")?.[0]
        const isRelativeItem = String(mainSkuPart) === String(mainSkuPartProduct)
        return isRelativeItem && el.id !== product.id
    })
    return data
}
const ProductProfilePage = ({match}) => {
    const navigate = useNavigate();
    let { productId } = useParams();
    console.log(productId, 'dredferfre');
    const product = useMemo(() => productsDataMap[productId], [productId]) // TODO додати обробку коли продукту нема
    const handleNavigateToHome = () => { navigate('/') }
    const handleNavigateToProductsLIst = () => { navigate('/products') }
    const relatedProducts = useMemo(() => {
        return findRelatedProducts(product)
    }, [product, productId])
    if (!product) {
        console.log('demkwmdkwekm')
        return (
            <motion.div
                layout
                initial="from"
                animate="to"
                exit="from"
                variants={fadeInOut(0.25)}
                className="flex flex-col px-5 md:px-7 pt-8 pb-9 flex justify-center flex-col items-center"
            >
                <h3 className="text-lg text-heading font-bold pt-8 text-center">
                    Нажать цей товар відсутній або розпроданий
                </h3>
                <Link to={ROUTS.PRODUCTS_LIST_PAGE()}
                      className=" mt-2 text-accent text-base font-semibold underline hover:no-underline"
                >
                    Повернутися до пошуку товарів
                </Link>
                <EmptyCart width={200} height={400}/>
            </motion.div>
        )
    }
    return (
        <>
            <Divider className="mb-0" />
            <Container>
                <ProductSingleDetails product={product}/>
                {
                    relatedProducts?.length > 0
                    && (
                        <RelatedProducts
                            products={relatedProducts}
                            currentProductId={product?.id}
                            sectionHeading="Схожі товари"
                        />
                    )
                }
            </Container>
        </>
    )
}

    export default ProductProfilePage
