import React, {useEffect, useRef} from "react";
import {AddActiveScroll} from "../../../helpers";
import Logo from "../../../Components/ui/logo";
import HeaderMenu from "./header-menu";
import {menu} from "../../data/static/menus";
import CartButton from "../../Cart/cart-button";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";
import {setCardItems, setFastBuyItems} from "../../../redux/productsSlice";
// import SearchIcon from "@components/icons/search-icon";
// import Logo from "@components/ui/logo";
// import { useUI } from "@contexts/ui.context";
// import { ROUTES } from "@lib/routes";
// import { addActiveScroll } from "@utils/add-active-scroll";
// import dynamic from "next/dynamic";
// import { useTranslation } from "next-i18next";
// import { useAtom } from "jotai";
// import { authorizationAtom } from "@store/authorization-atom";
// import { menu } from "@data/static/menus";
// import HeaderMenu from "@components/layout/header/header-menu";
// const AuthMenu = dynamic(() => import("./auth-menu"), { ssr: false });
// const CartButton = dynamic(() => import("@components/cart/cart-button"), {
//     ssr: false,
// });

// type DivElementRef = React.MutableRefObject<HTMLDivElement>;
const Header = () => {
    const dispatch = useDispatch()
    const { cardItems, fastBuyItems } = useSelector((state) => state.products)
    useEffect(() => {
        const cardData = localStorage.getItem('cardDataShopL');
        const fastBuyItems = localStorage.getItem('fastBuyItems');
        const parsedData = JSON.parse(cardData)
        const parsedFastItems = JSON.parse(fastBuyItems)
        if (parsedData && !isEmpty(parsedData)) {
            dispatch(setCardItems(parsedData))
        }
        if (parsedFastItems && !isEmpty(parsedFastItems)) {
            dispatch(setFastBuyItems(parsedFastItems))
        }

    }, [])
    useEffect(() => {
        if (cardItems) {
            localStorage.setItem('cardDataShopL', JSON.stringify(cardItems));
        }
    }, [cardItems])

    useEffect(() => {
        if (fastBuyItems) {
            localStorage.setItem('fastBuyItems', JSON.stringify(fastBuyItems));
        }
    }, [fastBuyItems])
    // const {
    //     openSidebar,
    //     setDrawerView,
    //     openSearch,
    //     openModal,
    //     setModalView,
    // } = useUI();
    // const [ isAuthorize ] = useAtom(authorizationAtom);
    // const { t } = useTranslation("common");
    const siteHeaderRef = useRef();
    AddActiveScroll(siteHeaderRef);

    function handleLogin() {
        // setModalView("LOGIN_VIEW");
        // return openModal();
    }
    function handleMobileMenu() {
        // setDrawerView("MOBILE_MENU");
        // return openSidebar();
    }

    return (
        <header
            id="siteHeader"
            ref={siteHeaderRef}
            className="w-full h-16 sm:h-20 lg:h-24 relative z-20"
        >
            <div className="innerSticky text-gray-700 body-font fixed bg-white w-full h-16 sm:h-20 lg:h-24 z-20 ps-4 md:ps-0 lg:ps-6 pe-4 lg:pe-6 transition duration-200 ease-in-out">
                <div className="flex items-center justify-center mx-auto max-w-[1920px] h-full w-full">
                    {/*<button*/}
                    {/*    aria-label="Menu"*/}
                    {/*    onClick={handleMobileMenu}*/}
                    {/*>*/}
					{/*	<span className="menuIcon">*/}
					{/*		<span className="bar" />*/}
					{/*		<span className="bar" />*/}
					{/*		<span className="bar" />*/}
					{/*	</span>*/}
                    {/*</button>*/}
                    <Logo />

                    <HeaderMenu data={menu}
                                className="hidden lg:flex md:ms-6 xl:ms-10"
                    />

                    <div className="hidden md:flex justify-end items-center space-s-6 lg:space-s-5 xl:space-s-8 2xl:space-s-10 ms-auto flex-shrink-0">
                        <button
                            className="flex items-center justify-center flex-shrink-0 h-auto relative focus:outline-none transform"
                            // onClick={openSearch}
                            aria-label="search-button"
                        >
                            {/*<SearchIcon />*/}
                        </button>
                        <div className="-mt-0.5 flex-shrink-0">
                            {/*<AuthMenu*/}
                            {/*    isAuthorized={isAuthorize}*/}
                            {/*    href={ROUTES.ACCOUNT}*/}
                            {/*    className="text-sm xl:text-base text-heading font-semibold"*/}
                            {/*    btnProps={{*/}
                            {/*        className:*/}
                            {/*            "text-sm xl:text-base text-heading font-semibold focus:outline-none",*/}
                            {/*        children: t("text-sign-in"),*/}
                            {/*        onClick: handleLogin,*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    {t("text-page-my-account")}*/}
                            {/*</AuthMenu>*/}
                        </div>
                        <CartButton />
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
