// import { ShopFilters } from "@components/shop/filters";
// import Scrollbar from "@components/common/scrollbar";
// import { useUI } from "@contexts/ui.context";
import { IoArrowBack } from "react-icons/io5";
import { IoArrowForward } from "react-icons/io5";
// import { useTranslation } from "next-i18next";
// import { getDirection } from "@utils/get-direction";
// import { useRouter } from "next/router";
import React, {useEffect} from "react";
import Scrollbar from "../../../Components/scrollbar";
import {ShopFilters} from "./filters";
import {closeCartModal, closeFilterModal, openFilterModal} from "../../../redux/uiSlice";
import {useDispatch, useSelector} from "react-redux";
import Drawer from "react-modern-drawer";
import DrawerWrapper from "../../../Components/drawer-wrapper";
import DrawerCustom from "../../../Components/drawer";
import {useWindowSize} from "../../../helpers";


const FilterSidebar = ({ searchResultCount }) => {
  // const { closeFilter } = useUI();
  const { products } = useSelector(state => state.products)
  // const router = useRouter();
  // const { t } = useTranslation("common");
  // const dir = getDirection(router.locale);
    const dispatch = useDispatch()
    const closeFilter = () => {
            dispatch(closeFilterModal())
    }
    const t = te => te
  return (
    <div className="flex flex-col justify-between w-full h-full">
      <div className="w-full border-b border-gray-100 flex justify-between items-center relative pe-5 md:pe-7 flex-shrink-0 py-0.5">
        <button
          className="flex text-2xl items-center justify-center text-gray-500 px-4 md:px-5 py-6 lg:py-8 focus:outline-none transition-opacity hover:opacity-60"
          onClick={closeFilter}
          aria-label="close"
        >
          {/*{dir === "rtl" ? (*/}
          {/*  <IoArrowForward className="text-black" />*/}
          {/*) : (*/}
            <IoArrowBack className="text-black" />
          {/*)}*/}
        </button>
        <h2 className="font-bold text-xl md:text-2xl m-0 text-heading w-full text-center pe-6">
          Фільтри
        </h2>
      </div>

      <Scrollbar className="menu-scrollbar flex-grow mb-auto">
        <div className="flex flex-col py-7 px-5 md:px-7 text-heading">
          <ShopFilters />
        </div>
      </Scrollbar>

      <div
          onClick={closeFilter}
          className="text-sm md:text-base leading-4 flex items-center justify-center px-7 flex-shrink-0 h-14 bg-heading text-white cursor-pointer">
       Всього товарів: {products?.length ?? 0}
      </div>
    </div>
  );
};

export const FilterSidebarModal = () => {
    const { showShowFilterModal } = useSelector((state) => state.ui)

    const dispatch = useDispatch()
    const openFilter = () => {
        dispatch(openFilterModal())
    }
    const closeFilter = () => {
        dispatch(closeFilterModal())
    }
    // const searchResultCount = 10

    return (
        <DrawerCustom
            open={showShowFilterModal}
            onClose={closeFilter}
            variant='left'
            // className="fixed inset-0 overflow-hidden h-full z-50"
            // style={{
            //     width: '95%',
            //     // background: 'red',
            //     maxWidth: '450px',
            //     // height: `${height}px`
            // }}

        >
            <FilterSidebar/>
            {/*<DrawerCustom open={showShowFilterModal} onClose={closeFilter} variant="right">*/}
            {/*    <DrawerWrapper>*/}
            {/*        /!*<div className="flex flex-col space-y-6 p-5">*!/*/}

            {/*        /!*</div>*!/*/}
            {/*    </DrawerWrapper>*/}
            {/*</DrawerCustom>*/}
        </DrawerCustom>
    )
}

export default FilterSidebar;
