// import type { FC } from "react";
// import { useProductsInfiniteQuery } from "@framework/products/products.query";
// import { useRouter } from "next/router";
// import { formatPriceRange } from "@lib/format-price-range";
// import SearchTopBar from "@components/shop/top-bar";
// import ProductInfiniteGrid from "@components/product/product-infinite-grid";



import {productsData} from "../../crmData";
import SearchTopBar from "./top-bar";
import ProductInfiniteGrid from "./product-infinite-grid";
import {useDispatch, useSelector} from "react-redux";
import {useMemo, useState} from "react";
import {take, add} from "ramda";
import {DEFAULT_PRODUCTS_PER_PAGE, setProductsForShowAmount} from "../../redux/productsSlice";

export const ProductSearchBlock = ({
  className = "",
                                       products,
}) => {
    const { productsForShowInList } = useSelector(state => state.products)
    const dispatch = useDispatch()
    const [loadingMore, setLoadingMore] = useState(false)

  const isLoading = false
   const fetchNextPage = () => {
      setLoadingMore(true)
        setTimeout(() => {
            setLoadingMore(false)
            dispatch(setProductsForShowAmount(productsForShowInList + DEFAULT_PRODUCTS_PER_PAGE))
        }, 300)
   }
   const visibleProducts = useMemo(() => take(productsForShowInList, products), [productsForShowInList, products])

   const hasNextPage = visibleProducts?.length < products?.length
   const error = null
  if (error) return <p>{error.message}</p>;

  return (
    <>
      <SearchTopBar
        searchResultCount={products?.length || 0}
      />
      <ProductInfiniteGrid
        className={className}
        loading={isLoading}
        data={visibleProducts}
        hasNextPage={hasNextPage}
        loadingMore={loadingMore}
        fetchNextPage={fetchNextPage}
      />
    </>
  );
};

export default ProductSearchBlock;
