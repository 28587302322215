import {uniq} from "ramda";
import {Link} from "react-router-dom";
import {ROUTS} from "../../data/routes";
import {ALL_FILTERS_DATA, COLOR_FILTERS_CRM_MAP} from "../../../crmData/filters-data";
import React from "react";

const ProductDescribeSection = ({ sku, product }) => {
    return (
        <ul className="text-sm space-y-3 pb-1">
            {sku && (
                <li>
                <span className="font-semibold text-heading inline-block pe-2">
                  Код:
                </span>
                    {sku}
                </li>
            )}

            {product?.categories &&
            Array.isArray(product.categories) &&
            product.categories.length > 0 && (
                <li>
                  <span className="font-semibold text-heading inline-block pe-2">
                    Категорії:
                  </span>
                    {uniq(product.categories).map((category, index) => (
                        <Link
                            key={index}
                            to={`${ROUTS.PRODUCTS_BY_CATEGORY([category])}`}
                            className="transition hover:underline hover:text-heading"
                        >
                            {uniq(product.categories)?.length === index + 1
                                ? ALL_FILTERS_DATA[category].label
                                : `${ALL_FILTERS_DATA[category].label}, `}
                        </Link>
                    ))}
                </li>
            )}

            {
                !!product?.productColor?.length
                && (
                    <li>
                <span className="font-semibold text-heading inline-block pe-2">
                  Колір:
                </span>
                        {product?.productColor.map((color, index) => (
                            <>
                                {product?.productColor?.length === index + 1
                                    ? COLOR_FILTERS_CRM_MAP[color].label
                                    : `${COLOR_FILTERS_CRM_MAP[color].label}, `}
                            </>
                        ))}
                    </li>
                )
            }

            {
                product?.productSize && (
                    <li>
                <span className="font-semibold text-heading inline-block pe-2">
                  Розміри:
                </span>
                        {product?.productSize}
                    </li>
                )
            }
            <li>
                <span className="font-semibold text-heading inline-block pe-2">
                  Матеріал:
                </span>
                Шкіра
            </li>


            {/*<li>*/}
            {/*  <span className="font-semibold text-heading inline-block pe-2">*/}
            {/*    {t("text-shop-colon")}*/}
            {/*  </span>*/}
            {/*  <Link*/}
            {/*    // href={`${ROUTES.SHOPS}/${product?.shop?.slug}`}*/}
            {/*    className="inline-block pe-1.5 transition hover:underline hover:text-heading last:pe-0"*/}
            {/*  >*/}
            {/*    {product?.shop?.name}*/}
            {/*  </Link>*/}
            {/*</li>*/}
        </ul>
    )
}

export default ProductDescribeSection
