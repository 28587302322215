import PageHeader from "../../../Components/page-header";
import Divider from "../../../Components/ui/divider";
import Container from "../../../Components/ui/container";
import OrderView from "../components/order-view";


const SussesOrderCreatedPage = () => {
    return (
        <>
            <PageHeader pageHeader="Ваше замовлення прийнято" pageSubHeader="LunaMe"/>
            <Divider className="mb-0" />
            <Container>
                <OrderView order={{}}/>
            </Container>
        </>
    )
}
export default SussesOrderCreatedPage
