import { Outlet, Link } from "react-router-dom";
import React, {useState} from 'react'
// import ManagedDrawer from "../../../Components/drawer";
// import CartModal from "../../Cart";
import Home from "./index";

const HomePage = () => {
    // const [showCart, setShowCart] = useState()
    return (
        <>
        <Home/>
        </>
    )
}

export default HomePage
