// import ProductCardLoader from "@components/ui/loaders/product-card-loader";



import ProductCardLoader from "./product-card-loader";

const ProductFeedLoader = ({ limit = 5, uniqueKey = "product" }) => {
	return (
		<>
			{Array.from({ length: limit }).map((_, idx) => (
				<ProductCardLoader key={idx} uniqueKey={`${uniqueKey}-${idx}`} />
			))}
		</>
	);
};

export default ProductFeedLoader;
