import { createSlice } from '@reduxjs/toolkit'
// import type { PayloadAction } from '@reduxjs/toolkit'

const initialState = {
    sidebarOpened: false,
    showCartModal: false,
    showShowFilterModal: false,

}

export const counterSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        openSidebar: (state) => {
            state.sidebarOpened = true
        },
        closeSidebar: (state) => {
            state.sidebarOpened = false
        },
        openCartModal: (state) => {
            state.showCartModal = true
        },
        closeCartModal: (state) => {
            state.showCartModal = false
        },
        openFilterModal: (state) => {
            state.showShowFilterModal = true
        },
        closeFilterModal: (state) => {
            state.showShowFilterModal = false
        },

        // closeSidebar: (state) => {
        //     // state.sidebarOpened = false
        // },
        // increment: (state) => {
        //     // Redux Toolkit allows us to write "mutating" logic in reducers. It
        //     // doesn't actually mutate the state because it uses the Immer library,
        //     // which detects changes to a "draft state" and produces a brand new
        //     // immutable state based off those changes
        //     state.value += 1
        // },
        // decrement: (state) => {
        //     state.value -= 1
        // },
        // incrementByAmount: (state, action) => {
        //     state.value += action.payload
        // },
    },
})

// Action creators are generated for each case reducer function
export const {
    openSidebar,
    closeSidebar,
    openCartModal,
    closeCartModal,
    openFilterModal,
    closeFilterModal,
} = counterSlice.actions

export default counterSlice.reducer
