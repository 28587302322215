const HomeIcon = ({
	color = "currentColor",
	width = "18px",
	height = "20px",
}) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox="0 0 17.996 20.442"
		>
			<path
				d="M48.187,7.823,39.851.182A.7.7,0,0,0,38.9.2L31.03,7.841a.7.7,0,0,0-.211.5V19.311a.694.694,0,0,0,.694.694H37.3A.694.694,0,0,0,38,19.311V14.217h3.242v5.095a.694.694,0,0,0,.694.694h5.789a.694.694,0,0,0,.694-.694V8.335a.7.7,0,0,0-.228-.512ZM47.023,18.617h-4.4V13.522a.694.694,0,0,0-.694-.694H37.3a.694.694,0,0,0-.694.694v5.095H32.2V8.63l7.192-6.98L47.02,8.642v9.975Z"
				transform="translate(-30.619 0.236)"
				fill={color}
				stroke={color}
				strokeWidth="0.4"
			/>
		</svg>
	);
};

export const ContactsIcon = ({
	color = "currentColor",
	width = "20px",
	height = "20px",
}) => {
	return (
		<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512"
			 width={width}
			 height={height} xmlns="http://www.w3.org/2000/svg">
			<path fill="none" strokeMiterlimit="10" strokeWidth="32" d="M451 374c-15.88-16-54.34-39.35-73-48.76-24.3-12.24-26.3-13.24-45.4.95-12.74 9.47-21.21 17.93-36.12 14.75s-47.31-21.11-75.68-49.39-47.34-61.62-50.53-76.48 5.41-23.23 14.79-36c13.22-18 12.22-21 .92-45.3-8.81-18.9-32.84-57-48.9-72.8C119.9 44 119.9 47 108.83 51.6A160.15 160.15 0 0083 65.37C67 76 58.12 84.83 51.91 98.1s-9 44.38 23.07 102.64 54.57 88.05 101.14 134.49S258.5 406.64 310.85 436c64.76 36.27 89.6 29.2 102.91 23s22.18-15 32.83-31a159.09 159.09 0 0013.8-25.8C465 391.17 468 391.17 451 374z"></path>
		</svg>
	);
};
// export const ContactsIcon = (props ={}) => (
// 	<svg
// 		xmlns="http://www.w3.org/2000/svg"
// 		viewBox="0 0 27.442 27.442"
// 		width = "20px"
// 	height = "20px"
// 		{...props}
// 		fill="currentColor"
// 	>
// 		<path d="M19.494 0H7.948a1.997 1.997 0 00-1.997 1.999v23.446c0 1.102.892 1.997 1.997 1.997h11.546a1.998 1.998 0 001.997-1.997V1.999A1.999 1.999 0 0019.494 0zm-8.622 1.214h5.7c.144 0 .261.215.261.481s-.117.482-.261.482h-5.7c-.145 0-.26-.216-.26-.482s.115-.481.26-.481zm2.85 24.255a1.275 1.275 0 110-2.55 1.275 1.275 0 010 2.55zm6.273-4.369H7.448V3.373h12.547V21.1z" />
// 	</svg>
// );


export default HomeIcon;
