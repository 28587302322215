import {useEffect, RefObject, useState} from 'react'
import {toast} from "react-toastify";

export function AddActiveScroll (ref, topOffset = 0) {
    useEffect(() => {
        const element = ref?.current;
        const listener = () => {
            if (window.scrollY > topOffset) {
                element?.classList.add('is-scrolling');
            } else {
                element?.classList.remove('is-scrolling');
            }
        };
        document.addEventListener('scroll', listener);
        return () => {
            document.removeEventListener('scroll', listener);
        };
    }, []);
}

export default function usePrice(
    data
) {
    const { amount, baseAmount, currencyCode } = data ?? {};
    // const { locale } = useRouter();
    // const value = useMemo(() => {
    //     if (typeof amount !== "number" || !currencyCode) return "";
    //     const currentLocale = locale ? locale : "en";
    //     return baseAmount
    //         ? formatVariantPrice({
    //             amount,
    //             baseAmount,
    //             currencyCode,
    //             locale: currentLocale,
    //         })
    //         : formatPrice({ amount, currencyCode, locale: currentLocale });
    // }, [amount, baseAmount, currencyCode, locale]);

    return  { price: `${amount}грн`, basePrice: baseAmount ? `${baseAmount}грн` : null};
}


export function fadeInOut (duration = 0.2) {
    return {
        from: {
            opacity: 0,
            transition: {
                type: 'easeInOut',
                duration: duration,
            }
        },
        to: {
            opacity: 1,
            transition: {
                type: 'easeInOut',
                duration: duration,
            }
        },
    }
}

export const getIcon = ({ iconList, iconName, ...rest }) => {
    const TagName = iconList[iconName];
    return !!TagName ? <TagName {...rest} /> : null;
};
function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}
export function useWindowSize() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

export const filterBrandImages = (images, layout) => {
    return images?.find((image) => image.key === layout);
}
export const makeId = () => {
    return "id" + Math.random().toString(16).slice(2);
}

export function createQueryString(queryObject = {}) {
    let queryString = Object.keys(queryObject)
        .filter((key) => queryObject[key] && !(Array.isArray(queryObject[key]) && !queryObject[key].length))
        .map((key) => {
            return Array.isArray(queryObject[key]) ? queryObject[key].map(item => `${encodeURIComponent(key)}=${encodeURIComponent(item)}`).join('&') : `${encodeURIComponent(key)}=${encodeURIComponent(queryObject[key])}`;
        }).join('&');
    return queryString ? `?${queryString}` : "";
};

export function queryStringToObject(queryString = "", options = {}) {
    let queryObject = {};
    queryString && decodeURIComponent(queryString.replace('?', '')).split('&').map((itemString) => {
        let [itemKey, itemValue] = itemString.split("=");
        if (options.hasOwnProperty(itemKey)) {
            if (!queryObject[itemKey] && Array.isArray(options[itemKey])) {
                queryObject[itemKey] = [];
            }
            Array.isArray(options[itemKey]) ? queryObject[itemKey].push(itemValue) : queryObject[itemKey] = typeof options[itemKey] === "number" ? parseInt(itemValue) : itemValue;}
    });
    return queryObject;
};

export const convertArrayToObg = (array, path) => array.reduce((accum, el) => ({
    ...accum,
    [el[path]]: el
}), {})

export const getCardItemsTotalPrice = (items) => {
    const total = items.reduce((accum, item) => {
        return accum + (item.unitPrice * item.quantity)
    }, 0)
    return total || 0
}

export const checkIfAbleSendFormData = (key, minutes = 3) => {
    const lastDate = Number(localStorage.getItem(key));
    if (!lastDate) return true

    const nowDate = Math.floor(Date.now() / 1000)
    const minutesOutAfterPreviousSend = Math.round((nowDate - lastDate) / 60)
    const needWaitMinutes = minutes - minutesOutAfterPreviousSend
    const ableForSend = needWaitMinutes <= 0

    if (!ableForSend) {
        toast(`Помилка.Ви нещодавно відправляли форму. Ви зможете надіслати форму повторно через ${needWaitMinutes}хвилин`, {
            type: "error",
            progressClassName: "fancy-progress-bar",
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    }
    return ableForSend

    // console.log((Math.floor(Date.now() / 1000) - 1697116583) / 60, 'dkewkodokew')
}
