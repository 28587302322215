import Scrollbar from "./scrollbar";



const DrawerWrapper = ({
  children,
  onClose,
}) => {
  return (
      <div className="h-full w-screen max-w-md">
        <div className="h-full flex flex-col text-body bg-light shadow-xl">
          {/*{children}*/}
        <Scrollbar className="w-full h-full">{children}</Scrollbar>
        </div>
      </div>
    // <div className="flex flex-col h-full">
    //
    //   {/* End of header part */}
    //   {/*<div className="pt-16 h-full">*/}
    //   {/*</div>*/}
    //   {/* End of menu part */}
    // </div>
  );
};

export default DrawerWrapper;
