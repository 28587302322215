import { configureStore } from '@reduxjs/toolkit'
import uiReducer from './uiSlice'
import productsReducer from './productsSlice'

export const store = configureStore({
    reducer: {
        ui: uiReducer,
        products: productsReducer
    },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
// export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
// export type AppDispatch = typeof store.dispatch
