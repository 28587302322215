// import { CategoryFilter } from "./category-filter";
// import { BrandFilter } from "./brand-filter";
// import { FilteredItem } from "./filtered-item";
// import { PriceFilter } from "./price-filter";
// import { useRouter } from "next/router";
import isEmpty from "lodash/isEmpty";
import {useHistory, useSearchParams} from "react-router-dom";
import {FilteredItem} from "./filtered-item";
import {useLocation} from "react-router";
import {queryStringToObject} from "../../../helpers";
import React, {useEffect} from 'react'
import {CategoryFilter} from "./category-filter";
import {
	ALL_FILTERS_DATA, CATEGORIES,
	colorFilterConfig,
	initialFilterParams,
	priceFilterConfig,
	shoesFilterCategoryConfig, shoesSizeFilterConfig
} from "../../../crmData/filters-data";
import {omit} from "lodash";
import {DEFAULT_PRODUCTS_PER_PAGE, setProductsForShowAmount} from "../../../redux/productsSlice";
import {useDispatch} from "react-redux";
// import { useTranslation } from "next-i18next";
// import { useAttributesQuery } from "@framework/attributes/attributes.query";
// import { AttributesFilter } from "@components/shop/attributes-filter";

export const ShopFilters= () => {
	let [searchParams, setSearchParams] = useSearchParams();
	const { search } = useLocation();
	const queryData = React.useMemo(() => queryStringToObject(search, initialFilterParams), [search]);
const dispatch = useDispatch()
	// useEffect(() => {
	// 	window.scrollTo(0, 0);
	// }, [searchParams])

	const clearAllFilters = () => {
		setSearchParams({
			...queryData,
			...omit(initialFilterParams, 'sorting', 'page')
		}, { replace: true })
		dispatch(setProductsForShowAmount(DEFAULT_PRODUCTS_PER_PAGE))
	}
	const handleRemoveFilter = (filterTypeKey, value) => {
		setSearchParams({
			...queryData,
			[filterTypeKey]: queryData[filterTypeKey].filter(el => el !== value),
			...(filterTypeKey === shoesFilterCategoryConfig.keyName && value === CATEGORIES.shoes) ? { [shoesSizeFilterConfig.keyName]: []} : {}
		}, { replace: true })
		dispatch(setProductsForShowAmount(DEFAULT_PRODUCTS_PER_PAGE))
		// console.log(filterTypeKey, value, 'kokookokokkokook')
	}

	return (
		<div className="pt-1">
			<div className="block border-b border-gray-300 pb-7 mb-7">
				<div className="flex items-center justify-between mb-2.5">
					<h2 className="font-semibold text-heading text-xl md:text-2xl">
						Фільтри
					</h2>
					<button
						className="flex-shrink text-xs mt-0.5 transition duration-150 ease-in focus:outline-none hover:text-heading"
						aria-label="Clear All"
						onClick={clearAllFilters}
					>
						Очистити фільтри
					</button>
				</div>
				<div className="flex flex-wrap -m-1.5 pt-2">
					{
						!isEmpty(queryData)
						&& (
							Object.keys(queryData).map((filterTypeKey) => {
								const filterTypeValues = queryData[filterTypeKey]
								console.log('updatedCategoryFilters')
								return (
									<>
										{
											Array.isArray(filterTypeValues)
											? filterTypeValues.map((value, idx) => (
												<FilteredItem
													itemKey={
														filterTypeKey
													}
													itemValue={ALL_FILTERS_DATA[value]?.label}
													key={`${idx}-${filterTypeKey}`}
													removeFilter={() => handleRemoveFilter(filterTypeKey, value)}
												/>
											))
												: null
										}
									</>
								)
							})
						)
					}
				</div>
			</div>

			<CategoryFilter config={shoesFilterCategoryConfig}/>
			{
				queryData?.[shoesFilterCategoryConfig.keyName]?.includes(CATEGORIES.shoes)
				&& (
					<CategoryFilter config={shoesSizeFilterConfig}/>
				)
			}
			<CategoryFilter config={priceFilterConfig}/>
			<CategoryFilter config={colorFilterConfig}/>
		{/*	<BrandFilter />*/}
		{/*	<PriceFilter />*/}

      {/*{data && <AttributesFilter attributes={data.attributes} />}*/}
		</div>
	);
};
