// import BannerCard from "@components/common/banner-card";
// import Container from "@components/ui/container";
// import BrandGridBlock from "@containers/brand-grid-block";
// import CategoryBlock from "@containers/category-block";
// import { getLayout } from "@components/layout/layout";
// import BannerWithProducts from "@containers/banner-with-products";
// import BannerBlock from "@containers/banner-block";
// import Divider from "@components/ui/divider";
// import DownloadApps from "@components/common/download-apps";
// import Support from "@components/common/support";
// import Instagram from "@components/common/instagram";
// import ProductsFlashSaleBlock from "@containers/product-flash-sale-block";
// import ProductsFeatured from "@containers/products-featured";
// import BannerSliderBlock from "@containers/banner-slider-block";
// import ExclusiveBlock from "@containers/exclusive-block";
// import Subscription from "@components/common/subscription";
// import NewArrivalsProductFeed from "@components/product/feeds/new-arrivals-product-feed";
// import { ROUTES } from "@lib/routes";
// import {
//   masonryBanner,
//   promotionBanner,
//   modernDemoBanner as banner,
//   modernDemoProductBanner as productBanner,
// } from "@data/static/banners";
//
// export { getStaticProps } from "@framework/ssr/homepage/modern";

import Container from "../../../Components/ui/container";
import BannerBlock from "../../../Components/containers/banner-block";
import {
    classicDemoBanner,
    masonryBanner,
    modernDemoBanner,
    modernDemoProductBanner,
    promotionBanner,
    standardDemoBanner, vintageDemoProductBanner
} from "../../data/static/banners";
import ProductsFlashSaleBlock from "../../../Components/containers/product-flash-sale-block";
import BannerSliderBlock from "../../../Components/containers/banner-slider-block";
import CategoryBlock from "../../../Components/containers/category-block";
import BannerWithProducts from "../../../Components/containers/banner-with-products";
import Divider from "../../../Components/ui/divider";
import {ROUTS} from "../../data/routes";
import {CATEGORIES} from "../../../crmData/filters-data";

export default function Home() {
  return (
    <>
      <BannerBlock data={masonryBanner} />
      <Container>
        <ProductsFlashSaleBlock />
      </Container>
      {/*<BannerSliderBlock data={promotionBanner} />*/}
      <Container>
        <CategoryBlock sectionHeading="text-shop-by-category" type="rounded" />
      {/*  <ProductsFeatured sectionHeading="text-featured-products" />*/}
      {/*  <BannerCard*/}
      {/*    data={banner[0]}*/}
      {/*    href={`${ROUTES.COLLECTIONS}/${banner[0].slug}`}*/}
      {/*    className="mb-12 lg:mb-14 xl:mb-16 pb-0.5 lg:pb-1 xl:pb-0"*/}
      {/*  />*/}
      {/*  <BrandGridBlock sectionHeading="text-top-brands" />*/}
      {/*  <BannerCard*/}
      {/*    data={banner[1]}*/}
      {/*    href={`${ROUTES.COLLECTIONS}/${banner[1].slug}`}*/}
      {/*    className="mb-12 lg:mb-14 xl:mb-16 pb-0.5 lg:pb-1 xl:pb-0"*/}
      {/*  />*/}
        <BannerWithProducts
          sectionHeading="Товари зі знижками"
          categorySlug={ROUTS.PRODUCTS_BY_CATEGORY([CATEGORIES.sale])}
          data={modernDemoProductBanner}
        />
      {/*  <ExclusiveBlock />*/}
      {/*  <NewArrivalsProductFeed />*/}
      {/*  <DownloadApps />*/}
      {/*  <Support />*/}
      {/*  <Instagram />*/}
      {/*  <Subscription className="bg-opacity-0 px-5 sm:px-16 xl:px-0 py-12 md:py-14 xl:py-16" />*/}
      </Container>
    </>
  );
}

// Home.getLayout = getLayout;
