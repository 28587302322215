// import { Drawer } from "@components/common/drawer/drawer";
// import FilterIcon from "@components/icons/filter-icon";
// import Text from "@components/ui/text";
// import { useUI } from "@contexts/ui.context";
// import FilterSidebar from "@components/shop/filter-sidebar";
// import ListBox from "@components/ui/list-box";
// import { useRouter } from "next/router";
// import { useTranslation } from "next-i18next";
// import { getDirection } from "@utils/get-direction";
import isEmpty from "lodash/isEmpty";
import React, {useEffect, useState} from "react";
import Text from "../ui/text";
import FilterIcon from "../../icons/filter-icon";
import Drawer from "react-modern-drawer";
import FilterSidebar, {FilterSidebarModal} from "../../Features/ProductList/components/filter-sidebar";
import {useDispatch, useSelector} from "react-redux";
import {closeFilterModal, openFilterModal} from "../../redux/uiSlice";
import ListBox from "../ui/list-box";
import {sortOptions} from "../../crmData/sorts-data";
import {useLocation} from "react-router";
import {queryStringToObject} from "../../helpers";
import {initialFilterParams} from "../../crmData/filters-data";
import {useSearchParams} from "react-router-dom";
import {DEFAULT_PRODUCTS_PER_PAGE, setProductsForShowAmount} from "../../redux/productsSlice";


const SearchTopBar = ({ searchResultCount }) => {
  // const { query } = useRouter();
  // const { openFilter, displayFilter, closeFilter } = useUI();
  // const { t } = useTranslation("common");
  // const { locale } = useRouter();
  // const dir = getDirection(locale);
    let [searchParams, setSearchParams] = useSearchParams();
    const { search } = useLocation();
    const query = {}
    // const [selectedSorting, setSelectedSorting] = useState(sortOptions[0])
    const {queryData, selectedSorting } = React.useMemo(() => {
        const queryData = queryStringToObject(search, initialFilterParams)
        const selectedSorting = sortOptions.filter(el => {
            if (el.value === queryData.sorting) {
                return true
            }
        })?.[0] || sortOptions[0]
        return {selectedSorting, queryData}
    }, [searchParams]);
    useEffect(() => {

    }, [search])

                console.log(selectedSorting, 'lplplppllplpl');
    // const contentWrapperCSS = dir === "ltr" ? { left: 0 } : { right: 0 };
    const dispatch = useDispatch()
    const { showShowFilterModal } = useSelector((state) => state.ui)
    const t = te => te
    const openFilter = () => {
        dispatch(openFilterModal())
    }
    const closeFilter = () => {
        dispatch(closeFilterModal())
    }
    const selectSortOption = (option) => {
        console.log(option, 'drkedfierk');
        setSearchParams({ ...queryData, sorting: option.value }, { replace: true })
        dispatch(setProductsForShowAmount( DEFAULT_PRODUCTS_PER_PAGE))
    }


    return (
    <div className="flex justify-between items-center mb-7">
      <Text variant="pageHeading" className="hidden lg:inline-flex pb-1">
        {isEmpty(query) ? "Товари" : "Знайдені товари"}
      </Text>
      <button
        className="lg:hidden text-heading text-sm px-4 py-2 font-semibold border border-gray-300 rounded-md flex items-center transition duration-200 ease-in-out focus:outline-none hover:bg-gray-200"
        onClick={openFilter}
      >
        <FilterIcon />
        <span className="ps-2.5">Фільтри</span>
      </button>
      <div className="flex items-center justify-end ml-auto">
        <div className="flex-shrink-0 text-body text-xs md:text-sm leading-4 pe-4 md:me-6 ps-2 hidden lg:block">
          {searchResultCount ?? 0} товарів
        </div>
          <div className={''} style={{ minWidth: '150px'}}>
              <ListBox
                  options={sortOptions}
                  selectedOption={selectedSorting}
                  onOptionSelect={selectSortOption}

              />
          </div>
      </div>
        <FilterSidebarModal/>
      {/*    <Drawer*/}
      {/*        lockBackgroundScroll*/}
      {/*        open={showShowFilterModal}*/}
      {/*        onClose={closeFilter}*/}
      {/*        direction='left'*/}
      {/*        className='bla bla bla'*/}
      {/*        style={{*/}
      {/*            width: '95%',*/}
      {/*            maxWidth: '450px'*/}
      {/*        }}*/}

      {/*    >*/}
      {/*  <FilterSidebar searchResultCount={searchResultCount ?? 0} />*/}
      {/*</Drawer>*/}
    </div>
  );
};

export default SearchTopBar;
