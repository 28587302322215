import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/custom-plugins.css';
import './styles/scrollbar.css';
import './styles/swiper-carousel.css';
import './styles/tailwind.css';
import App from './App';
import {
    createBrowserRouter,
    RouterProvider,
    useRouteError,
} from "react-router-dom";

import reportWebVitals from './reportWebVitals';
import ProductProfilePage from "./Features/ProductProfile/pages/ProductProfile";
import HomePage from "./Features/Home/Pages/HomePage";
import ProductListPage from "./Features/ProductList/Pages/ProductList";

import { store } from './redux/store'
import { Provider } from 'react-redux'
import ContactUsPage from "./Features/Contacts";
import NewOrderPage from "./Features/OrderPage/pages/checkout";
import SussesOrderCreatedPage from "./Features/OrderPage/pages/sucsses-order-create";
import AdminPanelPage from "./Features/Admin-panel";
import AboutUsPage from "./Features/InfoPages/pages/aboutUs";
import AgreementPage from "./Features/InfoPages/pages/agreement";
import ConfidentialPage from "./Features/InfoPages/pages/confidential";
import DeliveryPage from "./Features/InfoPages/pages/delivery";
import RecommendationsPage from "./Features/InfoPages/pages/recomendations";
import ReturnExchangePage from "./Features/InfoPages/pages/return-exchange";

const ErrorPage = () => {
    const error = useRouteError();
    console.error(error);

    return (
        <div id="error-page">
            <h1>Oops!</h1>
            <p>Sorry, an unexpected error has occurred.</p>
            <p>
                <i>{error.statusText || error.message}</i>
            </p>
        </div>
    );
}
const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        errorElement: <ErrorPage />,
        children: [
            { index: true, element: <HomePage /> },
            {
                path: "products",
                element: <ProductListPage />,
            },
            {
                path: "products/:productId",
                element: <ProductProfilePage />,
            },
            {
                path: "contacts",
                element: <ContactUsPage />,
            },
            {
                path: "new-order/:buyType",
                element: <NewOrderPage />,
            },
            {
                path: "/sussesOrderCreated/:id/:totalPrice",
                element: <SussesOrderCreatedPage />,
            },
            {
                path: "/aboutUs",
                element: <AboutUsPage />,
            },
            {
                path: "/agreement",
                element: <AgreementPage />,
            },
            {
                path: "/confidential",
                element: <ConfidentialPage />,
            },
            {
                path: "/delivery",
                element: <DeliveryPage />,
            },
            {
                path: "/recommendations",
                element: <RecommendationsPage />,
            },
            {
                path: "/return",
                element: <ReturnExchangePage />,
            },
            {
                path: "admin",
                element: <AdminPanelPage />,
            },
        ],
    },
    // {
    //     path: "/products",
    //     element: <App />,
    //     errorElement: <ErrorPage />,
    //     children: [
    //         {
    //             path: "products/:productId",
    //             element: <ProductProfilePage />,
    //         },
    //     ],
    // },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <React.StrictMode>
        {/**/}
          <RouterProvider router={router} />

        </React.StrictMode>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
