import axios from "axios";

const INITIAL_CRM_URL = 'http://127.0.0.1:5001/lunameshoes/us-central1/subsctiptionsTest'
// const INITIAL_CRM_URL = 'https://us-central1-lunameshoes.cloudfunctions.net/subsctiptionsTest'
const GET_PRODUCTS_URL = '/products/byIds'
const GET_PRODUCTS_VARIATIONS_URL = '/products/crmProductsVariations'
const CREATE_NEW_ORDER_URL = '/products/createOrder'
const CREATE_RECALL_CARD_URL = '/products/createRecallCard'
const UPDATE_PRODUCTS_CRM_URL = '/products/updateProductsCrm'
const CRM_CATEGORIES_URL = '/products/crmCategories'
const sendRequest = async ({path, method = 'get', data = {}}) => {
    try {
        return await axios({
            method,
            data,
            url: `${INITIAL_CRM_URL}${path}`,
            headers: {
                "Content-Type": "application/json",
                // "Accept": "application/json",
            }
        })
    } catch (e) {
        // console.log(e);
        return {
            error: e
        }
    }
}

export const getProducts = () => {
    return sendRequest({path: GET_PRODUCTS_URL})
}
export const getVariations = () => {
    return sendRequest({path: GET_PRODUCTS_VARIATIONS_URL})
}
export const updateProductsInCrm = () => {
    return sendRequest({path: UPDATE_PRODUCTS_CRM_URL})
}
export const getCrmCategories = () => {
    return sendRequest({path: CRM_CATEGORIES_URL})
}
export const createNewOrder = (data = {}) => {
    return sendRequest({ path: CREATE_NEW_ORDER_URL, method: 'POST', data })
}
export const createRecallCard = (data = {}) => {
    return sendRequest({ path: CREATE_RECALL_CARD_URL, method: 'POST', data })
}


