import Carousel from "../../../Components/ui/carousel";
import React from "react";
import { SwiperSlide } from "swiper/react";
import {useWindowSize} from "../../../helpers";
const productGalleryCarouselResponsive = {
    "768": {
        slidesPerView: 2,
        spaceBetween: 12,
    },
    "0": {
        slidesPerView: 1,
    },
};
const ProductImageSection = ({combineImages = [], name = ''}) => {
    const { width } = useWindowSize();
    return (
        <>
            {width < 1025 ? (
                <Carousel
                    pagination={{
                        clickable: true,
                    }}
                    breakpoints={productGalleryCarouselResponsive}
                    className="product-gallery"
                    buttonClassName="hidden"
                >
                    {combineImages?.length > 1 ? (
                        combineImages?.map((item, index) => (
                            <SwiperSlide key={`product-gallery-key-${index}`}>
                                <div className="col-span-1 transition duration-150 ease-in hover:opacity-90 flex">
                                    <img
                                        width={475}
                                        height={618}
                                        src={
                                            item ??
                                            "/assets/placeholder/products/product-gallery.svg"
                                        }
                                        alt={`${name}--${index}`}
                                        className="object-cover w-full"
                                    />
                                </div>
                            </SwiperSlide>
                        ))
                    ) : (
                        <SwiperSlide key={`product-gallery-key`}>
                            <div className="col-span-1 transition duration-150 ease-in hover:opacity-90 flex">
                                <img
                                    width={475}
                                    height={618}
                                    src={
                                        combineImages?.[0] ??
                                        "/assets/placeholder/products/product-gallery.svg"
                                    }
                                    alt={name}
                                    className="object-cover w-full"
                                />
                            </div>
                        </SwiperSlide>
                    )}
                </Carousel>
            ) : (
                <div className="col-span-5 grid grid-cols-2 gap-2.5">
                    {combineImages?.length > 1 ? (
                        combineImages?.map((item, index) => (
                            <div
                                key={index}
                                className="col-span-1 transition duration-150 ease-in flex"
                            >
                                <img
                                    width={475}
                                    height={618}
                                    src={
                                        item ??
                                        "/assets/placeholder/products/product-gallery.svg"
                                    }
                                    alt={`${name}--${index}`}
                                    className="object-cover w-full"
                                />
                            </div>
                        ))
                    ) : (
                        <div className="col-span-full bg-gray-300 flex justify-center rounded-md">
                            <div className="transition duration-150 ease-in w-1/2 flex">
                                <img
                                    width={475}
                                    height={618}
                                    src={
                                        combineImages?.[0] ??
                                        "/assets/placeholder/products/product-gallery.svg"
                                    }
                                    alt={name}
                                    className="object-cover"
                                />
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    )
}

export default ProductImageSection
